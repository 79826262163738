import { USER_DATA } from "./actionTypes"

const Data = JSON.parse(localStorage.getItem("authUser"))
const initialState = Data || null;

const userData = (state = initialState, action) => {
    switch (action.type) {
        case USER_DATA:
          return action.payload 
        default:
          return state
      }
    }
   
 export default userData