import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useRef, useCallback } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  CardTitle,
  Button,
  Modal,
} from "reactstrap"

//redux
import { useSelector, useDispatch, connect } from "react-redux"

import { withRouter, Link } from "react-router-dom"
import imageCompression from "browser-image-compression"
// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userEdit, userForgetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.png"

import axios from "axios"
import LayoutModal from "../../components/Custom/LayoutModel"
import Dropzone from "react-dropzone"
import Switch from "react-switch"
import makeAnimated from "react-select/animated"

import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import Loader from "components/Custom/Loader"

import Stack from "@mui/material/Stack"
import TextField from "@mui/material/TextField"
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import LocalizationProvider from "@mui/lab/LocalizationProvider"
import TimePicker from "@mui/lab/TimePicker"
import DateTimePicker from "@mui/lab/DateTimePicker"
import DesktopDatePicker from "@mui/lab/DesktopDatePicker"
import MobileDatePicker from "@mui/lab/MobileDatePicker"
import MomentUtils from "@date-io/moment"

import moment from "moment"
import Selector from "components/Custom/Selector"
import { BaseURL, ImageURL } from "config/BaseUrl"
import getCroppedImg from "pages/Items/getCroppedImg"
import Cropper from "react-easy-crop"

const AddItem = props => {
  const dispatch = useDispatch()

  const animatedComponents = makeAnimated()
  const [loader, setLoader] = useState(false)

  const [date, setDate] = useState(new Date())

  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  const [state, setState] = useState(null)
  const [city, setCity] = useState(null)

  const [sq1, setsq1] = useState(false)

  const [toggleSwitch, setToggleSwitch] = useState(true)

  // const dispatch = useDispatch();
  const [modal_xlarge, setmodal_xlarge] = useState(false)

  const [selectedFiles, setselectedFiles] = useState(null)
  const imgRef = useRef(null)
  const categorySelect = useRef(null)
  const [imgSrc, setImgSrc] = useState({ src: "", index: "" })
  const [compressedAllFiles, setCompressAllFiles] = useState(false)
  const [preventParent, setPreventParent] = useState(false)

  const [bannerImageFile, setBannerImageFile] = useState()
  const [compressLoader, setCompressLoader] = useState(false)

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  // const dogImg ='https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000'

  const [categoryList, setCategoryList] = useState([])
  const [brandList, setBrandList] = useState([])
  const [selectBrand, setSelectBrand] = useState(
    []
    // props.editData && props.editData.banner ? props.editData.brand._id : null
  )
  const [selectCategory, setSelectCategory] = useState(
    null
    // props.editData && props.editData.banner ? props.editData.category._id : null
  )
  // const [loader , setLoader] = useState(false)
  // const [checkedNot , setCheckedNot] = useState(props.editData && props.editData.banner ? props.editData.sendNotification : true)
  const [showEditImage, setShowEditImage] = useState(true)

  const [filesImage, setFilesImage] = useState(
    props.editData && props.editData.profilePic
      ? props.editData.profilePic
      : null
  )

  const [isBannerImage, setIsBannerImage] = useState(false)

  const [resetBanner, setResetBanner] = useState(false)

  const [resetCateSelect, SetResetCateSelect] = useState(true)

  useEffect(() => {
    // var config = {
    //   method: 'get',
    //   url: `${BaseURL}/states`,
    //   headers: {
    //     'x-auth-token': props.userData.token,
    //   }
    // };

    // var configCity = {
    //   method: 'get',
    //   url: `${BaseURL}/cities`,
    //   headers: {
    //     'x-auth-token': props.userData.token,
    //   }
    // };

    // axios(config)
    // .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    //   setStates(response.data)
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });

    // axios(configCity)
    // .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    //   setCities(response.data)
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });

    var getDropDown = {
      method: "get",
      url: `${BaseURL}/formdata/citystate`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(getDropDown)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        setCities(response.data.cities)
        setStates(response.data.states)
      })
      .catch(function (error) {
        console.log(error)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }, [])
  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseURL}/party_categories`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        setCategoryList(response.data.partycategory)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
    console.log(croppedAreaPixels, croppedArea, "Hello")
  }, [])

  useEffect(() => {
    console.log(selectedFiles, "OPEN MODEL")
    if (modal_xlarge) {
      setCompressAllFiles(false)
    }
  }, [modal_xlarge])

  const showCroppedImage = useCallback(async () => {
    setCompressLoader(true)

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    let filterBackendImages = []
    let filterUploadedImages = []

    selectedFiles !== null &&
      selectedFiles.map((d, i) => {
        if (!d._id) {
          filterUploadedImages.push(d)
        } else {
          filterBackendImages.push(d)
        }
      })

    let fileCompressed =
      selectedFiles !== null && !isBannerImage ? filterUploadedImages : []
    try {
      const croppedImage = await getCroppedImg(
        imgSrc.src,
        croppedAreaPixels,
        rotation
      )
      console.log("donee", { croppedImage })

      const compressFile = async file => {
        try {
          // const compressedFile = await imageCompression(file, options)

          const data = await imageCompression(file, options).then(f => {
            Object.assign(f, {
              preview: URL.createObjectURL(f),
              originalPreview: URL.createObjectURL(file),
              formattedSize: formatBytes(f.size),
              originalSize: formatBytes(
                isBannerImage
                  ? filesImage.size
                  : selectedFiles[imgSrc.index].size
              ),
              name: isBannerImage
                ? filesImage.name
                : selectedFiles[imgSrc.index].name,
              compressed: true,
              bannerImage: false,
            })
            console.log(f, "dDD")
            console.log(imgSrc.index, "INDEX")
            fileCompressed.splice(imgSrc.index, 1, f)

            // fileCompressed.push(f)
            return f
          })
          console.log(data, fileCompressed, "COMPRESSED FILES")
          // setselectedFiles([...fileCompressed ])
          fileCompressed.length - 1 > Number(imgSrc.index)
            ? setImgSrc({
                src: fileCompressed[Number(imgSrc.index) + 1].preview,
                index: Number(imgSrc.index) + 1,
              })
            : setmodal_xlarge(!modal_xlarge)
          setCompressLoader(false)
          let result = true
          // let bannerImageResult = false

          fileCompressed.map((d, i) => {
            if (i < fileCompressed.length - 1) {
              console.log(d.compressed, "Bool")
              result = d.compressed && fileCompressed[i + 1].compressed
              // bannerImageResult = d.bannerImage || fileCompressed[i+1].bannerImage
            }
          })
          setCompressAllFiles(result)
          // console.log(result,bannerImageResult,"Result")

          console.log(fileCompressed[0], selectedFiles, "ImHere")
          setFilesImage(fileCompressed[0])

          // console.log(result,bannerImageResult,fileCompressed,"Result")
        } catch (e) {
          console.log(e, filesImage, "Compress")
        }
      }
      compressFile(croppedImage)

      //   setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation, imgSrc])

  function tog_xlarge() {
    if (compressedAllFiles) {
      setmodal_xlarge(!modal_xlarge)
    }

    // removeBodyCss();
  }

  function handleAcceptedFiles(files, isBanner) {
    console.log(files, "LENGTH")

    try {
      files.map(file => {
        console.log(file, "====++====")

        Object.assign(file, {
          preview: URL.createObjectURL(file),
          originalPreview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
          originalSize: formatBytes(file.size),
          compressed: false,
          bannerImage: false,
        })
      })

      console.log(files, "====++====")
      setFilesImage(files)
      setImgSrc({ src: files[0].preview, index: 0 })
    } catch (e) {
      console.log(e, "ERRRRROR")
    }
  }

  //  useEffect(() => {console.log(selectedFiles.length)},[selectedFiles.length])

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  useEffect(() => {
    console.log(props.editData, "llllllll")
    if (props.editData) {
      if (!props.editData.partyName) {
        return () => dispatch(userEdit(null))
      }

      props.editData.state && setState(props.editData.state._id)
      props.editData.city && setCity(props.editData.city._id)
      let data = props.editData
      let DataMogified = []
      props.editData.partyCategory !== null &&
        setSelectCategory(props.editData.partyCategory._id)
      data.contacts.length !== 0 &&
        data.contacts.map((d, i) => {
          // sameAsContact
          if (d.phone === d.whatsapp && d.phone !== "") {
            d.sameAsContact = true
            d.id = i + 1
          } else {
            d.sameAsContact = false
            d.id = i + 1
          }
          DataMogified.push(d)
        })

      const files = []
      data.profilePic !== null &&
        Object.assign(data.profilePic, {
          preview: ImageURL + "/party_profile_pics/" + data.profilePic.filename,
          formattedSize: formatBytes(data.profilePic.fileSize),
          compressed: true,
          name: data.profilePic.originalname,
          _id: data.profilePic._id,
        })
      data.birthdate && setDate(data.birthdate)
      data.profilePic && setFilesImage(data.profilePic)

      data.contacts.length !== 0 && setFormRows(DataMogified)

      console.log(data, DataMogified, "EDITDATA")

      setTimeout(() => {
        data.contacts.length !== 0 &&
          data.contacts.map((d, i) => {
            document.getElementById("name" + i).value = d.name
            document.getElementById("phone" + i).value = d.phone
            document.getElementById("whatsapp" + i).value = d.whatsapp
          })
      }, 100)
    }

    return () => dispatch(userEdit(null))
  }, [props.editData])

  //   console.log(props.userData,"DATATA")

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    // enableReinitialize: true,

    initialValues: {
      partyName: props.editData ? props.editData.partyName : "",
      email: props.editData ? props.editData.email : "",
      password: "",
      // address: props.editData ? props.editData.address : "",
      pincode: props.editData ? props.editData.pincode : "",
      // state:props.editData ? props.editData.state : '',
      // city:props.editData ? props.editData.city :  '',
      // dob: '',
    },
    validationSchema: !props.editData
      ? Yup.object({
          partyName: Yup.string().required("Please Enter item name"),
          email: Yup.string().required("Please Enter Brand"),
          password: Yup.string().required("Please select a option"),
          // address: Yup.string().required("Please Enter pincode"),
          pincode: Yup.string().required("Please Enter pincode"),
          // state: Yup.string().required("Please Enter state"),
          // city: Yup.string().required("Please Enter Description"),
          // dob: Yup.string().required("Please Enter dob"),
          // dob: Yup.mixed().required("Please select images"),
        })
      : Yup.object({
          partyName: Yup.string().required("Please Enter item name"),
          // address: Yup.string().required("Please Enter pincode"),
          pincode: Yup.string().required("Please Enter pincode"),
          // state: Yup.string().required("Please Enter state"),
          // city: Yup.string().required("Please Enter Description"),
          // dob: Yup.string().required("Please Enter dob"),
          // dob: Yup.mixed().required("Please select images"),
        }),

    onSubmit: values => {
      let dob = moment(date).format("YYYY-MM-DD")
      // console.log(values)

      // if(filesImage == null){
      //   return toast.error("Please Upload Your Profile Image", {
      //     position: "top-right",
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,

      //     });
      // }
      if (selectCategory == null) {
        return toast.error("Please select a Category", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      if (state == null) {
        return toast.error("Please select a State", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }

      if (city == null) {
        return toast.error("Please select a City", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }

      setLoader(true)

      var FormData = require("form-data")

      var data = new FormData()
      data.append("partyName", values.partyName)
      !props.editData && data.append("email", values.email)
      !props.editData && data.append("password", values.password)
      // data.append("address", values.address)
      data.append("address", values.address ? values.address : "")
      data.append("pincode", values.pincode)
      data.append("state", state)
      data.append("city", city)
      data.append("partyCategory", selectCategory)
      data.append("birthdate", dob)

      if (filesImage !== null) {
        let fileImage = new File([filesImage], filesImage.name)
        !filesImage._id && data.append("profilePic", fileImage)
      }

      formRows.map((d, i) => {
        data.append(`contacts[${i}][name]`, d.name)
        data.append(`contacts[${i}][phone]`, d.phone)
        data.append(`contacts[${i}][whatsapp]`, d.whatsapp)
      })

      // var data =  !props.editData ? JSON.stringify({
      //   "partyName": values.partyName,
      //   "email": values.email,
      //   "password": values.password,
      //   "address": values.address,
      //   "pincode": values.pincode,
      //   "state": state,
      //   "city": city,
      //   "birthdate": dob,
      //   "contacts": formRows,
      //   "profilePic": fileImage,
      // })
      // :
      // JSON.stringify({
      //   "partyName": values.partyName,
      //   "address": values.address,
      //   "pincode": values.pincode,
      //   "state": state,
      //   "city": city,
      //   "birthdate": dob,
      //   "contacts": formRows,
      //   "profilePic": fileImage,
      // })
      // ;

      var config = {
        method: props.editData ? "put" : "post",
        url: `${BaseURL}/parties${
          props.editData ? "/update/" + props.editData._id : ""
        }`,
        headers: {
          "x-auth-token": props.userData.token,
          "Content-Type": "application/json",
        },
        data: data,
      }

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data))
          setLoader(false)
          props.history.push("/party-list")
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch(function (error) {
          console.log(error)
          setLoader(false)
          toast.error(error.response && error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
    },
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  const [formRows, setFormRows] = useState([
    { id: 1, name: "", phone: "", whatsapp: "", sameAsContact: false },
  ])

  const onAddFormRow = () => {
    const modifiedRows = [...formRows]
    console.log(modifiedRows, "Value")
    modifiedRows.push({
      id: modifiedRows.length + 1,
      name: "",
      phone: "",
      whatsapp: "",
      sameAsContact: false,
    })
    setFormRows(modifiedRows)
  }

  const onDeleteFormRow = id => {
    const modifiedRowsDelete = [...formRows]

    modifiedRowsDelete.splice(id, 1)

    setFormRows(modifiedRowsDelete)
    console.log(id, modifiedRowsDelete, "Key")
    // if (id !== 1) {
    //   var modifiedRows = [...formRows]
    //   console.log(modifiedRows, "Value")
    //   modifiedRows = modifiedRows.filter(x => x["id"] !== id)
    //   setFormRows(modifiedRows)
    // }
  }

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    )
  }

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    )
  }

  const toggle = () => {
    setToggleSwitch(false)
    setTimeout(() => {
      setToggleSwitch(true)
    }, 1)
  }

  if (loader) {
    return <Loader />
  }

  return (
    <>
      <Tostify />
      <LayoutModal
        button={true}
        form={true}
        title={props.editData ? "Edit Party" : "Add Party"}
        btnTitle={props.editData ? "Add Party" : "Party List"}
        onclick={() => {
          if (props.editData) {
            dispatch(userEdit(null))

            // setFilesImage(null)
            // setFormRows([{ id:1 ,name: "" ,phone:"" ,whatsapp:"" , sameAsContact:false }])
            setLoader(true)
            window.location.reload()
            setTimeout(() => setLoader(false), 300)
          } else {
            props.history.push("/party-list")
          }
          // props.editData ? dispatch(userEdit(null)) : props.history.push("/party-list")
        }}
      >
        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="mb-3">
            {/* <Label 
                      style={{
                        marginBottom:10,
                        // width:20,
                      }}
                      className="form-label">Upload Profile Picture <span style={{color:"red"}}>*</span></Label> */}
            <div
              style={{
                height: 100,
                width: 100,
                backgroundColor: "#ffe3e3",
                // marginLeft:"auto",
                marginBottom: 20,
                borderRadius: 50,
                border:
                  filesImage !== null
                    ? "2px solid #34c38f"
                    : "2px solid #FF5858",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <input
                style={{
                  opacity: 0,
                  height: 100,
                  width: 100,
                  position: "absolute",
                }}
                onChange={e => {
                  if (typeof e.target.files[0] !== "undefined") {
                    console.log(e.target.files[0], "File")
                    handleAcceptedFiles([e.target.files[0]])

                    setResetBanner(true)
                    let isBanner = true
                    setIsBannerImage(true)
                    setmodal_xlarge(true)
                    // validation.handleChange(e)
                    setFilesImage(e.target.files[0])
                  }
                }}
                type="file"
              />
              {filesImage !== null ? (
                <img
                  src={filesImage.preview}
                  alt="img"
                  style={{
                    height: 98,
                    width: 98,
                    borderRadius: 50,
                    objectFit: "cover",
                  }}
                />
              ) : (
                <Label
                  style={{
                    textAlign: "center",
                    fontSize: 10,
                  }}
                  className="form-label"
                >
                  Upload Profile Picture
                </Label>
              )}
            </div>
          </div>
          <Row>
            <div
              style={{
                border: "1px dashed #1313132e",
                marginTop: 0,
                marginBottom: 20,
              }}
            />
          </Row>
          <Row>
            <Col md={6}>
              <div className="mb-3">
                <Label className="form-label">
                  Party Name <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  name="partyName"
                  id="partyName"
                  className="form-control"
                  placeholder="Enter your Item Name"
                  type="text"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.partyName || ""}
                  invalid={
                    validation.touched.partyName && validation.errors.partyName
                      ? true
                      : false
                  }
                />
                {validation.touched.partyName && validation.errors.partyName ? (
                  <FormFeedback type="invalid">
                    {validation.errors.partyName}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col md={6}>
              <Selector
                label="Category"
                required={true}
                id="select"
                // ref={categorySelect}
                data={categoryList !== null ? categoryList : []}
                onchange={event => setSelectCategory(event.value)}
                edit={
                  props.editData &&
                  props.editData.partyCategory !== null && {
                    label: props.editData.partyCategory.name,
                    value: props.editData.partyCategory._id,
                  }
                }
              />
            </Col>
          </Row>
          {!props.editData && (
            <Row className="mb-3">
              <Col md={6}>
                <Label className="form-label">
                  Email <span style={{ color: "red" }}>*</span>
                </Label>

                <Input
                  name="email"
                  id="email"
                  className="form-control"
                  placeholder="Enter your Brand Name"
                  type="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />

                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </Col>

              <Col md={6}>
                <Label className="form-label">
                  Password <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  name="password"
                  id="password"
                  className="form-control"
                  placeholder="Enter your password"
                  type="password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />

                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
          )}
          <div className="mb-3">
            <Label className="form-label">Address</Label>
            <Input
              name="address"
              id="address"
              className="form-control"
              placeholder="Enter your Description"
              type="textarea"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.address || ""}
              // invalid={
              //   validation.touched.address && validation.errors.address
              //     ? true
              //     : false
              // }
            />
            {/* {validation.touched.address && validation.errors.address ? (
              <FormFeedback type="invalid">
                {validation.errors.address}
              </FormFeedback>
            ) : null} */}
          </div>

          <Row className="mb-3">
            <Col md={4}>
              <Label className="form-label">
                Pincode <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="pincode"
                id="pincode"
                className="form-control"
                placeholder="Enter your pincode"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.pincode || ""}
                onKeyPress={event => {
                  if (
                    !/[0-9]/.test(event.key) ||
                    event.target.value.length > 5
                  ) {
                    event.preventDefault()
                  }
                }}
                invalid={
                  validation.touched.pincode && validation.errors.pincode
                    ? true
                    : false
                }
              />
              {validation.touched.pincode && validation.errors.pincode ? (
                <FormFeedback type="invalid">
                  {validation.errors.pincode}
                </FormFeedback>
              ) : null}
            </Col>

            <Col md={4}>
              <Selector
                label="State"
                data={states !== null ? states : []}
                onchange={event => setState(event.value)}
                required
                edit={
                  props.editData &&
                  props.editData.partyName &&
                  props.editData.state && {
                    label: props.editData.state.name,
                    value: props.editData.state._id,
                  }
                }
              />
              {/* <Label className="form-label">State</Label>
                        <Input
                          name="state"
                          id="state"
                          className="form-control"
                          placeholder="Enter your state"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.state || ""}
                          invalid={
                            validation.touched.state && validation.errors.state ? true : false
                          }
                        /> */}
              {validation.touched.state && validation.errors.state ? (
                <FormFeedback type="invalid">
                  {validation.errors.state}
                </FormFeedback>
              ) : null}
            </Col>
            <Col md={4}>
              <Selector
                label="Cities"
                required
                data={cities !== null ? cities : []}
                onchange={event => setCity(event.value)}
                edit={
                  props.editData &&
                  props.editData.partyName &&
                  props.editData.city && {
                    label: props.editData.city.name,
                    value: props.editData.city._id,
                  }
                }
              />
            </Col>
          </Row>

          <div>
            <LocalizationProvider dateAdapter={MomentUtils}>
              <MobileDatePicker
                label="Date of Birth"
                inputFormat="DD/MM/YYYY"
                value={date}
                onChange={e => {
                  const dateOfBirth = moment(e)
                  setDate(dateOfBirth)
                }}
                renderInput={params => (
                  <TextField variant="outlined" {...params} />
                )}
              />
            </LocalizationProvider>

            {/* <Label className="form-label">Date</Label>
                        <Input
                          name="dob"
                          id="dob"
                          className="form-control"
                          placeholder="Enter your Description"
                          type="date"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.dob || ""}
                          invalid={
                            validation.touched.dob && validation.errors.dob ? true : false
                          }
                        /> */}
            {validation.touched.dob && validation.errors.dob ? (
              <FormFeedback type="invalid">
                {validation.errors.dob}
              </FormFeedback>
            ) : null}
          </div>

          <Row>
            <div
              style={{
                border: "1px dashed #1313132e",
                marginTop: 40,
                marginBottom: 10,
              }}
            />
          </Row>

          <Row>
            <Col xs={12}>
              <CardBody>
                <CardTitle className="mb-4">Contact Details</CardTitle>
                <Form className="repeater" encType="multipart/form-data">
                  <div>
                    {(formRows || []).map((formRow, key) => (
                      <Row key={key}>
                        <Col lg={4} className="mb-3">
                          <label htmlFor="name">Contact Person</label>
                          <Input
                            type="text"
                            id={"name" + key}
                            name="untyped-input"
                            className="form-control"
                            placeholder="Enter Your Name"
                            onChange={e => {
                              formRow.name = e.target.value
                              setFormRows([...formRows])
                            }}
                            value={formRow.name}
                          />
                        </Col>

                        <Col lg={4} className="mb-3">
                          <label htmlFor="email">Contact Number</label>
                          <Input
                            type="phone"
                            id={"phone" + key}
                            className="form-control"
                            placeholder="Enter Your Phone Number"
                            onChange={e => {
                              formRow.phone = e.target.value
                              setFormRows([...formRows])
                            }}
                            onKeyPress={event => {
                              if (
                                !/[0-9]/.test(event.key) ||
                                event.target.value.length > 9
                              ) {
                                event.preventDefault()
                              }
                            }}
                            value={formRow.phone}
                          />
                        </Col>

                        <Col lg={4} className="mb-3">
                          <label style={{ marginRight: 10 }} htmlFor="subject">
                            Whatsapp
                          </label>

                          {toggleSwitch && (
                            <Switch
                              uncheckedIcon={<Offsymbol />}
                              checkedIcon={<OnSymbol />}
                              className="me-2 mb-sm-8 mb-2"
                              onColor="#626ed4"
                              height={18}
                              width={40}
                              onChange={() => {
                                console.log(formRow.sameAsContact, "Contact")
                                formRow.sameAsContact = !formRow.sameAsContact
                                formRow.sameAsContact
                                  ? (formRow.whatsapp = formRow.phone)
                                  : (formRow.whatsapp = "")
                                console.log(formRow.sameAsContact, "Contact")
                                toggle()
                                setFormRows([...formRows])
                              }}
                              checked={formRow.sameAsContact}
                            />
                          )}
                          {/* </div> */}
                          <Input
                            type="text"
                            id={"whatsapp" + key}
                            className="form-control"
                            disabled={formRow.sameAsContact}
                            placeholder={
                              formRow.sameAsContact
                                ? formRow.phone
                                : "Enter Your Subject"
                            }
                            onChange={e => {
                              formRow.whatsapp = e.target.value
                              setFormRows([...formRows])
                            }}
                            onKeyPress={event => {
                              if (
                                !/[0-9]/.test(event.key) ||
                                event.target.value.length > 9
                              ) {
                                event.preventDefault()
                              }
                            }}
                            value={formRow.whatsapp}
                          />
                        </Col>

                        {formRow.id !== 1 && (
                          <Col
                            lg={2}
                            style={{ marginBottom: 10 }}
                            className="align-self-center"
                          >
                            <div className="d-grid">
                              <input
                                type="button"
                                className="btn btn-primary"
                                value="Delete"
                                onClick={() => onDeleteFormRow(key)}
                              />
                            </div>
                          </Col>
                        )}
                      </Row>
                    ))}
                  </div>
                  <input
                    type="button"
                    className="btn btn-success mt-3 mt-lg-0"
                    value="Add"
                    onClick={() => onAddFormRow()}
                  />
                </Form>
              </CardBody>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col className="text-start">
              <button
                className="btn btn-primary w-md "
                type="submit"
                style={{ marginBottom: 50, marginTop: 20 }}
              >
                Submit
              </button>
            </Col>
          </Row>
        </Form>
        <Modal
          size="xl"
          isOpen={modal_xlarge}
          toggle={() => {
            tog_xlarge()
          }}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
              Crop & Compress Images
            </h5>
            {compressedAllFiles && (
              <button
                onClick={() => {
                  if (compressedAllFiles) {
                    setmodal_xlarge(false)
                  }
                }}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            )}
          </div>
          <div
            className="modal-body"
            style={{
              height: 500,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {compressLoader ? (
              <Loader />
            ) : (
              <Cropper
                image={imgSrc.src}
                crop={crop}
                // rotation={rotation}
                zoom={zoom}
                aspect={1}
                cropShape="round"
                onCropChange={c => {
                  setCrop(c)
                  console.log(c, "CC")
                }}
                // onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            )}
          </div>

          <Button color="primary" onClick={showCroppedImage}>
            Submit
          </Button>
        </Modal>
      </LayoutModal>
    </>
  )
}

AddItem.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  editData: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(AddItem))
