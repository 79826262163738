import React, { useState, useEffect } from "react"
import LayoutModal from "../../components/Custom/LayoutModel"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import "../user/datatables.scss"

import { connect } from "react-redux"
import PropTypes from "prop-types"

import { Row, Col } from "../imports/import"

import axios from "axios"

import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"

import { userEdit } from "../../store/actions"
import { withRouter } from "react-router-dom"

import Loader from "components/Custom/Loader"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import { BaseURL } from "config/BaseUrl"

function CategoryList(props) {
  const dispatch = useDispatch()

  const [itemList, setItemList] = useState([])
  const [sizePerPage, setSizePerPage] = useState(10)
  const [activeTooltip, setActiveTooltip] = useState(false)
  const [rowIndexTable, setRowIndexTable] = useState()
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseURL}/items/0`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))

        setItemList(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const editUserData = data => {
    console.log(data, "EDIT")
    try {
      localStorage.setItem("editData", JSON.stringify(data))
    } catch {
      console.log("user list editData localStorage fail")
    }

    try {
      dispatch(userEdit(data))

      props.history.push("/add-item")
    } catch (e) {
      console.log("user list redux editdata  fail")
    }
  }

  const changeStatus = data => {
    //   console.log(data, userList[userList.indexOf(data)] ,userList.indexOf(data)  , "STATUS DATA")
    setRowIndexTable(rowIndexTable - 1)
    setLoader(true)

    //   var dataa = JSON.stringify({
    //     "status": !data.isActive
    //   });

    var config = {
      method: "put",
      url: `${BaseURL}/items/${data._id}/${data.isActive ? "0" : "1"}`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      // data : dataa
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        setLoader(false)
        let values = itemList
        values[itemList.indexOf(data)].isActive = !data.isActive
        setItemList([...values])
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        console.log(row, cellContent, "SERVICES")
        let title
        if (cellContent.length > 180) {
          title = cellContent.substring(0, 180) + "..."
        } else {
          title = cellContent
        }
        return (
          <div
            style={{
              width: 300,
              wordBreak: "break-all",
              overflowWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            <p>{title}</p>
          </div>
        )
      },
    },
    {
      dataField: "brand.name",
      text: "Brand",
      sort: true,
    },
    {
      dataField: "category.name",
      text: "Category",
      sort: true,
    },
    {
      dataField: "unit.name",
      text: "Unit",
      sort: true,
    },
    {
      dataField: "mrp",
      text: "MRP",
      sort: true,
    },
    {
      dataField: "price",
      text: "Price",
      sort: true,
    },
    {
      dataField: "discount",
      text: "Discount",
      sort: true,
    },
    // {
    //   dataField: 'descriptionDisplay',
    //   text: 'Desc on Display',
    //   sort: true,
    //   formatter:(cellContent,row , rowIndex,field) => {
    //     console.log(row,cellContent ,"SERVICES")
    //     let title ;
    //     if(cellContent.length > 180){
    //       title = cellContent.substring(0,180) + "..."
    //     }else{
    //       title = cellContent
    //     }
    //     return <div style={{  width:300 ,
    //         wordBreak:"break-all" , overflowWrap:"break-word",
    //         whiteSpace:"break-spaces"
    //     }}><p >{title}</p></div>
    //  }

    // },
    // {
    //   dataField: 'descriptionPopup',
    //   text: 'Desc on Popup',
    //   sort: true,
    //   formatter:(cellContent,row , rowIndex,field) => {
    //     console.log(row,cellContent ,"SERVICES")
    //     let title ;
    //     if(cellContent.length > 180){
    //       title = cellContent.substring(0,180) + "..."
    //     }else{
    //       title = cellContent
    //     }
    //     return <div style={{  width:300 ,
    //         wordBreak:"break-all" , overflowWrap:"break-word",
    //         whiteSpace:"break-spaces"
    //     }}><p >{title}</p></div>
    //  }
    // },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        if (row.isActive) {
          return (
            <>
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable && "PopoverLegacy"}
                className="btn btn-success"
              >
                Active
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you Sure, You want to Deactivate this Item ?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="success"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        } else {
          return (
            <>
              {" "}
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable && "PopoverLegacy"}
                className="btn btn-danger"
                onClick={() =>
                  console.log(cellContent, row, "DATATATATAATATATATAT")
                }
              >
                Deactive
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you Sure, You want to Activate this Item ?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="success"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // console.log(rowIndex,"clicked")
          rowIndex !== rowIndexTable && setRowIndexTable(rowIndex)
        },
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              size="sm"
              type="button"
              className="btn btn-warning"
              onClick={() => editUserData(row)}
            >
              {/* <i className="bx bxs-pencil font-size-15 "></i> */}
              Edit
            </Button>
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPage: 50,
    sizePerPageRenderer,
    totalSize: itemList.length, // replace later with size(customers),
    // custom: true,
  }

  const { SearchBar } = Search

  if (loader) {
    return <Loader />
  }

  return (
    <>
      <Tostify />
      <LayoutModal
        title="Item List"
        sideTitle="User"
        button={true}
        btnTitle="Add Item"
        onclick={() => {
          props.history.push("/add-item")
        }}
      >
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="_id"
          columns={columns}
          data={itemList}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="_id"
              columns={columns}
              data={itemList}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          // keyField={"email"}

                          responsive
                          bordered={false}
                          striped={true}
                          //  // defaultSorted={defaultSorted}
                          hover
                          // selectRow={selectRow}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                     
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row> */}
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </LayoutModal>
    </>
  )
}
CategoryList.propTypes = {
  userData: PropTypes.object,
  editUser: PropTypes.any,
  history: PropTypes.any,
}

const mapStateToProps = state => {
  return { userData: state.userData }
}

export default connect(mapStateToProps)(withRouter(CategoryList))
