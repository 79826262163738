import React, { useState, useEffect } from "react"
import LayoutModal from "../../components/Custom/LayoutModel"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import "../user/datatables.scss"

import { connect } from "react-redux"
import PropTypes from "prop-types"

import { Row, Col } from "../imports/import"

import axios from "axios"

import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"

import { userEdit } from "../../store/actions"
import { withRouter } from "react-router-dom"

import Loader from "components/Custom/Loader"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import { BaseURL, ImageURL } from "config/BaseUrl"

function VideoList(props) {
  const dispatch = useDispatch()

  const [videoListData, setVideoList] = useState([])
  const [sizePerPage, setSizePerPage] = useState(10)
  const [activeTooltip, setActiveTooltip] = useState(false)
  const [rowIndexTable, setRowIndexTable] = useState()
  const [loader, setLoader] = useState(false)

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseURL}/helpcenters/0`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))

        setVideoList(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
    // console.log(config);
  }, [])
  const editUserData = data => {
    console.log(data, "EDIT")
    try {
      localStorage.setItem("editData", JSON.stringify(data))
    } catch {
      console.log("user list editData localStorage fail")
    }

    try {
      dispatch(userEdit(data))

      props.history.push("/add-helpcenter")
    } catch (e) {
      console.log("user list redux editdata  fail")
    }
  }
  const changeStatus = data => {
    //   console.log(data, userList[userList.indexOf(data)] ,userList.indexOf(data)  , "STATUS DATA")
    setRowIndexTable(rowIndexTable - 1)

    setLoader(true)
    //   var dataa = JSON.stringify({
    //     "status": !data.isActive
    //   });

    var config = {
      method: "put",
      url: `${BaseURL}/helpcenters/${data._id}/${data.isActive ? "0" : "1"}`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      // data : dataa
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        setLoader(false)
        let values = videoListData
        values[videoListData.indexOf(data)].isActive = !data.isActive
        setVideoList([...values])
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        if (row.isActive) {
          return (
            <>
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable ? "PopoverLegacy" : ""}
                className="btn btn-success"
              >
                Active
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you Sure, You want to Deactive this Item ?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="success"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        } else {
          return (
            <>
              {" "}
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable && "PopoverLegacy"}
                className="btn btn-danger"
                onClick={() =>
                  console.log(cellContent, row, "DATATATATAATATATATAT")
                }
              >
                Deactive
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you Sure, You want to Activate this Item ?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="success"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          console.log(rowIndex, "clicked")
          rowIndex !== rowIndexTable && setRowIndexTable(rowIndex)
        },
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              size="sm"
              type="button"
              className="btn btn-warning"
              onClick={() => editUserData(row)}
            >
              {/* <i className="bx bxs-pencil font-size-15 "></i> */}
              Edit
            </Button>
            {row.link != "" ? (
              <a
                style={{ marginLeft: 10 }}
                size="sm"
                type="button"
                target="blank"
                className="btn btn-danger btn-sm"
                href={row.link}
              >
                {/* <i className="bx bxs-pencil font-size-15 "></i> */}
                Video
              </a>
            ) : (
              <a
                style={{ marginLeft: 10, width: 50 }}
                size="sm"
                type="button"
                target="blank"
                className="btn btn-success btn-sm"
                href={`${ImageURL}/helpcenter/${row.pdf.filename}`}
              >
                PDF
              </a>
            )}
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )
  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]
  const pageOptions = {
    sizePerPageRenderer,
    sizePerPage: 50,
    totalSize: videoListData.length, // replace later with size(customers),
    // custom: true,
  }
  const { SearchBar } = Search
  if (loader) {
    return <Loader />
  }

  return (
    <>
      <Tostify />
      <LayoutModal
        title="Help Center List"
        form={true}
        sideTitle="User"
        button={true}
        btnTitle="Add Help Center"
        onclick={() => {
          props.history.push("/add-helpcenter")
        }}
      >
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="_id"
          columns={columns}
          data={videoListData}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="_id"
              columns={columns}
              data={videoListData}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          keyField={"_id"}
                          responsive
                          bordered={false}
                          striped={true}
                          // defaultSorted={defaultSorted}
                          hover
                          // selectRow={selectRow}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </LayoutModal>
    </>
  )
}

VideoList.propTypes = {
  userData: PropTypes.object,
  editUser: PropTypes.any,
  history: PropTypes.any,
}

const mapStateToProps = state => {
  return { userData: state.userData }
}

export default connect(mapStateToProps)(withRouter(VideoList))
