import React, { useState, useEffect } from "react"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import "./datatables.scss"

import { connect } from "react-redux"
import PropTypes from "prop-types"

import { Row, Col } from "../imports/import"
import { Container, Label } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import { withRouter } from "react-router-dom"
import Loader from "components/Custom/Loader"

function NotificationPartyTable(props) {
  const dispatch = useDispatch()
  const [partyList, setPartyList] = useState(props.data.parties)
  const [loader, setLoader] = useState(false)

  console.log(props.data, "DIRECTORY DATA")

  const columns = [
    {
      dataField: "dummy",
      isDummyField: true,
      text: "SEQ",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        return (
          <div>
            <p>{rowIndex + 1}</p>
          </div>
        )
      },
    },

    {
      dataField: "partyName",
      text: "Party Name",
      sort: true,
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPageRenderer,
    sizePerPage: 50,
    totalSize: partyList.length, // replace later with size(customers),
    // custom: true,
  }

  const { SearchBar } = Search

  if (loader) {
    return <Loader />
  }

  return (
    <Container fluid>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div className="mb-1">
          <h3 className="form-label">Party List</h3>
        </div>

        <div
          className="mb-1"
          style={{ display: "flex", flexDirection: "flex-end" }}
        >
          <i
            className="bx bx-x-circle mb-3"
            onClick={props.onClick}
            style={{
              fontSize: 25,
              color: "#FF5858",
              cursor: "pointer",
            }}
          ></i>
        </div>
      </div>

      <Row
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      ></Row>

      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="_id"
        columns={columns}
        data={partyList}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="_id"
            columns={columns}
            data={partyList}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2">
                  <h6 style={{ marginTop: 10, position: "absolute" }}>
                    {props.dirData.name}
                  </h6>
                  <Col
                    md="4"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar {...toolkitProps.searchProps} />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        // keyField={"email"}
                        responsive
                        bordered={true}
                        striped={false}
                        // defaultSorted={defaultSorted}
                        hover
                        // selectRow={selectRow}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </Container>
  )
}
NotificationPartyTable.propTypes = {
  userData: PropTypes.object,
  editUser: PropTypes.any,
  history: PropTypes.any,
  data: PropTypes.any,
  dirData: PropTypes.object,
  delivered: PropTypes.bool,
  onClick: PropTypes.any,
}

const mapStateToProps = state => {
  return { userData: state.userData }
}

export default connect(mapStateToProps)(withRouter(NotificationPartyTable))
