import React from "react"
import { Label, Form } from "reactstrap"
import { useState, useEffect, useRef } from "react"
import Select from "react-select"
import PropTypes from "prop-types"

function Selector(props) {
  const [selectData, setSelectData] = useState([])
  const [defaultVal, setDefaultVal] = useState(null)
  const [change, setChange] = useState(true)

  const formRef = useRef()

  // useEffect(() =>{
  //   console.log(props.edit , "EDIT PROPS")

  // },[props.edit])

  // useEffect(() => {

  //   // if(props.edit){

  //   //   formRef.current.value = props.edit

  //   //   if(formRef.current.state.prevProps.value != null) formRef.current.state.prevProps.value.label = props.edit.label
  //   //   if(formRef.current.state.prevProps.value != null) formRef.current.state.prevProps.value.value = props.edit.value

  //   //   console.log(formRef.current.value != null && formRef.current.value.label ,"LBELLLLL")
  //   // }

  // },[])

  useEffect(() => {
    // console.log("EDIT DATA", props.edit, formRef.current)
    // console.log(props.edit, "mmmmmmm")
    let arrayOfData = []
    props.data &&
      props.data.map((d, i) => {
        var val = { value: d._id, label: d.name }
        arrayOfData.push(val)
      })
    setSelectData(arrayOfData)
  }, [props])

  const customStyles = {
    container: provided => ({
      ...provided,
      width: 300,
    }),

    menu: (provided, state) => ({
      ...provided,
      maxHeight: 150,
      width: 300,
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150,
    }),
  }

  return (
    <div
      className="col-12"
      style={{
        marginBottom: 10,
        // zIndex: 1000,
      }}
      //   className={props.classname}
    >
      <label>
        {props.label}{" "}
        {props.required && <span style={{ color: "red" }}>*</span>}
      </label>
      <Select
        isDisabled={props.isDisable}
        value={props.valueNull}
        ref={props.refValue}
        // styles={props.fullWidth ? "none" :customStyles}
        defaultValue={props.edit != null && props.edit}
        theme={theme => ({
          ...theme,
          borderRadius: 5,

          colors: {
            ...theme.colors,
          },
        })}
        isMulti={props.isMulti}
        onChange={props.onchange}
        options={selectData}
      />
    </div>
  )
}

Selector.propTypes = {
  edit: PropTypes.object,
  data: PropTypes.any,
  label: PropTypes.string,
  onchange: PropTypes.any,
  fullWidth: PropTypes.any,
  required: PropTypes.bool,
  classname: PropTypes.any,
  isMulti: PropTypes.bool,
  key: PropTypes.any,
  isDisable: PropTypes.bool,
  valueNull: PropTypes.any,
  refValue: PropTypes.any,
}

export default Selector
