import React, { useState, useRef, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import LayoutModal from "../../components/Custom/LayoutModel"
import { userEdit } from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import NotificationPartyTable from "pages/Notify/NotificationPartyTable"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  FormGroup,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
  Modal,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Loader from "components/Custom/Loader"
import Tostify from "components/Custom/Tostify"
import { BaseURL, ImageURL } from "config/BaseUrl"
import { toast } from "react-toastify"
import Selector from "components/Custom/Selector"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

function Notification(props) {
  const [files, setFiles] = useState(null)
  const [filesErr, setFilesErr] = useState("")
  const [brandName, setBrandName] = useState("")
  const [brandNameErr, setBrandNameErr] = useState("")
  const [showEditImage, setShowEditImage] = useState(true)
  const [loader, setLoader] = useState(false)
  const [rowIndexTable, setRowIndexTable] = useState()

  const [selectAll, setSelectAll] = useState(true)
  const [selectUser, setSelectUser] = useState(false)
  const [partyList, setPartyList] = useState(null)
  const [selectedParty, setSelectedParty] = useState([])
  const [notificationData, setNotification] = useState("")
  const [modal_xlarge, setmodal_xlarge] = useState(false)

  const [categoryList, setCategoryList] = useState([])
  const [selectCategory, setSelectCategory] = useState(null)
  const [brandList, setBrandList] = useState([])
  const [selectBrand, setSelectBrand] = useState(null)
  const [itemList, setItemList] = useState([])
  const [selectItem, setSelectItem] = useState(null)

  const brandValue = useRef(null)
  const categoryValue = useRef(null)
  const itemValue = useRef(null)

  const dispatch = useDispatch()

  // console.log(process.env)

  const fileRef = useRef()
  const nameRef = useRef()

  // useEffect(() => {
  //   const data = props.editData

  //   // console.log("dataaaa",data)

  //   if (props.editData) {
  //     document.getElementById("title").value = data.name
  //     setBrandName(data.name)
  //     // setFiles(props.editData.file)
  //   } else {
  //     document.getElementById("title").value = ""
  //     setBrandName("")
  //   }

  //   return () => dispatch(userEdit(null))
  // }, [props.editData])

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseURL}/formdata/banner`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data), "karankarn")
        setCategoryList(response.data.categories)
        setBrandList(response.data.brands)
        setItemList(response.data.items)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (selectCategory != null) {
      console.log("Category")
      setSelectBrand(null)
      setSelectItem(null)
      brandValue.current.setValue("")
      itemValue.current.setValue("")
    }
  }, [selectCategory])

  useEffect(() => {
    if (selectBrand != null) {
      console.log("Brand")
      setSelectCategory(null)
      setSelectItem(null)
      categoryValue.current.setValue("")
      itemValue.current.setValue("")
    }
  }, [selectBrand])

  useEffect(() => {
    if (selectItem != null) {
      console.log("Item")
      setSelectCategory(null)
      setSelectBrand(null)
      categoryValue.current.setValue("")
      brandValue.current.setValue("")
    }
  }, [selectItem])

  useEffect(() => {
    document.getElementById("radio1").checked = true
    var config = {
      method: "get",
      url: `${BaseURL}/formdata/party`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))

        setPartyList(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    var config = {
      method: "get",
      url: BaseURL + "/notifications",
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      // data: data,
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))

        setNotification(response.data.notifications)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  // console.log("karan", notificationData)
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      title: "",
      body: "",
      // file: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Title"),
      body: Yup.string().required("Please Enter Your Body"),
      // file: props.editData
      //   ? Yup.mixed()
      //   : Yup.mixed().required("Please select a file"),
    }),
    onSubmit: values => {
      console.log(values, "SSSSSSSS")

      if (files == null) {
        setBrandNameErr("Brand name required")
        nameRef.current.invalid = true
      }

      if (
        files !== null &&
        files.size > Number(process.env.REACT_APP_FILE_SIZE)
      ) {
        // fileRef.current = null
        // console.log("Hello")
        // setFilesErr("File is required")
        toast.error("File size exceeds 2MB limit", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      } else {
        var data = new FormData()
        data.append("title", values.title)
        data.append("message", values.body)
        files !== null && data.append("file", files)
        data.append("isSendToAll", selectAll ? "1" : "0")
        data.append(
          "refId",
          selectCategory != null
            ? selectCategory
            : selectBrand != null
            ? selectBrand
            : selectItem
            ? selectItem
            : null
        )
        data.append(
          "type",
          selectCategory != null
            ? "category"
            : selectBrand != null
            ? "brand"
            : selectItem
            ? "item"
            : null
        )
        !selectAll &&
          selectedParty != "undefined" &&
          selectedParty.length > 0 &&
          selectedParty.map((d, i) => {
            console.log(d._id, "IDIDIDIDIDIDIDIDIDIDiot")
            data.append(`parties[${i}]`, d.value)
          })
        // data.append("parties[0]", "629db0d6fc2a03f9c2384db4")
        // data.append("parties[1]", "6290cd9908a238c8eb66fa6b")

        var config = {
          method: props.editData ? "put" : "post",
          url: `${BaseURL}/notifications${
            props.editData ? "/" + props.editData._id : ""
          }`,
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": props.userData.token,
          },
          data: data,
        }

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data))
            setLoader(false)
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
            document.getElementById("radio1").checked = true
            document.getElementById("title").value = ""
            document.getElementById("body").value = ""
            setSelectedParty([])
            setSelectAll(true)
            setSelectUser(false)
            setFiles(null)
            window.location.reload(false)
          })
          .catch(function (error) {
            console.log(error)
            setLoader(false)
            toast.error(error.response && error.response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
          })

        // data.append("name", values.name)
        // files !== null && data.append("doc", files)

        // axios(config)
        //   .then(function (response) {
        //     console.log(JSON.stringify(response.data))
        //     setLoader(false)
        //     // document.getElementById("name").value = ""
        //     // document.getElementById("file").value = ""
        //     // setBrandName("")
        //     // dispatch(userEdit(null))
        //     // setFiles(null)
        //     toast.success(response.data.message, {
        //       position: "top-right",
        //       autoClose: 5000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //     })
        //   })
        //   .catch(function (error) {
        //     setLoader(false)
        //     toast.error(error.response && error.response.data.message, {
        //       position: "top-right",
        //       autoClose: 5000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //     })
        //     console.log(error)
        //   })
      }
    },
  })

  const handleSubmit = () => {
    if (files == null) {
      setBrandNameErr("Brand name required")
      nameRef.current.invalid = true
    }

    if (
      files !== null &&
      files.size > Number(process.env.REACT_APP_FILE_SIZE)
    ) {
      fileRef.current = null
      console.log("Hello")
      setFilesErr("File is required")
      toast.error("File size exceeds 2MB limit", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    } else {
      setLoader(true)
      var data = new FormData()
      data.append("name", brandName)
      data.append("doc", files)

      var config = {
        method: props.editData ? "put" : "post",
        url: `${BaseURL}/brands${
          props.editData ? "/" + props.editData._id : ""
        }`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": props.userData.token,
        },
        data: data,
      }

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data))
          setLoader(false)
          document.getElementById("name").value = ""
          document.getElementById("file").value = ""
          setBrandName("")
          dispatch(userEdit(null))
          setFiles(null)
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch(function (error) {
          setLoader(false)
          toast.error(error.response && error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
          console.log(error)
        })
    }

    // console.log(brandName ,props.editData.file.filename, files);
  }
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge)
  }
  const columns = [
    {
      dataField: "title",
      text: "Title",
      sort: true,
    },
    {
      dataField: "message",
      text: "Message",
      sort: true,
    },
    {
      dataField: "df0",
      isDummyField: true,
      text: "Image",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            {row.file && (
              <img
                data-dz-thumbnail=""
                height="80"
                className="avatar-sm rounded bg-light"
                style={{
                  objectFit: "cover",
                }}
                // alt={files.name}
                src={`${ImageURL}/notification_images/${row.file.filename}`}
              />
            )}
          </div>
        )
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            {row.isSendToAll != true ? (
              <Button
                size="sm"
                type="button"
                style={{ marginLeft: 10 }}
                className="btn btn-link"
                color="secondary"
                onClick={() => setmodal_xlarge(true)}
              >
                {/* <i className="bx bxs-pencil font-size-15 "></i> */}
                Parties
              </Button>
            ) : (
              <Button
                size="sm"
                type="button"
                style={{ marginLeft: 10 }}
                className="btn btn-link"
                disabled={true}
                color="secondary"
              >
                {/* <i className="bx bxs-pencil font-size-15 "></i> */}
                Parties
              </Button>
            )}

            {rowIndex === rowIndexTable && modal_xlarge && (
              <Modal
                size="md"
                isOpen={modal_xlarge}
                toggle={() => {
                  tog_xlarge()
                }}
              >
                <div className="modal-header">
                  <NotificationPartyTable
                    data={row}
                    dirData={row}
                    onClick={() => setmodal_xlarge(!modal_xlarge)}
                  />
                </div>
              </Modal>
            )}
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]
  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )
  const pageOptions = {
    sizePerPageRenderer,
    sizePerPage: 50,
    totalSize: notificationData.length, // replace later with size(customers),
    // custom: true,
  }

  const { SearchBar } = Search

  if (loader) {
    return <Loader />
  }

  return (
    <>
      <Tostify />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="col-sm-12 col-lg-4">
          <LayoutModal
            button={false}
            // form={true}
            title={props.editData ? "Edit Notification" : "Add Notification"}
            // btnTitle={props.editData ? "Add Notification" : "Notification List"}
            onclick={() => {
              props.editData
                ? dispatch(userEdit(null))
                : props.history.push("/brand-list")
            }}
          >
            <Form
              onSubmit={validation.handleSubmit}
              // onSubmit={ props.editData ? editUser  : submitAddUser}
            >
              <div className="mb-3">
                <Label className="form-label">
                  Title <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  ref={nameRef}
                  name="title"
                  id="title"
                  className="form-control"
                  placeholder="Enter your title"
                  type="text"
                  // onChange={(e) => setBrandName(e.target.value)}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.title}
                  invalid={
                    validation.touched.title && validation.errors.title
                      ? true
                      : false
                  }
                />
                {validation.touched.title && validation.errors.title ? (
                  <FormFeedback type="invalid">
                    {validation.errors.title}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">
                  Body <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  // ref={nameRef}
                  name="body"
                  id="body"
                  className="form-control"
                  placeholder="Enter body"
                  type="text"
                  // onChange={(e) => setBrandName(e.target.value)}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.body}
                  invalid={
                    validation.touched.body && validation.errors.body
                      ? true
                      : false
                  }
                />
                {validation.touched.body && validation.errors.body ? (
                  <FormFeedback type="invalid">
                    {validation.errors.body}
                  </FormFeedback>
                ) : null}
              </div>

              <div className="mb-3">
                <Label className="form-label">Select File (1320 * 480)</Label>
                <Input
                  name="file"
                  id="file"
                  className="form-control"
                  // placeholder="Enter your new password"
                  type="file"
                  // onChange={(e) => setFiles(e.target.files[0])}
                  onChange={e => {
                    validation.handleChange(e)
                    setFiles(e.target.files[0])
                  }}
                  onBlur={validation.handleBlur}
                  ref={fileRef}
                  value={validation.file || ""}
                  invalid={
                    (validation.touched.file &&
                      files !== null &&
                      files.size > Number(process.env.REACT_APP_FILE_SIZE)) ||
                    validation.errors.file
                      ? true
                      : false
                  }
                />

                {validation.touched.file &&
                files !== null &&
                files.size > Number(process.env.REACT_APP_FILE_SIZE) ? (
                  <FormFeedback type="invalid">
                    File must be less than 2 MB
                  </FormFeedback>
                ) : null}

                {validation.touched.file && validation.errors.file ? (
                  <FormFeedback type="invalid">
                    {validation.errors.file}
                  </FormFeedback>
                ) : null}
              </div>

              {files !== null && (
                <Card
                  className="mt-1 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                  style={{
                    backgroundColor:
                      files.size > Number(process.env.REACT_APP_FILE_SIZE)
                        ? "#ff6a6a"
                        : "#2FDD92",
                    color:
                      files.size > Number(process.env.REACT_APP_FILE_SIZE)
                        ? "#ffffff"
                        : "#131313",
                    width: "97%",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <img
                          data-dz-thumbnail=""
                          height="80"
                          className="avatar-sm rounded bg-light"
                          style={{
                            objectFit: "cover",
                          }}
                          alt={files.name}
                          src={URL.createObjectURL(files)}
                        />
                      </Col>
                      <Col>
                        <Link
                          to="#"
                          // className="text-muted font-weight-bold"
                          style={{
                            color:
                              files.size >
                              Number(process.env.REACT_APP_FILE_SIZE)
                                ? "#ffffff"
                                : "#131313",
                          }}
                        >
                          {files.name}
                        </Link>
                        <p className="mb-0">
                          <strong>
                            {(files.size / 1048576).toFixed(2) + " MB"}
                          </strong>
                        </p>
                        <i
                          className="bx bx-no-entry"
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 10,
                            color:
                              files.size >
                              Number(process.env.REACT_APP_FILE_SIZE)
                                ? "#ffffff"
                                : "#131313",
                            fontSize: 17,
                            fontWeight: 800,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setFiles(null)
                            document.getElementById("file").value = ""
                          }}
                        ></i>
                      </Col>
                    </Row>
                  </div>
                </Card>
              )}

              {props.editData &&
                props.editData.file &&
                showEditImage &&
                files == null && (
                  <Card
                    className="mt-1 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    style={{
                      backgroundColor:
                        props.editData.file.fileSize >
                        Number(process.env.REACT_APP_FILE_SIZE)
                          ? "#ff6a6a"
                          : "#2FDD92",
                      color:
                        props.editData.file.fileSize >
                        Number(process.env.REACT_APP_FILE_SIZE)
                          ? "#ffffff"
                          : "#131313",
                      width: "97%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            style={{
                              objectFit: "cover",
                            }}
                            alt={props.editData.file.originalname}
                            // alt={files.name}
                            src={`${ImageURL}/brands/${props.editData.file.filename}`}
                          />
                        </Col>
                        <Col>
                          <Link
                            to="#"
                            // className="text-muted font-weight-bold"
                            style={{
                              color:
                                props.editData.file.fileSize >
                                Number(process.env.REACT_APP_FILE_SIZE)
                                  ? "#ffffff"
                                  : "#131313",
                            }}
                          >
                            {props.editData.file.originalname}
                          </Link>
                          <p className="mb-0">
                            <strong>
                              {(props.editData.file.fileSize / 1024).toFixed(
                                2
                              ) + " MB"}
                            </strong>
                          </p>
                          <i
                            className="bx bx-no-entry"
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 10,
                              color:
                                props.editData.file.fileSize >
                                Number(process.env.REACT_APP_FILE_SIZE)
                                  ? "#ffffff"
                                  : "#131313",
                              fontSize: 17,
                              fontWeight: 800,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowEditImage(false)
                            }}
                          ></i>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )}

              <div className="mb-3">
                <Selector
                  label="Brand"
                  // required={true}
                  id="brand"
                  //   isDisable={selectBrand !== null ? true : false}
                  refValue={brandValue}
                  // valueNull=""
                  data={brandList !== null ? brandList : []}
                  onchange={event => {
                    setSelectBrand(event.value)
                  }}
                  // edit={
                  //   editBanner &&
                  //   editBanner.type == "brand" && {
                  //     label: editBanner.brandId.name,
                  //     value: editBanner.brandId._id,
                  //   }
                  // }
                />
              </div>
              <div className="mb-3">
                <Selector
                  label="Category"
                  // required={true}
                  id="category"
                  refValue={categoryValue}
                  data={categoryList !== null ? categoryList : []}
                  onchange={event => {
                    setSelectCategory(event.value)
                  }}
                  // edit={
                  //   editBanner &&
                  //   editBanner.type == "category" && {
                  //     label: editBanner.categoryId.name,
                  //     value: editBanner.categoryId._id,
                  //   }
                  // }
                />
              </div>
              <div className="mb-3">
                <Selector
                  label="Item"
                  // required={true}
                  id="item"
                  refValue={itemValue}
                  data={itemList !== null ? itemList : []}
                  onchange={event => {
                    setSelectItem(event.value)
                  }}
                  // edit={
                  //   editBanner &&
                  //   editBanner.type == "item" && {
                  //     label: editBanner.itemId.name,
                  //     value: editBanner.itemId._id,
                  //   }
                  // }
                />
              </div>

              <FormGroup
                tag="fieldset"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "space-between",
                }}
              >
                <Label className="form-label">
                  Send To <span style={{ color: "red" }}>*</span>
                </Label>
                <FormGroup style={{ marginLeft: 30 }} check>
                  <Label check>
                    <Input
                      type="radio"
                      name="radio1"
                      id="radio1"
                      // checked={selectAll}
                      onChange={e => {
                        setSelectAll(true)
                        setSelectUser(false)
                      }}
                    />{" "}
                    ALL
                  </Label>
                </FormGroup>
                <FormGroup style={{ marginLeft: 20 }} check>
                  <Label check>
                    <Input
                      type="radio"
                      name="radio1"
                      onChange={e => {
                        setSelectAll(false)
                        setSelectUser(true)
                      }}
                    />{" "}
                    Select User
                  </Label>
                </FormGroup>
              </FormGroup>

              {selectUser && (
                <div
                  className="mb-3"
                  style={{
                    zIndex: 1000,
                    display: "flex",
                  }}
                >
                  <Selector
                    data={partyList !== null ? partyList : []}
                    label="Party List"
                    required
                    onchange={event => {
                      // console.log(event, "EEEVVVVV")
                      setSelectedParty(event)
                    }}
                    isMulti={true}
                    // edit={
                    //   editReason !== null && {
                    //     label: editReason.orderStatus.name,
                    //     value: editReason.orderStatus._id,
                    //   }
                    // }
                  />
                </div>
              )}

              <Row className="mb-3">
                <Col className="text-start">
                  <button
                    className="btn btn-primary w-md "
                    type="submit"
                    // onClick={() => handleSubmit()}
                  >
                    Submit
                  </button>
                </Col>
              </Row>
            </Form>
          </LayoutModal>
        </div>
        <div className="col-lg-8">
          <LayoutModal>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="_id"
              columns={columns}
              data={notificationData}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="_id"
                  columns={columns}
                  data={notificationData}
                  // search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col md="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={"_id"}
                              responsive
                              bordered={false}
                              striped={true}
                              // defaultSorted={defaultSorted}
                              hover
                              // selectRow={selectRow}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </LayoutModal>
        </div>
      </div>
    </>
  )
}

Notification.propTypes = {
  userData: PropTypes.object,
  editData: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(Notification))
