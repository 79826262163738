import React, { useState, useRef, useEffect } from "react"
import Tostify from "components/Custom/Tostify"
import { toast } from "react-toastify"
import Loader from "components/Custom/Loader"
import NotificationPartyTable from "pages/Notify/NotificationPartyTable"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  FormGroup,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
  Modal,
} from "reactstrap"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import BootstrapTable from "react-bootstrap-table-next"
import PropTypes from "prop-types"
import * as Yup from "yup"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import LayoutModal from "../../components/Custom/LayoutModel"
import Selector from "components/Custom/Selector"
import { useFormik } from "formik"
import { BaseURL, ImageURL } from "config/BaseUrl"
import axios from "axios"

function AddSlider(props) {
  const [files, setFiles] = useState(null)
  const [selectUser, setSelectUser] = useState(false)
  const [categoryList, setCategoryList] = useState([])
  const [selectCategory, setSelectCategory] = useState(null)
  const [brandList, setBrandList] = useState([])
  const [selectBrand, setSelectBrand] = useState(null)
  const [itemList, setItemList] = useState([])
  const [selectItem, setSelectItem] = useState(null)
  const [bannerData, setBanner] = useState("")
  const [loader, setLoader] = useState(false)
  const [rowIndexTable, setRowIndexTable] = useState()
  const [editBanner, setEditData] = useState(null)
  const [showEditImage, setShowEditImage] = useState(true)

  const brandValue = useRef(null)
  const categoryValue = useRef(null)
  const itemValue = useRef(null)
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      file: "",
    },
    validationSchema: Yup.object({
      file:
        editBanner !== null
          ? Yup.mixed()
          : Yup.mixed().required("Please select a file"),
    }),
    onSubmit: values => {
      console.log(values, "SSSSSSSS")
      if (
        selectCategory == null &&
        selectBrand == null &&
        selectItem == null &&
        editBanner !== null
      ) {
        // console.log("kkkkkk")
        return toast.error("You can not submit form with same values", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      if (selectCategory == null && selectBrand == null && selectItem == null) {
        // console.log("kkkkkk")
        return toast.error("Please Select  at least one Option", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
      if (
        files !== null &&
        files.size > Number(process.env.REACT_APP_FILE_SIZE)
      ) {
        // fileRef.current = null
        // console.log("Hello")
        // setFilesErr("File is required")
        toast.error("File size exceeds 2MB limit", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      } else {
        var data = new FormData()
        if (selectCategory != null) {
          var refId = selectCategory
          var type = "category"
        } else if (selectBrand != null) {
          var refId = selectBrand
          var type = "brand"
        } else if (selectItem != null) {
          var refId = selectItem
          var type = "item"
        }
        files !== null && data.append("doc", files)
        data.append("refId", refId)
        data.append("type", type)

        var config = {
          method: editBanner !== null ? "put" : "post",
          url: `${BaseURL}/banners${
            editBanner !== null ? "/update/" + editBanner._id : ""
          }`,
          headers: {
            "x-auth-token": props.userData.token,
            "Content-Type": "application/json",
          },
          data: data,
        }

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data))
            // brandValue.current.setValue("")
            setLoader(true)
            window.location.reload(false)
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
          })
          .catch(function (error) {
            console.log(error)
            toast.error(error.response && error.response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
          })
      }
    },
  })
  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseURL}/formdata/banner`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data), "karankarn")
        setCategoryList(response.data.categories)
        setBrandList(response.data.brands)
        setItemList(response.data.items)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])
  useEffect(() => {
    if (editBanner != null) {
      console.log(editBanner, "pops")
    }
  }, [editBanner])
  useEffect(() => {
    var config = {
      method: "get",
      url: BaseURL + "/banners/0",
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))

        setBanner(response.data.banner)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (selectCategory != null) {
      console.log("Category")
      setSelectBrand(null)
      setSelectItem(null)
      brandValue.current.setValue("")
      itemValue.current.setValue("")
    }
  }, [selectCategory])

  useEffect(() => {
    if (selectBrand != null) {
      console.log("Brand")
      setSelectCategory(null)
      setSelectItem(null)
      categoryValue.current.setValue("")
      itemValue.current.setValue("")
    }
  }, [selectBrand])

  useEffect(() => {
    if (selectItem != null) {
      console.log("Item")
      setSelectCategory(null)
      setSelectBrand(null)
      categoryValue.current.setValue("")
      brandValue.current.setValue("")
    }
  }, [selectItem])

  const changeStatus = data => {
    console.log(
      data,
      bannerData[bannerData.indexOf(data)],
      bannerData.indexOf(data),
      "STATUS DATA"
    )
    setRowIndexTable(rowIndexTable - 1)

    setLoader(true)
    // var dataa = JSON.stringify({
    //   "status": !data.isActive
    // });

    var config = {
      method: "put",
      url: `${BaseURL}/banners/status/${data._id}/${data.isActive ? "0" : "1"}`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        let values = bannerData
        values[bannerData.indexOf(data)].isActive = !data.isActive
        setBanner([...values])
        setLoader(false)
        console.log(response.data.message, "pppppp")
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }
  const deleteBanner = data => {
    const id = data._id
    // console.log(id, "deleteId")

    setLoader(true)

    var config = {
      method: "put",
      url: `${BaseURL}/banners/delete/${id}`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      // data: data,
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        bannerData.splice(bannerData.indexOf(data), 1)
        setBanner(bannerData)
        setLoader(false)
        // setShowEditImage(false)
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  const columns = [
    // row.categoryId &&
    {
      dataField: "dummy",
      isDummyField: true,
      // ("categoryId" !== null && "categoryId.name ") ||
      // ("brandId" !== null && "brandId.name") ||
      // ("itemId" !== null && "itemId.name"),
      text: "Name",
      sort: true,
      formatter: (cellContent, row, rowIndex) => {
        // console.log(row, "rowdataddddd")
        let sliderName = ""
        if (row.brandId != null) {
          sliderName = row.brandId.name
        } else if (row.categoryId != null) {
          sliderName = row.categoryId.name
        } else if (row.itemId != null) {
          sliderName = row.itemId.name
        }
        return (
          <div
            style={{
              width: 200,
              wordBreak: "break-all",
              overflowWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            <p>{sliderName}</p>
          </div>
        )
      },
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
    },
    {
      dataField: "df0",
      isDummyField: true,
      text: "Image",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            {row.file && (
              <img
                data-dz-thumbnail=""
                height="80"
                className="avatar-sm rounded bg-light"
                style={{
                  objectFit: "cover",
                }}
                // alt={files.name}
                src={`${ImageURL}/banner_images/${row.file.filename}`}
              />
            )}
          </div>
        )
      },
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        if (row.isActive) {
          return (
            <>
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable && "PopoverLegacy"}
                className="btn btn-success"
                onClick={() => {
                  console.log(
                    cellContent,
                    row,
                    rowIndex,
                    field,
                    "DATATATATAATATATATAT"
                  )
                }}
              >
                Active
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you Sure, You want to Deactivate this Banner ?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="success"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        } else {
          return (
            <>
              {" "}
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable && "PopoverLegacy"}
                className="btn btn-danger"
                onClick={() =>
                  console.log(cellContent, row, "DATATATATAATATATATAT")
                }
              >
                Deactive
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you Sure, You want to Activate this Banner ?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="success"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        }
      },

      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // console.log(rowIndex,"clicked")
          rowIndex !== rowIndexTable && setRowIndexTable(rowIndex)
        },
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
    {
      dataField: "dummy01",
      text: "Action",
      isDummyField: true,
      formatter: (cellContent, row, rowIndex) => {
        // console.log(row, "wwwwwww")
        // let nameSet = {}
        // if (row.brandId != null) {
        //   nameSet = row.brandId._id
        // }
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              size="sm"
              type="button"
              className="btn btn-warning "
              onClick={() => {
                setLoader(true)
                setEditData(row)
                setTimeout(() => {
                  setLoader(false)
                }, 500)
              }}
            >
              {/* <i className="bx bxs-pencil font-size-15 "></i> */}
              Edit
            </Button>
            <button
              size="sm"
              type="button"
              style={{ marginLeft: 10 }}
              className="btn btn-danger btn-sm"
              id={rowIndex === rowIndexTable && "PopoverLegacy01"}
              // onClick={console.log(cellContent, row, "DATATATATAATATATATAT")}
            >
              Delete
            </button>
            {rowIndex === rowIndexTable && (
              <UncontrolledPopover
                placement="top"
                target="PopoverLegacy01"
                trigger="legacy"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <PopoverHeader style={{ textAlign: "center" }}>
                  Are you Sure, You want to Delete this Banner ?
                </PopoverHeader>
                <PopoverBody>
                  <Button
                    size="sm"
                    style={{
                      marginRight: 5,
                    }}
                    onClick={() => deleteBanner(row)}
                    color="success"
                  >
                    Yes
                  </Button>
                  <Button
                    size="sm"
                    style={{
                      marginLeft: 5,
                    }}
                    onClick={() => setRowIndexTable(rowIndexTable - 1)}
                    color="danger"
                  >
                    No
                  </Button>
                </PopoverBody>
              </UncontrolledPopover>
            )}
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]
  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )
  const pageOptions = {
    sizePerPageRenderer,
    sizePerPage: 50,
    totalSize: bannerData.length, // replace later with size(customers),
    // custom: true,
  }

  const { SearchBar } = Search

  if (loader) {
    return <Loader />
  }

  return (
    <>
      <Tostify />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="col-sm-12 col-lg-4">
          <LayoutModal
            button={false}
            // form={true}
            title={props.editData ? "Edit Slider" : "Add Slider"}
            // btnTitle={props.editData ? "Add Notification" : "Notification List"}
            onclick={() => {
              props.editData
                ? dispatch(userEdit(null))
                : props.history.push("/brand-list")
            }}
          >
            <Form
              onSubmit={validation.handleSubmit}
              // onSubmit={ props.editData ? editUser  : submitAddUser}
            >
              <div className="mb-3">
                <Label className="form-label">Select File (1329.2 * 480)</Label>{" "}
                <span style={{ color: "red" }}>*</span>
                <Input
                  name="file"
                  id="file"
                  className="form-control"
                  // placeholder="Enter your new password"
                  type="file"
                  // onChange={(e) => setFiles(e.target.files[0])}
                  onChange={e => {
                    validation.handleChange(e)
                    setFiles(e.target.files[0])
                    if (editBanner !== null) {
                      if (editBanner.brandId !== null) {
                        setSelectBrand(editBanner.brandId._id)
                      } else if (editBanner.categoryId !== null) {
                        setSelectCategory(editBanner.categoryId._id)
                      } else if (editBanner.itemId !== null) {
                        setSelectItem(editBanner.itemId._id)
                      }
                    }
                  }}
                  onBlur={validation.handleBlur}
                  //   ref={fileRef}
                  value={validation.file || ""}
                  invalid={
                    (validation.touched.file &&
                      files !== null &&
                      files.size > Number(process.env.REACT_APP_FILE_SIZE)) ||
                    validation.errors.file
                      ? true
                      : false
                  }
                />
                {validation.touched.file &&
                files !== null &&
                files.size > Number(process.env.REACT_APP_FILE_SIZE) ? (
                  <FormFeedback type="invalid">
                    File must be less than 2 MB
                  </FormFeedback>
                ) : null}
                {validation.touched.file && validation.errors.file ? (
                  <FormFeedback type="invalid">
                    {validation.errors.file}
                  </FormFeedback>
                ) : null}
                {files !== null && (
                  <Card
                    className="mt-2 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    style={{
                      backgroundColor:
                        files.size > Number(process.env.REACT_APP_FILE_SIZE)
                          ? "#ff6a6a"
                          : "#2FDD92",
                      color:
                        files.size > Number(process.env.REACT_APP_FILE_SIZE)
                          ? "#ffffff"
                          : "#131313",
                      width: "97%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            style={{
                              objectFit: "cover",
                            }}
                            alt={files.name}
                            src={URL.createObjectURL(files)}
                          />
                        </Col>
                        <Col>
                          <Link
                            to="#"
                            // className="text-muted font-weight-bold"
                            style={{
                              color:
                                files.size >
                                Number(process.env.REACT_APP_FILE_SIZE)
                                  ? "#ffffff"
                                  : "#131313",
                            }}
                          >
                            {files.name}
                          </Link>
                          <p className="mb-0">
                            <strong>
                              {(files.size / 1048576).toFixed(2) + " MB"}
                            </strong>
                          </p>
                          <i
                            className="bx bx-no-entry"
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 10,
                              color:
                                files.size >
                                Number(process.env.REACT_APP_FILE_SIZE)
                                  ? "#ffffff"
                                  : "#131313",
                              fontSize: 17,
                              fontWeight: 800,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setFiles(null)
                              document.getElementById("file").value = ""
                            }}
                          ></i>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )}
                {editBanner != null && showEditImage && files == null && (
                  <Card
                    className="mt-2 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    style={{
                      backgroundColor:
                        editBanner.file.fileSize >
                        Number(process.env.REACT_APP_FILE_SIZE)
                          ? "#ff6a6a"
                          : "#2FDD92",
                      color:
                        editBanner.file.fileSize >
                        Number(process.env.REACT_APP_FILE_SIZE)
                          ? "#ffffff"
                          : "#131313",
                      width: "97%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            style={{
                              objectFit: "cover",
                            }}
                            alt={editBanner.file.originalname}
                            src={`${ImageURL}/banner_images/${editBanner.file.filename}`}
                          />
                        </Col>
                        <Col>
                          <Link
                            to="#"
                            // className="text-muted font-weight-bold"
                            style={{
                              color:
                                editBanner.file.fileSize >
                                Number(process.env.REACT_APP_FILE_SIZE)
                                  ? "#ffffff"
                                  : "#131313",
                            }}
                          >
                            {editBanner.file.originalname}
                          </Link>
                          <p className="mb-0">
                            <strong>
                              {(editBanner.file.fileSize / 1048576).toFixed(2) +
                                " MB"}
                            </strong>
                          </p>
                          <i
                            className="bx bx-no-entry"
                            style={{
                              position: "absolute",
                              top: 0,
                              right: 10,
                              color:
                                editBanner.file.fileSize >
                                Number(process.env.REACT_APP_FILE_SIZE)
                                  ? "#ffffff"
                                  : "#131313",
                              fontSize: 17,
                              fontWeight: 800,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setShowEditImage(false)
                            }}
                          ></i>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )}
              </div>
              <div className="mb-3">
                <Selector
                  label="Brand"
                  required={true}
                  id="brand"
                  //   isDisable={selectBrand !== null ? true : false}
                  refValue={brandValue}
                  // valueNull=""
                  data={brandList !== null ? brandList : []}
                  onchange={event => {
                    setSelectBrand(event.value)
                  }}
                  edit={
                    editBanner &&
                    editBanner.type == "brand" && {
                      label: editBanner.brandId.name,
                      value: editBanner.brandId._id,
                    }
                  }
                />
              </div>
              <div className="mb-3">
                <Selector
                  label="Category"
                  required={true}
                  id="category"
                  refValue={categoryValue}
                  data={categoryList !== null ? categoryList : []}
                  onchange={event => {
                    setSelectCategory(event.value)
                  }}
                  edit={
                    editBanner &&
                    editBanner.type == "category" && {
                      label: editBanner.categoryId.name,
                      value: editBanner.categoryId._id,
                    }
                  }
                />
              </div>
              <div className="mb-3">
                <Selector
                  label="Item"
                  required={true}
                  id="item"
                  refValue={itemValue}
                  data={itemList !== null ? itemList : []}
                  onchange={event => {
                    setSelectItem(event.value)
                  }}
                  edit={
                    editBanner &&
                    editBanner.type == "item" && {
                      label: editBanner.itemId.name,
                      value: editBanner.itemId._id,
                    }
                  }
                />
              </div>

              <Row className="mb-3">
                <Col className="text-start">
                  <button
                    className="btn btn-primary w-md "
                    type="submit"
                    // onClick={() => handleSubmit()}
                  >
                    Submit
                  </button>
                </Col>
              </Row>
            </Form>
          </LayoutModal>
        </div>
        <div className="col-lg-8">
          <LayoutModal>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="_id"
              columns={columns}
              data={bannerData}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="_id"
                  columns={columns}
                  data={bannerData}
                  // search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col md="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              keyField={"_id"}
                              responsive
                              bordered={false}
                              striped={true}
                              // defaultSorted={defaultSorted}
                              hover
                              // selectRow={selectRow}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </LayoutModal>
        </div>
      </div>
    </>
  )
}
AddSlider.propTypes = {
  userData: PropTypes.object,
  editData: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(AddSlider))
