import React, { useEffect } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "../../pages/imports/import"
import Breadcrumbs from "../Common/Breadcrumb"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { changeSidebarType } from "store/actions"

function LayoutModel(props) {
  // const dispatch = useDispatch()

  // useEffect(() => {
  //   dispatch(changeSidebarType("default"))
  // },[])

  return (
    <React.Fragment>
      <div style={{ flex: 1, minHeight: "100vh" }}>
        <div className="page-content">
          <Container
            fluid
            className={
              props.form
                ? "col-xs-12 col-sm-12 col-md-10 col-lg-8 float-start"
                : "col-12"
            }
          >
            {/* Render Breadcrumb */}
            <Breadcrumbs
              onClick={props.onclick}
              showBtn={props.form}
              buttonTitle={props.btnTitle}
              title={props.sideTitle}
              breadcrumbItem={props.title}
              button={props.button}
              editOrder={props.editOrder && props.editOrder}
              onClickOfEdit={props.onClickOfEdit && props.onClickOfEdit}
            />
            <Row>
              <Col>
                <Card>
                  <CardBody>
                    <Row>{props.children}</Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

LayoutModel.propTypes = {
  sideTitle: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
  form: PropTypes.bool,
  btnTitle: PropTypes.string,
  link: PropTypes.string,
  onclick: PropTypes.any,
  button: PropTypes.bool,
  editOrder: PropTypes.any,
  onClickOfEdit: PropTypes.any,
}

export default LayoutModel
