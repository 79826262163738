import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect, useRef, useCallback } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  CardTitle,
  Button,
  Modal,
} from "reactstrap"

//redux
import { useSelector, useDispatch, connect } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.png"
import imageCompression from "browser-image-compression"

import axios from "axios"
import LayoutModal from "../../components/Custom/LayoutModel"
import Dropzone from "react-dropzone"

import Cropper from "react-easy-crop"
import getCroppedImg from "./getCroppedImg"
import Loader from "components/Custom/Loader"
import Selector from "components/Custom/Selector"
import { BaseURL, ImageURL } from "config/BaseUrl"
import Tostify from "components/Custom/Tostify"
import { toast } from "react-toastify"
import { userEdit } from "../../store/actions"

const AddItem = props => {
  const dispatch = useDispatch()
  const [modal_xlarge, setmodal_xlarge] = useState(false)

  const [selectedFiles, setselectedFiles] = useState(null)
  const imgRef = useRef(null)
  const categorySelect = useRef(null)
  const [imgSrc, setImgSrc] = useState({ src: "", index: "" })
  const [compressedAllFiles, setCompressAllFiles] = useState(false)
  const [preventParent, setPreventParent] = useState(false)

  const [bannerImageFile, setBannerImageFile] = useState()
  const [compressLoader, setCompressLoader] = useState(false)
  const [poPular, setPopular] = useState(false)

  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  // const dogImg ='https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000'

  const [categoryList, setCategoryList] = useState([])

  const [brandList, setBrandList] = useState([])
  const [selectBrand, setSelectBrand] = useState(
    props.editData && props.editData.banner ? props.editData.brand._id : null
  )
  const [selectCategory, setSelectCategory] = useState(
    props.editData && props.editData.banner ? props.editData.category._id : null
  )
  const [loading, setLoading] = useState(false)
  const [checkedNot, setCheckedNot] = useState(
    props.editData && props.editData.banner
      ? props.editData.sendNotification
      : true
  )

  const [outOfStockk, setOutOfStock] = useState(false)
  const [showEditImage, setShowEditImage] = useState(true)

  const [filesImage, setFilesImage] = useState(
    props.editData && props.editData.banner ? props.editData.banner : null
  )

  const [isBannerImage, setIsBannerImage] = useState(false)

  const [resetBanner, setResetBanner] = useState(false)

  const [resetCateSelect, SetResetCateSelect] = useState(true)

  const [unitList, setUnitList] = useState([])
  const [selectedUnit, setSelectedUnit] = useState(
    props.editData && props.editData.unit._id ? props.editData.unit._id : null
  )

  useEffect(() => {
    // if(!resetCateSelect){
    //   setLoader(true)
    //   SetResetCateSelect(true)
    //   setTimeout(() =>{setLoader(false)},200)
    // }

    var config = {
      method: "get",
      url: `${BaseURL}/categories/1`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        let data = []
        response.data.categories.map((d, i) => {
          // console.log(d,selectBrand,"___________________________________")
          if (d.brand._id === selectBrand) {
            // console.log("......................")
            data.push(d)
          }
        })
        setCategoryList(data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [selectBrand])

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseURL}/brands/1`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))

        setBrandList(response.data.brands)
      })
      .catch(function (error) {
        console.log(error)
      })

    var config = {
      method: "get",
      url: `${BaseURL}/units`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data, "UNITS"))
        setUnitList(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (props.editData) {
      const data = props.editData
      console.log(data.isPopular, "POPPPPPPPPPPP")

      if (data.outOfStock) {
        document.getElementById("out").checked = data.outOfStock
      }
      if (data.isPopular) {
        document.getElementById("out1").checked = data.isPopular
      }

      setPopular(data.isPopular)

      // console.log(props.editData , "USER DATATATATATATATAT")
      // // setselectedFiles(data.pics)
      // if(props.editData.pin !== null){
      //   setSelectedServices(data.services)
      //   setSelectedDirected(data.directoryTypes)
      //   setCity(props.editData.pin.city.name)
      //   setState(props.editData.pin.state.name)
      // }else{
      //   setCity(" ")
      //   setState(" ")
      // }
      // setCity(data.city)
      // setState(data.state)

      // const rows = [];

      // data.contacts.map((d,i) => {

      //   Object.assign(d, {id : i + 1})
      //   rows.push(d)

      // })

      // setFormRows(rows)

      // if(rows.length > 0){
      //   data.contacts.map((d,i) => {
      //     console.log(d,"DATA OF CONTACTS")
      //     setTimeout(() => {
      //       document.getElementById("name" + i).value = d.name;
      //       document.getElementById("email"+i).value = d.email;
      //       document.getElementById("phone"+i).value = d.mobile;
      //       document.getElementById("designation"+i).value = d.designation;
      //     },100)

      //   })
      // }

      const files = []
      data.pics &&
        data.pics.map((d, i) => {
          Object.assign(d, {
            preview: ImageURL + "/items/" + d.filename,
            formattedSize: formatBytes(d.fileSize),
            compressed: true,
            name: d.originalname,
            _id: d._id,
          })
          files.push(d)
          console.log(d, "PICSDATA")
        })
      setselectedFiles([...files])
      // setEditSelectedFiles( files.length )
    } else {
      document.getElementById("not").checked = checkedNot
    }

    return () => dispatch(userEdit(null))
  }, [])

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
    console.log(croppedAreaPixels, croppedArea, "Hello")
  }, [])

  useEffect(() => {
    console.log(selectedFiles, "OPEN MODEL")
    if (modal_xlarge) {
      setCompressAllFiles(false)
    }
  }, [modal_xlarge])

  const showCroppedImage = useCallback(async () => {
    setCompressLoader(true)

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    let filterBackendImages = []
    let filterUploadedImages = []

    selectedFiles !== null &&
      selectedFiles.map((d, i) => {
        if (!d._id) {
          filterUploadedImages.push(d)
        } else {
          filterBackendImages.push(d)
        }
      })

    let fileCompressed =
      selectedFiles !== null && !isBannerImage ? filterUploadedImages : []
    try {
      const croppedImage = await getCroppedImg(
        imgSrc.src,
        croppedAreaPixels,
        rotation
      )
      console.log("donee", { croppedImage })

      const compressFile = async file => {
        try {
          // const compressedFile = await imageCompression(file, options)

          const data = await imageCompression(file, options).then(f => {
            Object.assign(f, {
              preview: URL.createObjectURL(f),
              originalPreview: URL.createObjectURL(file),
              formattedSize: formatBytes(f.size),
              originalSize: formatBytes(
                isBannerImage
                  ? filesImage.size
                  : selectedFiles[imgSrc.index].size
              ),
              name: isBannerImage
                ? filesImage.name
                : selectedFiles[imgSrc.index].name,
              compressed: true,
              bannerImage: false,
            })
            console.log(f, "dDD")
            console.log(imgSrc.index, "INDEX")
            fileCompressed.splice(imgSrc.index, 1, f)

            // fileCompressed.push(f)
            return f
          })
          console.log(data, fileCompressed, "COMPRESSED FILES")
          // setselectedFiles([...fileCompressed ])
          fileCompressed.length - 1 > Number(imgSrc.index)
            ? setImgSrc({
                src: fileCompressed[Number(imgSrc.index) + 1].preview,
                index: Number(imgSrc.index) + 1,
              })
            : setmodal_xlarge(!modal_xlarge)
          setCompressLoader(false)
          let result = true
          // let bannerImageResult = false

          fileCompressed.map((d, i) => {
            if (i < fileCompressed.length - 1) {
              console.log(d.compressed, "Bool")
              result = d.compressed && fileCompressed[i + 1].compressed
              // bannerImageResult = d.bannerImage || fileCompressed[i+1].bannerImage
            }
          })
          setCompressAllFiles(result)
          // console.log(result,bannerImageResult,"Result")

          if (!isBannerImage) {
            // fileCompressed[0].bannerImage = true;
            // setBannerImageFile(fileCompressed[0])
            setselectedFiles([...fileCompressed, ...filterBackendImages])
          } else {
            console.log(fileCompressed[0], selectedFiles, "ImHere")
            setFilesImage(fileCompressed[0])
          }

          // console.log(result,bannerImageResult,fileCompressed,"Result")
        } catch (e) {
          console.log(e, filesImage, "Compress")
        }
      }
      compressFile(croppedImage)

      //   setCroppedImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation, imgSrc])

  function tog_xlarge() {
    if (compressedAllFiles) {
      setmodal_xlarge(!modal_xlarge)
    }

    // removeBodyCss();
  }
  //   console.log(props.userData,"DATATA")

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      itemName: props.editData ? props.editData.name : "",
      // brand: '',
      // category: '',
      mrp: props.editData ? props.editData.mrp : "",
      price: props.editData ? props.editData.price : "",
      descr: props.editData ? props.editData.descriptionDisplay : "",
      descrOnGrp: props.editData ? props.editData.descriptionPopup : "",
      disc: props.editData ? props.editData.discount : "",
      // image: '',
    },
    validationSchema: Yup.object({
      itemName: Yup.string().required("Please Enter item name"),
      // brand: Yup.string().required("Please Enter Brand"),
      // category: Yup.string().required("Please select a option"),
      mrp: Yup.string().required("Please Enter MRP"),
      price: Yup.string().required("Please Enter Price"),
      descr: Yup.string().required("Please Enter Description"),
      descrOnGrp: Yup.string().required("Please Enter Description Group"),
      disc: Yup.string(),
      // image: Yup.mixed().required("Please select images"),
    }),
    onSubmit: values => {
      // console.log(selectedFiles,"____________________________________________________________________________")
      // filesImage

      if (selectedUnit === null) {
        return toast.error("Please select a Unit, Unit is Required", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }

      if (selectBrand === null) {
        return toast.error("Please select a Brand, Brand is Required", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }

      if (selectCategory === null) {
        return toast.error("Please select a Category, Category is Required", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }

      if (filesImage === null && !props.editData) {
        return toast.error(
          "Please Upload Banner Images, Banner Images is Required",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        )
      }

      var data = new FormData()
      data.append("name", values.itemName)
      data.append("brand", selectBrand)
      data.append("category", selectCategory)
      data.append("unit", selectedUnit)
      data.append("mrp", values.mrp)
      data.append("price", values.price)
      data.append("descriptionDisplay", values.descr)
      data.append("descriptionPopup", values.descrOnGrp)
      data.append("discount", values.disc)

      selectedFiles !== null &&
        selectedFiles.map((d, i) => {
          console.log(
            d,
            "----------------------------------------------------------------------------IMG"
          )
          if (d._id) {
            console.log("FOUND IMAGE FROM EDIT")
            return
          }
          let file = new File([d], d.name)
          data.append("pics", file)
        })
      // console.log(props.editData.banner.originalname , typeof(filesImage.name),typeof(filesImage.name) !== "undefined" ,"<<<<>>>><><>>>>><<<<")
      let bannerr = new File([filesImage], filesImage.name)
      typeof filesImage.name !== "undefined" && data.append("banner", bannerr)
      // data.append('pics', fs.createReadStream('/Users/wiztechlabs/Downloads/logo-light.png'));
      // data.append('pics', fs.createReadStream('/Users/wiztechlabs/Downloads/logo.png'));
      // data.append('banner', fs.createReadStream('/Users/wiztechlabs/Downloads/logo (1).png'));
      data.append("sendNotification", checkedNot ? 1 : 0)
      data.append("outOfStock", outOfStockk ? 1 : 0)
      data.append("isPopular", poPular ? 1 : 0)

      setLoading(true)

      var config = {
        method: props.editData ? "put" : "post",
        url: `${BaseURL}/items${
          props.editData ? "/update/" + props.editData._id : ""
        }`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": props.userData.token,
        },
        data: data,
      }

      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data))
          setLoading(false)
          setTimeout(() => {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
            setTimeout(() => {
              props.history.push("/item-list")
            }, 2000)
          }, 200)
        })
        .catch(function (error) {
          console.log(error)
          setLoading(false)
          toast.error(error.response && error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
    },
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  // const [crop, setCrop] = useState({
  //   unit: '%',
  //   width: 50,
  //   height: 50,
  //   x: 25,
  //   y: 25
  // })

  function handleAcceptedFiles(files, isBanner) {
    console.log(isBanner, "BBBBBBBBBBBBBB")

    const options = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }

    const filesLength = Number(files.length)
    const prevFilesLength = Number(
      selectedFiles !== null ? selectedFiles.length : 0
    )

    console.log(filesLength, prevFilesLength, "LENGTH")

    if (filesLength + prevFilesLength > 3) {
      toast.error("Upload atmost 3 images", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
      setmodal_xlarge(false)
      return
    }

    let fileCompressed = []

    if (isBanner) {
      files.map(file => {
        console.log(file, "====++====")

        Object.assign(file, {
          preview: URL.createObjectURL(file),
          originalPreview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
          originalSize: formatBytes(file.size),
          compressed: false,
          bannerImage: false,
        })
      })

      console.log(files, "====++====")
      // fileCompressed=files
      setFilesImage(files)
      setImgSrc({ src: files[0].preview, index: 0 })
    } else {
      if (selectedFiles !== null && selectedFiles.length > 0) {
        files.map(file => {
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            originalPreview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
            originalSize: formatBytes(file.size),
            compressed: false,
            bannerImage: false,
          })
          console.log(fileCompressed, "FILE")
          selectedFiles.unshift(file)
          fileCompressed = selectedFiles
          setselectedFiles([...selectedFiles])

          setImgSrc({ src: selectedFiles[0].preview, index: 0 })
        })
      } else {
        files.map(file => {
          console.log(file, "FILrrrrrrE")

          Object.assign(file, {
            preview: URL.createObjectURL(file),
            originalPreview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
            originalSize: formatBytes(file.size),
            compressed: false,
            bannerImage: false,
          })
        })

        console.log(fileCompressed, isBannerImage, "NOTBANNER")
        // fileCompressed=files
        setselectedFiles(files)
        setImgSrc({ src: files[0].preview, index: 0 })
      }
    }
  }

  //  useEffect(() => {console.log(selectedFiles.length)},[selectedFiles.length])

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  if (loading) {
    return <Loader />
  }

  return (
    <>
      <Tostify />
      <LayoutModal
        button={true}
        form={true}
        title={props.editData ? "Edit Item" : "Add Item"}
        btnTitle={props.editData ? "Add Item" : "Item List"}
        onclick={() => {
          if (props.editData) {
            dispatch(userEdit(null))
            window.location.reload()
          } else {
            props.history.push("/item-list")
          }
        }}
      >
        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="mb-3">
            <Label className="form-label">
              Item Name <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="itemName"
              id="itemName"
              className="form-control"
              placeholder="Enter your Item Name"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.itemName || ""}
              invalid={
                validation.touched.itemName && validation.errors.itemName
                  ? true
                  : false
              }
            />
            {validation.touched.itemName && validation.errors.itemName ? (
              <FormFeedback type="invalid">
                {validation.errors.itemName}
              </FormFeedback>
            ) : null}
          </div>

          {/* <div className="mb-3"> */}

          <Row className="mb-3">
            <Col md={6}>
              <Selector
                label="Brand"
                required={true}
                id="select"
                data={brandList !== null ? brandList : []}
                onchange={event => setSelectBrand(event.value)}
                edit={
                  props.editData &&
                  props.editData.brand && {
                    label: props.editData.brand.name,
                    value: props.editData.brand._id,
                  }
                }
              />

              {/* <Label className="form-label">Brand <span style={{color:"red"}}>*</span></Label>
                      
                        <Input
                          name="brand"
                          id="brand"
                          className="form-control"
                          placeholder="Enter your Brand Name"
                          type="text"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.brand || ""}
                          invalid={
                            validation.touched.brand && validation.errors.brand ? true : false
                          }
                        />
                   
                        {validation.touched.brand && validation.errors.brand ? (
                          <FormFeedback type="invalid">{validation.errors.brand}</FormFeedback>
                        ) : null} */}
            </Col>
            <Col md={6}>
              <Selector
                label="Category"
                required={true}
                id="select"
                ref={categorySelect}
                data={categoryList !== null ? categoryList : []}
                onchange={event => setSelectCategory(event.value)}
                edit={
                  props.editData &&
                  props.editData.category && {
                    label: props.editData.category.name,
                    value: props.editData.category._id,
                  }
                }
              />

              {/* <Label className="form-label">Category <span style={{color:"red"}}>*</span></Label>
                        <Input
                          name="category"
                          id="category"
                          className="form-control"
                        //   placeholder="Confirm your password"
                          type="select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.category || ""}
                          invalid={
                            validation.touched.category && validation.errors.category ? true : false
                          }
                        >
                        <option value="">Select </option>
                        <option value="s">Hello</option>
                        <option value="d">Hello</option>
                        <option value="d">Hello</option>
                        </Input>
                        {validation.touched.category && validation.errors.category ? (
                          <FormFeedback type="invalid">{validation.errors.category}</FormFeedback>
                        ) : null} */}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Selector
                label="Units"
                required={true}
                id="select"
                data={unitList !== null ? unitList : []}
                onchange={event => setSelectedUnit(event.value)}
                edit={
                  props.editData &&
                  props.editData.unit && {
                    label: props.editData.unit.name,
                    value: props.editData.unit._id,
                  }
                }
              />
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Label className="form-label">
                MRP <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="mrp"
                id="mrp"
                className="form-control"
                placeholder="Enter your MRP"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.mrp || ""}
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                invalid={
                  validation.touched.mrp && validation.errors.mrp ? true : false
                }
              />
              {validation.touched.mrp && validation.errors.mrp ? (
                <FormFeedback type="invalid">
                  {validation.errors.mrp}
                </FormFeedback>
              ) : null}
            </Col>
            <Col md={6}>
              <Label className="form-label">
                Price <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                name="price"
                id="price"
                className="form-control"
                placeholder="Enter your Price"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.price || ""}
                onKeyPress={event => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                invalid={
                  validation.touched.price && validation.errors.price
                    ? true
                    : false
                }
              />
              {validation.touched.price && validation.errors.price ? (
                <FormFeedback type="invalid">
                  {validation.errors.price}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Label className="form-label">Discount (%)</Label>
              <Input
                name="disc"
                id="disc"
                className="form-control"
                placeholder="Enter Discount here"
                type="text"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={
                  validation.values.disc != "0"
                    ? validation.values.disc
                    : "" || ""
                }
                onKeyPress={event => {
                  if (!/[0-9 & .]/.test(event.key)) {
                    event.preventDefault()
                  }
                }}
                invalid={
                  validation.touched.disc && validation.errors.disc
                    ? true
                    : false
                }
              />
              {validation.touched.disc && validation.errors.disc ? (
                <FormFeedback type="invalid">
                  {validation.errors.disc}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>

          <div className="mb-3">
            <Label className="form-label">
              Description on Display <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="descr"
              id="descr"
              className="form-control"
              placeholder="Enter your Description"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.descr || ""}
              invalid={
                validation.touched.descr && validation.errors.descr
                  ? true
                  : false
              }
            />
            {validation.touched.descr && validation.errors.descr ? (
              <FormFeedback type="invalid">
                {validation.errors.descr}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">
              Description on Popup <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="descrOnGrp"
              id="descrOnGrp"
              className="form-control"
              placeholder="Enter your Description"
              type="textarea"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.descrOnGrp || ""}
              invalid={
                validation.touched.descrOnGrp && validation.errors.descrOnGrp
                  ? true
                  : false
              }
            />
            {validation.touched.descrOnGrp && validation.errors.descrOnGrp ? (
              <FormFeedback type="invalid">
                {validation.errors.descrOnGrp}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">
              Banner Image (1080 X 1080) <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="file"
              id="file"
              accept="image/png, image/jpeg"
              className="form-control"
              // placeholder="Enter your new password"
              type="file"
              // onChange={(e) => setFilesImage(e.target.files[0])}
              onChange={e => {
                // console.log(e.target.files[0],"File")
                setResetBanner(true)
                let isBanner = true
                setIsBannerImage(true)
                setmodal_xlarge(true)
                handleAcceptedFiles([e.target.files[0]], isBanner)
                validation.handleChange(e)
                setFilesImage(e.target.files[0])
              }}
              onBlur={validation.handleBlur}
              // ref={fileRef}
              value={validation.file || ""}
              invalid={
                (validation.touched.file &&
                  filesImage !== null &&
                  filesImage.size > Number(process.env.REACT_APP_FILE_SIZE)) ||
                validation.errors.file
                  ? true
                  : false
              }
            />

            {validation.touched.file &&
            filesImage !== null &&
            filesImage.size > Number(process.env.REACT_APP_FILE_SIZE) ? (
              <FormFeedback type="invalid">
                File must be less than 2 MB
              </FormFeedback>
            ) : null}

            {validation.touched.file && validation.errors.file ? (
              <FormFeedback type="invalid">
                {validation.errors.file}
              </FormFeedback>
            ) : null}
          </div>

          {filesImage !== null && (
            <Card
              className="mt-1 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              style={{
                backgroundColor:
                  !resetBanner && props.editData
                    ? filesImage.fileSize >
                      Number(process.env.REACT_APP_FILE_SIZE)
                      ? "#ff6a6a"
                      : "#b9e0ff"
                    : filesImage.size > Number(process.env.REACT_APP_FILE_SIZE)
                    ? "#ff6a6a"
                    : "#b9e0ff",
                color:
                  !resetBanner && props.editData
                    ? filesImage.fileSize >
                      Number(process.env.REACT_APP_FILE_SIZE)
                      ? "#ffffff"
                      : "#131313"
                    : filesImage.size > Number(process.env.REACT_APP_FILE_SIZE)
                    ? "#ffffff"
                    : "#131313",
                width: "97%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      style={{
                        objectFit: "cover",
                      }}
                      alt={
                        !resetBanner && props.editData
                          ? filesImage.originalname
                          : filesImage.name
                      }
                      src={
                        !resetBanner && props.editData
                          ? ImageURL + "/items/" + filesImage.filename
                          : filesImage.preview
                      }
                    />
                  </Col>
                  <Col>
                    <Link
                      to="#"
                      // className="text-muted font-weight-bold"
                      style={{
                        color:
                          !resetBanner && props.editData
                            ? filesImage.fileSize >
                              Number(process.env.REACT_APP_FILE_SIZE)
                              ? "#ffffff"
                              : "#131313"
                            : filesImage.size >
                              Number(process.env.REACT_APP_FILE_SIZE)
                            ? "#ffffff"
                            : "#131313",
                      }}
                    >
                      {!resetBanner && props.editData
                        ? filesImage.originalname
                        : filesImage.name}
                    </Link>
                    <p className="mb-0">
                      <strong>
                        {(
                          (!resetBanner && props.editData
                            ? filesImage.fileSize
                            : filesImage.size) / 1048576
                        ).toFixed(2) + " MB"}
                      </strong>
                    </p>
                    <i
                      className="bx bx-no-entry"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 10,
                        color:
                          !resetBanner && props.editData
                            ? filesImage.fileSize >
                              Number(process.env.REACT_APP_FILE_SIZE)
                              ? "#ffffff"
                              : "#131313"
                            : filesImage.size >
                              Number(process.env.REACT_APP_FILE_SIZE)
                            ? "#ffffff"
                            : "#131313",
                        fontSize: 17,
                        fontWeight: 800,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setFilesImage(null)
                        document.getElementById("file").value = ""
                      }}
                    ></i>
                  </Col>
                </Row>
              </div>
            </Card>
          )}

          <div className="mb-3">
            <Dropzone
              accept="image/png, image/jpeg"
              onDrop={acceptedFiles => {
                // const type = new RegExp('image/*');
                // let imageAccepted = true;
                // console.log(acceptedFiles.length , "Length")
                // if(acceptedFiles.length > 1) {
                //   console.log(acceptedFiles , "Length")
                //   acceptedFiles.map((d,i) =>{
                //     if(i < acceptedFiles.length ){
                //      let result = type.test(d.type)

                //      if(!result){
                //       acceptedFiles.splice(i,1)
                //       return
                //      }
                //      console.log( result ,"test")
                //    if(acceptedFiles[i+1] !== undefined){
                //     console.log( result ,"testttttttdddd")
                //     imageAccepted = result && type.test(acceptedFiles[i+1].type)
                //    }

                //     }
                //   })
                // }else{
                //   let result = type.test(acceptedFiles[0].type)
                //   if(!result){
                //     acceptedFiles.splice(0,1)
                //    }

                //   console.log( result ,"testtttttt")
                //   imageAccepted = result;
                // }

                // console.log(acceptedFiles)
                // console.log(imageAccepted,"ACCEPTED")

                if (acceptedFiles.length < 4) {
                  console.log("HHH")
                  let isBanner = false
                  setIsBannerImage(false)
                  setmodal_xlarge(true)
                  handleAcceptedFiles(acceptedFiles, isBanner)
                } else {
                  toast.error("Select atmost 3 Images", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  })
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <div className="dropzone">
                  <div
                    className="dz-message needsclick mt-2"
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <div className="mb-3">
                      <i className="display-4 text-muted bx bxs-cloud-upload" />
                    </div>
                    <h4>Drop files here or click to upload. (1440 X 810)</h4>
                  </div>
                </div>
              )}
            </Dropzone>
            <div className="dropzone-previews mt-3" id="file-previews">
              {selectedFiles !== null &&
                selectedFiles.map((f, i) => {
                  console.log(f, "PREVIEW")
                  return (
                    f.compressed && (
                      <Card
                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        key={i + "-file"}
                      >
                        <div
                          className="p-2"
                          // style={{ backgroundColor: f.bannerImage && "#b9e0ff",cursor:"pointer"}}
                          // onClick={(event) => {

                          //   selectedFiles.map((d,index) => {
                          //   if(i == index){
                          //     d.bannerImage = true
                          //     setBannerImageFile(d)
                          //   }else{
                          //     d.bannerImage = false
                          //   }
                          //   })

                          // }}
                        >
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                className="avatar-sm rounded bg-light"
                                style={{
                                  objectFit: "contain",
                                  height: 70,
                                  width: 130,
                                }}
                                alt={f.name}
                                src={f.preview}
                              />
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                className="text-muted font-weight-bold"
                              >
                                {f.name}
                              </Link>
                              <p className="mb-0">
                                <strong>{f.formattedSize}</strong>
                                {/* <strong style={{marginLeft:10, color:"#FF5959", textDecorationLine: 'line-through'}}>{f.originalSize}</strong> */}
                              </p>
                              <i
                                className="bx bx-no-entry"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 10,
                                  fontSize: 17,
                                  fontWeight: 800,
                                  cursor: "pointer",
                                }}
                                onClick={e => {
                                  e.stopPropagation()
                                  let data = selectedFiles.splice(i, 1)

                                  if (
                                    selectedFiles.length > 0 &&
                                    i == 0 &&
                                    data[0].bannerImage == true
                                  ) {
                                    console.log("INSIDE", selectedFiles[0])
                                    selectedFiles[0].bannerImage = true
                                    setBannerImageFile(selectedFiles[0])
                                  }
                                  if (
                                    selectedFiles.length > 0 &&
                                    i > 0 &&
                                    data[0].bannerImage == true
                                  ) {
                                    console.log("INSIDE", selectedFiles[i])
                                    selectedFiles[i - 1].bannerImage = true
                                    setBannerImageFile(selectedFiles[i - 1])
                                  }
                                  console.log("OUTSIDE", data)

                                  setselectedFiles([...selectedFiles])

                                  if (props.editData && f._id) {
                                    console.log(props.editData, "IDDDDDD")
                                    var dataa = JSON.stringify({
                                      itemId:
                                        props.editData && props.editData._id,
                                      picId: f._id,
                                    })

                                    var config = {
                                      method: "put",
                                      url: `${BaseURL}/items/removeImage`,
                                      headers: {
                                        "Content-Type": "application/json",
                                        "x-auth-token": props.userData.token,
                                      },
                                      data: dataa,
                                    }

                                    axios(config)
                                      .then(function (response) {
                                        console.log("RESPONSE", response.data)
                                        toast.success(response.data.message, {
                                          position: "top-right",
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                        })
                                      })
                                      .catch(function (error) {
                                        console.log(error)
                                        toast.error(
                                          error.response &&
                                            error.response.data.message,
                                          {
                                            position: "top-right",
                                            autoClose: 5000,
                                            hideProgressBar: false,
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                          }
                                        )
                                      })
                                  }
                                }}
                              ></i>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )
                  )
                })}
            </div>
          </div>

          <Row>
            <Col md={2}>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // backgroundColor:"#131313",
                  // marginTop:30,
                  marginBottom: 10,
                  marginLeft: 5,
                  // width: "100%",
                }}
              >
                <Input
                  name="out"
                  id="out"
                  className="form-control"
                  // placeholder="Enter your Price"
                  type="checkbox"
                  onChange={e => setOutOfStock(!outOfStockk)}
                  onBlur={validation.handleBlur}
                  value={outOfStockk}
                  // checked
                  // value={validation.values.not || ""}
                  // invalid={
                  //   validation.touched.not && validation.errors.not ? true : false
                  // }
                />
                <Label
                  className="form-label"
                  htmlFor="out"
                  style={{ marginLeft: 20, marginTop: -18 }}
                >
                  Out Of Stock
                </Label>
                {/* {validation.touched.not && validation.errors.not ? (
                          <FormFeedback type="invalid">{validation.errors.not}</FormFeedback>
                        ) : null} */}
              </Row>
            </Col>
            <Col md={2}>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // backgroundColor:"#131313",
                  // marginTop:30,
                  marginBottom: 10,
                  marginLeft: 5,
                  // width: "100%",
                }}
              >
                <Input
                  name="out1"
                  id="out1"
                  className="form-control"
                  // placeholder="Enter your Price"
                  type="checkbox"
                  onChange={e => setPopular(!poPular)}
                  onBlur={validation.handleBlur}
                  value={poPular}
                  // checked
                  // value={validation.values.not || ""}
                  // invalid={
                  //   validation.touched.not && validation.errors.not ? true : false
                  // }
                />
                <Label
                  className="form-label"
                  htmlFor="out1"
                  style={{ marginLeft: 20, marginTop: -18 }}
                >
                  Popular
                </Label>
                {/* {validation.touched.not && validation.errors.not ? (
                          <FormFeedback type="invalid">{validation.errors.not}</FormFeedback>
                        ) : null} */}
              </Row>
            </Col>
            {!props.editData && (
              <Col md={4}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    // backgroundColor:"#131313",
                    // marginTop:30,
                    marginBottom: 10,
                    marginLeft: 5,
                    // width: "100%",
                  }}
                >
                  <Input
                    name="not"
                    id="not"
                    className="form-control"
                    // placeholder="Enter your Price"
                    type="checkbox"
                    onChange={e => setCheckedNot(!checkedNot)}
                    onBlur={validation.handleBlur}
                    value={checkedNot}
                    // checked
                    // value={validation.values.not || ""}
                    // invalid={
                    //   validation.touched.not && validation.errors.not ? true : false
                    // }
                  />
                  <Label
                    className="form-label"
                    htmlFor="not"
                    style={{ marginLeft: 20, marginTop: -18 }}
                  >
                    Send New Notification
                  </Label>
                  {/* {validation.touched.not && validation.errors.not ? (
                          <FormFeedback type="invalid">{validation.errors.not}</FormFeedback>
                        ) : null} */}
                </Row>
              </Col>
            )}
          </Row>

          <Row className="mb-3">
            <Col className="text-start">
              <button
                className="btn btn-primary w-md "
                type="submit"
                style={{ marginBottom: 50 }}
              >
                Submit
              </button>
            </Col>
          </Row>
        </Form>
      </LayoutModal>

      <Modal
        size="xl"
        isOpen={modal_xlarge}
        toggle={() => {
          tog_xlarge()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myExtraLargeModalLabel">
            Crop & Compress Images
          </h5>
          {compressedAllFiles && (
            <button
              onClick={() => {
                if (compressedAllFiles) {
                  setmodal_xlarge(false)
                }
              }}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
        <div
          className="modal-body"
          style={{
            height: 500,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {compressLoader ? (
            <Loader />
          ) : (
            <Cropper
              image={imgSrc.src}
              crop={crop}
              // rotation={rotation}
              zoom={zoom}
              aspect={isBannerImage ? 1 : 16 / 9}
              onCropChange={c => {
                setCrop(c)
                console.log(c, "CC")
              }}
              // onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          )}
        </div>

        <Button color="primary" onClick={showCroppedImage}>
          Submit
        </Button>

        <div
          className="modal-footer"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {!isBannerImage &&
            selectedFiles !== null &&
            selectedFiles.map((f, i) => {
              // console.log(f)
              return (
                !f._id && (
                  <Card
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    key={i + "-file"}
                  >
                    <div
                      className="p-2"
                      style={{
                        border: !f.compressed
                          ? "2px solid #FF6A6A"
                          : "2px solid #2FDD92",
                        borderRadius: 15,
                      }}
                    >
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <img
                            onClick={() =>
                              setImgSrc({ src: f.preview, index: i })
                            }
                            data-dz-thumbnail=""
                            height="80"
                            className="avatar-sm rounded bg-light"
                            style={{
                              objectFit: "cover",
                              // border: !f.compressed && '2px solid red'
                            }}
                            alt={f.name}
                            src={f.preview}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )
              )
            })}
        </div>
      </Modal>

      {/* <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <Modal
                      size="xl"
                      isOpen={modal_xlarge}
                      style={{height:500}}
                      toggle={() => {
                        tog_xlarge();
                      }}
                    >
                      <div className="modal-header">
                            <Cropper
                            image={dogImg}
                            crop={crop}
                            rotation={rotation}
                            zoom={zoom}
                            aspect={1}
                            onCropChange={setCrop}
                            onRotationChange={setRotation}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                          />
                      </div>
          </Modal>
          </CardBody>
          </Card>
          </Col>
          </Row> */}
    </>
  )
}

AddItem.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  editData: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(AddItem))
