import React, { useState, useEffect } from "react"
import LayoutModal from "../../components/Custom/LayoutModel"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import axios from "axios"
import { useFormik } from "formik"
import * as Yup from "yup"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import Loader from "components/Custom/Loader"
import { useSelector, useDispatch } from "react-redux"
import { userEdit } from "../../store/actions"
import pdfImage from "../../assets/images/pdfdemo.png"
// import { Overlay, OverlayTrigger } from "react-bootstrap/Overlay"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import {
  Row,
  Col,
  Card,
  FormFeedback,
  Input,
  Label,
  FormGroup,
  Form,
} from "reactstrap"
import { BaseURL, ImageURL } from "config/BaseUrl"

function AddVideo(props) {
  const [videoTitles, setVideoTitle] = useState("")
  const [pdfTitles, setPdfTitle] = useState("")
  const [link, setLink] = useState("")
  const [video, setVideo] = useState(true)
  const [pdf, setPdf] = useState(false)
  const [pdfName, setPdfName] = useState(null)
  const [files, setFiles] = useState(null)
  const [pdfFile, setPdfFile] = useState(null)
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()

  const handleImageChange = e => {
    const files = e.target.files
    files.length > 0 && setPdfName(URL.createObjectURL(files[0]))
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed

    enableReinitialize: true,
    initialValues: {
      videoTitle: videoTitles || "",
      videolink: link || "",
      // file: "",
      pdfupload: "",
      pdftitle: pdfTitles || "",
    },
    validationSchema: Yup.object({
      videoTitle: video
        ? Yup.string().required("Please Enter Video Title")
        : Yup.string(),
      videolink: video
        ? Yup.string().required("Please Enter Video Link")
        : Yup.string(),
      // file: video
      //   ? props.editData
      //     ? Yup.mixed()
      //     : Yup.mixed().required("Please select a file")
      //   : Yup.mixed(),
      pdftitle: pdf
        ? Yup.string().required("Please Enter PDF Title")
        : Yup.string(),
      pdfupload: pdf
        ? props.editData
          ? Yup.mixed()
          : Yup.mixed().required("Please select a file")
        : Yup.mixed(),
    }),
    onSubmit: values => {
      setLoader(true)
      var data = new FormData()

      if (props.editData) {
        if (props.editData.link) {
          data.append("title", values.videoTitle)
          // files !== null && data.append("doc", files)
          data.append("link", values.videolink)
          data.append("assetType", "Video")
        } else {
          data.append("title", values.pdftitle)
          pdfFile !== null && data.append("doc", pdfFile)
          data.append("link", "")
          data.append("assetType", "PDF")
        }
      } else {
        if (pdfFile) {
          data.append("title", values.pdftitle)
          pdfFile !== null && data.append("doc", pdfFile)
          data.append("link", "")
          data.append("assetType", "PDF")
        } else {
          data.append("title", values.videoTitle)
          // files !== null && data.append("doc", files)
          data.append("link", values.videolink)
          data.append("assetType", "Video")
        }
      }

      var config = {
        method: props.editData ? "put" : "post",
        url: `${BaseURL}/helpcenters${
          props.editData ? "/update/" + props.editData._id : ""
        }`,
        headers: {
          "x-auth-token": props.userData.token,
          "Content-Type": "application/json",
        },
        data: data,
      }
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data))
          setLoader(false)
          setTimeout(() => {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
          }, 100)

          setTimeout(() => {
            props.history.push("/helpcenter-list")
          }, 2000)

          if (props.editData) {
            if (props.editData.link) {
              console.log("video")
              document.getElementById("videoPreview").style.display = "none"
            } else {
              console.log("pdf")
              document.getElementById("editpdf").style.display = "none"
            }
          } else {
            document.getElementById("addpdf").style.display = "none"
          }
        })
        .catch(function (error) {
          setLoader(false)
          toast.error(error.response && error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
          console.log(error)
        })
    },
  })

  useEffect(() => {
    if (video) {
      setFiles(null)
      document.getElementById("videoTitle").value = ""
      // document.getElementById("pdfembed").style.display = "block"
      setVideoTitle("")
      setLink("")
      setPdfName(null)
      setPdfFile(null)
      setPdfTitle("")
    }
  }, [video])
  useEffect(() => {
    if (pdf) {
      // document.getElementById("pdfembed").style.display = "block"
      setPdfTitle("")
      document.getElementById("pdftitle").value = ""
      setVideoTitle("")
      setPdfFile(null)
      setPdfName(null)
    }
  }, [pdf])

  useEffect(() => {
    // const params = useParams();

    const data = props.editData
    if (props.editData) {
      // console.log("pppppppppp", props.editData.pdf.fileSize)
      if (data.link) {
        document.getElementById("video").checked = true
        document.getElementById("videoTitle").value = data.title
        document.getElementById("videolink").value = data.link
        setVideoTitle(data.title)
        setLink(data.link)
      } else {
        setVideo(false)
        setTimeout(function () {
          document.getElementById("pdftitle").value = data.title
          document.getElementById("pdf").checked = true
          setPdfTitle(data.title)
        }, 200)

        document.getElementById("video").checked = false
        document.getElementById("pdf").checked = true
        document.getElementById("video").disabled = true
        document.getElementById("pdf").disabled = true
      }
    } else {
      document.getElementById("video").checked = true
    }

    return () => dispatch(userEdit(null))
  }, [props.editData])

  if (loader) {
    return <Loader />
  }
  const renderTooltip = props => (
    <Tooltip id="button-tooltip" {...props}>
      Enter embed video Link
    </Tooltip>
  )
  return (
    <>
      <Tostify />
      <div className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row">
        <div className="col-sm-12 col-lg-5">
          <LayoutModal
            button={true}
            // form={true}
            title={props.editData ? "Edit Help Center" : "Help center"}
            btnTitle={props.editData ? "Help Center List" : "Help Center List"}
            onclick={() => {
              props.editData
                ? props.history.push("/helpcenter-list")
                : props.history.push("/helpcenter-list")
            }}
          >
            <Form
              onSubmit={validation.handleSubmit}
              // onSubmit={ props.editData ? editUser  : submitAddUser}
            >
              <FormGroup
                tag="fieldset"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "space-between",
                }}
              >
                <Label className="form-label">
                  Select Type <span style={{ color: "red" }}>*</span>
                </Label>
                <FormGroup style={{ marginLeft: 30 }} check>
                  <Label check>
                    <Input
                      type="radio"
                      name="radio1"
                      id="video"
                      disabled={props.editData ? true : false}
                      onChange={e => {
                        setVideo(true)
                        setPdf(false)
                      }}
                      // checked={video === true}
                    />
                    Video
                  </Label>
                </FormGroup>
                <FormGroup style={{ marginLeft: 20 }} check>
                  <Label check>
                    <Input
                      type="radio"
                      name="radio1"
                      id="pdf"
                      disabled={props.editData ? true : false}
                      onChange={e => {
                        setVideo(false)
                        setPdf(true)
                      }}
                    />
                    PDF
                  </Label>
                </FormGroup>
              </FormGroup>
              {video == true ? (
                <div>
                  <div className="mb-3">
                    <Label className="form-label">
                      Title <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      // ref={nameRef}
                      name="videoTitle"
                      id="videoTitle"
                      className="form-control"
                      placeholder="Enter your Video Title"
                      type="text"
                      onChange={e => {
                        validation.handleChange(e)
                        setVideoTitle(e.target.value)
                      }}
                      // onBlur={validation.handleBlur}
                      value={validation.videoTitle}
                      invalid={
                        validation.touched.videoTitle &&
                        validation.errors.videoTitle
                          ? true
                          : false
                      }
                    />
                    <FormFeedback type="invalid">
                      {validation.errors.videoTitle}
                    </FormFeedback>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label" style={{ marginRight: 5 }}>
                      Link{" "}
                    </Label>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip}
                    >
                      <i className="fa fa-info-circle" variant="success"></i>
                    </OverlayTrigger>{" "}
                    <span style={{ color: "red" }}>*</span>
                    <Input
                      // ref={nameRef}
                      name="videolink"
                      id="videolink"
                      className="form-control"
                      placeholder="Enter your Video Link"
                      type="text"
                      onChange={e => {
                        validation.handleChange(e)
                        setLink(e.target.value)
                      }}
                      value={validation.videolink}
                      invalid={
                        validation.touched.videolink &&
                        validation.errors.videolink
                          ? true
                          : false
                      }
                    />
                    <FormFeedback type="invalid">
                      {validation.errors.videolink}
                    </FormFeedback>
                  </div>
                  {/* <div className="mb-3">
                    <Label className="form-label">
                      Select File <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      name="file"
                      id="file"
                      className="form-control"
                      type="file"
                      accept="image/png, image/jpeg,image/JPG,image/PNG, image/JPEG,image/JPG,"
                      // onChange={(e) => setFiles(e.target.files[0])}
                      onChange={e => {
                        validation.handleChange(e)
                        setFiles(e.target.files[0])
                      }}
                      // ref={fileRef}
                      value={validation.file || ""}

                      // invalid={
                      //   (validation.touched.file &&
                      //     files !== null &&
                      //     files.size >
                      //       Number(process.env.REACT_APP_FILE_SIZE)) ||
                      //   validation.errors.file
                      //     ? true
                      //     : false
                      // }
                    />

                    {validation.touched.file &&
                    files !== null &&
                    files.size > Number(process.env.REACT_APP_FILE_SIZE) ? (
                      <FormFeedback type="invalid">
                        File must be less than 2 MB
                      </FormFeedback>
                    ) : null}

                    {validation.touched.file && validation.errors.file ? (
                      <FormFeedback type="invalid">
                        {validation.errors.file}
                      </FormFeedback>
                    ) : null}
                  </div>
                  {files !== null && (
                    <Card
                      className="mt-1 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      style={{
                        backgroundColor:
                          files.size > Number(process.env.REACT_APP_FILE_SIZE)
                            ? "#ff6a6a"
                            : "#2FDD92",
                        color:
                          files.size > Number(process.env.REACT_APP_FILE_SIZE)
                            ? "#ffffff"
                            : "#131313",
                        width: "97%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <div className="p-2">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              style={{
                                objectFit: "cover",
                              }}
                              alt={files.name}
                              src={URL.createObjectURL(files)}
                            />
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              // className="text-muted font-weight-bold"
                              style={{
                                color:
                                  files.size >
                                  Number(process.env.REACT_APP_FILE_SIZE)
                                    ? "#ffffff"
                                    : "#131313",
                              }}
                            >
                              {files.name}
                            </Link>
                            <p className="mb-0">
                              <strong>
                                {(files.size / 1048576).toFixed(2) + " MB"}
                              </strong>
                            </p>
                            <i
                              className="bx bx-no-entry"
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 10,
                                color:
                                  files.size >
                                  Number(process.env.REACT_APP_FILE_SIZE)
                                    ? "#ffffff"
                                    : "#131313",
                                fontSize: 17,
                                fontWeight: 800,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setFiles(null)
                                document.getElementById("file").value = ""
                              }}
                            ></i>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )}
                  {props.editData &&
                    props.editData.thumbnail &&
                    showEditImage &&
                    files == null && (
                      <Card
                        className="mt-1 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                        style={{
                          backgroundColor:
                            props.editData.thumbnail.fileSize >
                            Number(process.env.REACT_APP_FILE_SIZE)
                              ? "#ff6a6a"
                              : "#2FDD92",
                          color:
                            props.editData.thumbnail.fileSize >
                            Number(process.env.REACT_APP_FILE_SIZE)
                              ? "#ffffff"
                              : "#131313",
                          width: "97%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <div className="p-2">
                          <Row className="align-items-center">
                            <Col className="col-auto">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                className="avatar-sm rounded bg-light"
                                style={{
                                  objectFit: "cover",
                                }}
                                alt={props.editData.thumbnail.originalname}
                                // alt={files.name}
                                src={`${ImageURL}/helpcenter/${props.editData.thumbnail.filename}`}
                              />
                            </Col>
                            <Col>
                              <Link
                                to="#"
                                // className="text-muted font-weight-bold"
                                style={{
                                  color:
                                    props.editData.thumbnail.fileSize >
                                    Number(process.env.REACT_APP_FILE_SIZE)
                                      ? "#ffffff"
                                      : "#131313",
                                }}
                              >
                                {props.editData.thumbnail.originalname}
                              </Link>
                              <p className="mb-0">
                                <strong>
                                  {(
                                    props.editData.thumbnail.fileSize / 1024
                                  ).toFixed(2) + " MB"}
                                </strong>
                              </p>
                              <i
                                className="bx bx-no-entry"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 10,
                                  color:
                                    props.editData.thumbnail.fileSize >
                                    Number(process.env.REACT_APP_FILE_SIZE)
                                      ? "#ffffff"
                                      : "#131313",
                                  fontSize: 17,
                                  fontWeight: 800,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setShowEditImage(false)
                                }}
                              ></i>
                            </Col>
                          </Row>
                        </div>
                      </Card>
                    )} */}
                </div>
              ) : (
                <div>
                  <div className="mb-3">
                    <Label className="form-label">
                      Title <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      // ref={nameRef}
                      name="pdftitle"
                      id="pdftitle"
                      className="form-control"
                      placeholder="Enter your PDF Title"
                      type="text"
                      onChange={e => {
                        validation.handleChange(e)
                        setPdfTitle(e.target.value)
                      }}
                      // onBlur={validation.handleBlur}
                      value={validation.pdftitle}
                      invalid={
                        validation.touched.pdftitle &&
                        validation.errors.pdftitle
                          ? true
                          : false
                      }
                    />
                    <FormFeedback type="invalid">
                      {validation.errors.pdftitle}
                    </FormFeedback>
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">
                      PDF File <span style={{ color: "red" }}>*</span>
                    </Label>
                    <Input
                      // ref={nameRef}
                      name="pdfupload"
                      id="pdfupload"
                      className="form-control"
                      placeholder="Enter your Video Link"
                      type="file"
                      accept=".pdf"
                      onChange={e => {
                        validation.handleChange(e)
                        setPdfFile(e.target.files[0])
                        handleImageChange(e)
                      }}
                      //   onBlur={validation.handleBlur}
                      value={validation.pdfupload || ""}
                      invalid={
                        (validation.touched.pdfupload &&
                          pdfFile !== null &&
                          pdfFile.size >
                            Number(process.env.REACT_APP_FILE_SIZE)) ||
                        validation.errors.pdfupload
                          ? true
                          : false
                      }
                    />

                    {validation.touched.pdfupload &&
                    pdfFile !== null &&
                    pdfFile.size > Number(process.env.REACT_APP_FILE_SIZE) ? (
                      <FormFeedback type="invalid">
                        File must be less than 2 MB
                      </FormFeedback>
                    ) : null}

                    {validation.touched.file && validation.errors.pdfupload ? (
                      <FormFeedback type="invalid">
                        {validation.errors.pdfupload}
                      </FormFeedback>
                    ) : null}
                  </div>
                  {pdfFile !== null && (
                    <Card
                      className="mt-1 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      style={{
                        backgroundColor:
                          pdfFile.size > Number(process.env.REACT_APP_FILE_SIZE)
                            ? "#ff6a6a"
                            : "#2FDD92",
                        color:
                          pdfFile.size > Number(process.env.REACT_APP_FILE_SIZE)
                            ? "#ffffff"
                            : "#131313",
                        width: "97%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <div className="p-2" id="addpdf">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              style={{
                                objectFit: "cover",
                              }}
                              alt={pdfFile.name}
                              src={pdfImage}
                            />
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              className="text-muted font-weight-bold"
                              style={{
                                color:
                                  pdfFile.size >
                                  Number(process.env.REACT_APP_FILE_SIZE)
                                    ? "#ffffff"
                                    : "#131313",
                              }}
                            >
                              {pdfFile.name}
                            </Link>
                            <p className="mb-0">
                              <strong>
                                {(pdfFile.size / 1048576).toFixed(2) + " MB"}
                              </strong>
                            </p>
                            <i
                              className="bx bx-no-entry"
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 10,
                                color:
                                  pdfFile.size >
                                  Number(process.env.REACT_APP_FILE_SIZE)
                                    ? "#ffffff"
                                    : "#131313",
                                fontSize: 17,
                                fontWeight: 800,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setPdfFile(null)
                                document.getElementById("pdfupload").value = ""
                              }}
                            ></i>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )}
                  {props.editData && props.editData.pdf && pdfFile == null && (
                    <Card
                      className="mt-1 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                      style={{
                        backgroundColor: "#2FDD92",
                        color: "#131313",
                        width: "97%",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                    >
                      <div className="p-2" id="editpdf">
                        <Row className="align-items-center">
                          <Col className="col-auto">
                            <img
                              data-dz-thumbnail=""
                              height="80"
                              className="avatar-sm rounded bg-light"
                              style={{
                                objectFit: "cover",
                              }}
                              // alt={props.editData.pdf.filename}
                              src={pdfImage}
                            />
                          </Col>
                          <Col>
                            <Link
                              to="#"
                              className="text-muted font-weight-bold"
                              style={{
                                color: "#ffffff",
                              }}
                            >
                              {props.editData.pdf.filename}
                            </Link>
                            <p className="mb-0">
                              <strong>
                                {(
                                  props.editData.pdf.fileSize / 1048576
                                ).toFixed(2) + " MB"}
                              </strong>
                            </p>
                            <i
                              className="bx bx-no-entry"
                              style={{
                                position: "absolute",
                                top: 0,
                                right: 10,
                                color: "#131313",
                                fontSize: 17,
                                fontWeight: 800,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setPdfFile(null)
                                document.getElementById(
                                  "editpdf"
                                ).style.display = "none"
                                document.getElementById("pdfupload").value = ""
                              }}
                            ></i>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )}
                </div>
              )}

              <Row className="mb-3">
                <Col className="text-start">
                  <button
                    className="btn btn-primary w-md "
                    type="submit"
                    // onClick={() => handleSubmit()}
                  >
                    Submit
                  </button>
                </Col>
              </Row>
            </Form>
          </LayoutModal>
        </div>
        {
          props.editData && props.editData.link && (
            <div className="col-sm-12 col-lg-7" id="videoPreview">
              <LayoutModal>
                <iframe
                  frameBorder="1"
                  style={{ marginLeft: 50, height: 300, width: 600 }}
                  src={props.editData.link}
                />
              </LayoutModal>
            </div>
          )
          //  (
          //   props.editData.pdf !== null ? (
          //     <div className="col-sm-12 col-lg-8" id="pdfembed">
          //       <LayoutModal>
          //         <iframe
          //           style={{ marginLeft: 50, height: 600, width: 550 }}
          //           src={`${ImageURL}/helpcenter/${props.editData.pdf.filename}`}
          //         />
          //         {/* <Document
          //           file={{
          //             url: `${ImageURL}/helpcenter/${props.editData.pdf.filename}`,
          //           }}
          //           // onLoadSuccess={onDocumentLoadSuccess}
          //         />
          //         <Page pageNumber={1} /> */}
          //       </LayoutModal>
          //     </div>
          //   ) : (
          //     <div className="col-sm-12 col-lg-7" id="pdfembed">
          //       <LayoutModal>
          //         <iframe
          //           frameBorder="1"
          //           style={{ marginLeft: 50, height: 300, width: 600 }}
          //           src={props.editData.link}
          //         />
          //       </LayoutModal>
          //     </div>
          //   )
          // ) : (
          //   <div className="col-sm-12 col-lg-7" id="pdfembed">
          //     <LayoutModal>
          //       <embed
          //         style={{ marginLeft: 50, height: 600, width: 550 }}
          //         src={pdfName + "#toolbar=0"}
          //       />
          //     </LayoutModal>
          //   </div>
          // )
        }
      </div>
      {/* <Popover id="popover-positioned-top">
        <Popover.Body>
          <strong>Holy guacamole!</strong> Check this info.
        </Popover.Body>
      </Popover> */}
    </>
  )
}

AddVideo.propTypes = {
  userData: PropTypes.object,
  editData: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(AddVideo))
