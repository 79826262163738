import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useState, useEffect } from "react"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"

//redux
import { useSelector, useDispatch, connect } from "react-redux"

import { withRouter, Link } from "react-router-dom"

// Formik Validation
import * as Yup from "yup"
import { useFormik } from "formik"

// action
import { userForgetPassword } from "../../store/actions"

// import images
import profile from "../../assets/images/profile-img.png"
import logo from "../../assets/images/logo.png"

import axios from "axios"
import LayoutModal from "../../components/Custom/LayoutModel"
import { BaseURL } from "config/BaseUrl"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import Loader from "components/Custom/Loader"
import Selector from "components/Custom/Selector"

const ForgetPasswordPage = props => {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const [selectedParty, setSelectedParty] = useState(null)
  const [party, setPartyList] = useState([])
  console.log(props.userData, "DATATA")

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseURL}/formdata/party`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data), "PARTIES")
        setPartyList(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      newPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("Please Enter Your New Password")
        .min(6, "Password must have at least 6 characters")
        .notOneOf(
          [Yup.ref("oldPassword")],
          "Please enter different password from old password"
        ),
    }),
    onSubmit: values => {
      setLoader(true)
      console.log(values)

      if (selectedParty === null) {
        toast.error("Please select a party", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }

      var data = JSON.stringify({
        party: selectedParty,
        password: values.newPassword,
      })

      var config = {
        method: "put",
        url: `${BaseURL}/users/changePass`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": props.userData.token,
        },
        data: data,
      }

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data))
          setLoader(false)

          document.getElementById("newPassword").value = ""

          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch(function (error) {
          setLoader(false)
          console.log(error)
          toast.error(error.response && error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
    },
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }))

  if (loader) {
    return <Loader />
  }

  return (
    <>
      <Tostify />

      <LayoutModal
        button={false}
        form={true}
        title="Change Party Password"
        // btnTitle="Reset Password"
        // onclick={() => {props.editData ? dispatch(userEdit(null)) : props.history.push("/user-list")}}
        //  sideTitle="Reset Password"
      >
        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <Selector
            label="Party Name"
            data={party !== null ? party : []}
            onchange={event => setSelectedParty(event.value)}
            // value={}
            required
          />

          <div className="mb-3">
            <Label className="form-label">New Password</Label>
            <Input
              name="newPassword"
              id="newPassword"
              className="form-control"
              placeholder="Enter your new password"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.newPassword || ""}
              invalid={
                validation.touched.newPassword && validation.errors.newPassword
                  ? true
                  : false
              }
            />
            {validation.touched.newPassword && validation.errors.newPassword ? (
              <FormFeedback type="invalid">
                {validation.errors.newPassword}
              </FormFeedback>
            ) : null}
          </div>

          <Row className="mb-3">
            <Col className="text-start">
              <button className="btn btn-primary w-md " type="submit">
                Reset
              </button>
            </Col>
          </Row>
        </Form>
      </LayoutModal>
    </>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
  }
}

export default connect(mapStateToProps)(withRouter(ForgetPasswordPage))
