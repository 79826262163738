import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { version } from "../../package.json"
import { BaseURL, ImageURL } from "../config/BaseUrl"
import axios from "axios"
const useCacheBuster = () => {
  const location = useLocation()
  const parseVersion = str => +str.replace(/\D/g, "")
  // const fetch = window?.window.fetch.bind(window)
  console.log(ImageURL, "BBB")

  useEffect(() => {
    axios(`/meta.json?v=${+new Date()}`, {
      cache: "no cache",
    })
      .then(response => response.data)
      .then(meta => {
        if (meta?.version) {
          const metaVersion = parseVersion(meta.version)
          const packageVersion = parseVersion(version)
          console.log(
            metaVersion + " META",
            packageVersion + " PKG",
            "In Versionn"
          )
          if (packageVersion < metaVersion) {
            console.log("is metaVersion version greater")
            if (window?.location?.reload) {
              window.location.reload()
            }
          }
        }
      })
      .catch(error => {
        console.error("something went wrong fetching meta.json", error)
      })
  }, [location])

  return null
}

export default useCacheBuster
