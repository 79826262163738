// const isDev = true
const isDev = false

// console.log(process.env.NODE_ENV, isDev, "ISDEV")

export const dev = isDev
export let BaseURL = ""
export let ImageURL = ""

if (isDev) {
  // BaseURL = "http://192.168.29.192:4000/api"
  // ImageURL = "http://192.168.29.192:4000"

  // BaseURL = "http://192.168.29.6:4000/api"
  // ImageURL = "http://192.168.29.6:4000"

  BaseURL = "https://test-powers.wiztechlabs.tech/api"
  ImageURL = "https://test-powers.wiztechlabs.tech"

  // BaseURL = "https://powers.wiztechlabs.tech/api"
  // ImageURL = "https://powers.wiztechlabs.tech"
} else {


  BaseURL = "https://powers.wiztechlabs.tech/api"
  ImageURL = "https://powers.wiztechlabs.tech"
}

// const URL = {BaseURL, ImageURL}

// export default URL;
