import React, { useState, useRef, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import LayoutModal from "../../components/Custom/LayoutModel"
import { userEdit } from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Selector from "../../components/Custom/Selector"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import Loader from "components/Custom/Loader"
import { BaseURL, ImageURL } from "config/BaseUrl"

function AddCategory(props) {
  const [files, setFiles] = useState(null)
  const [brands, setBrands] = useState(null)
  const [selectBrand, setSelectBrand] = useState(null)
  const [editData, setEditData] = useState(null)
  const [loader, setLoader] = useState(false)
  const [showEditImage, setShowEditImage] = useState(true)

  const [submitting, setSubmitting] = useState(false)

  const dispatch = useDispatch()
  const fileRef = useRef()

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      category: props.editData ? props.editData.name : "",
      // name: '',
      file: "",
      // brandSelect:'',
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Please Enter Your Category"),
      // name: Yup.string().required("Please Enter Your Brand Name"),
      file: props.editData
        ? Yup.mixed()
        : Yup.mixed().required("Please select a file"),
      // brandSelect: props.editData ? Yup.string() :Yup.string().required("Please select a Brand")
    }),

    onSubmit: values => {
      setSubmitting(true)

      console.log(values, files, "BRAND PAGE")

      if (
        files !== null &&
        files.size > Number(process.env.REACT_APP_FILE_SIZE)
      ) {
        fileRef.current = null
        // console.log("Hello")

        toast.error("File size exceeds 2MB limit", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      } else {
        if (selectBrand == null) {
          setLoader(false)
          return toast.error("Please select a brand", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        }
        setLoader(true)
        var data = new FormData()
        data.append(
          "name",
          values.category !== undefined ? values.category : editData.name
        )
        data.append(
          "brand",
          selectBrand !== undefined ? selectBrand : editData.brand._id
        )
        files !== null && data.append("doc", files)

        var config = {
          method: props.editData ? "put" : "post",
          url: `${BaseURL}/categories${
            props.editData ? "/" + props.editData._id : ""
          }`,
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": props.userData.token,
          },
          data: data,
        }

        axios(config)
          .then(function (response) {
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
            props.history.push("/category-list")
            console.log(JSON.stringify(response.data))
            let brandsData = brandList
            setLoader(false)
            setFiles(null)
            document.getElementById("category").value = ""
            document.getElementById("file").value = ""
            setSelectBrand("")
            setBrandList([])
            setTimeout(() => {
              setBrandList(brandsData)
            }, 100)
          })
          .catch(function (error) {
            // console.log(error);

            toast.error(error.response && error.response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
            setLoader(false)
          })
      }
    },
  })

  useEffect(() => {
    const data = props.editData

    console.log("dataaaa", data)

    if (data && data.brand) {
      document.getElementById("category").value = data.name

      setEditData(data)
      setSelectBrand(data.brand._id)
    } else {
      document.getElementById("category").value = ""
      setEditData(null)
      dispatch(userEdit(null))
    }

    return () => dispatch(userEdit(null))
  }, [props.editData])

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseURL}/formdata/brand`,
      headers: {
        "x-auth-token": props.userData.token,
      },
      // data : data
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        setBrands(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  if (loader) {
    return <Loader />
  }

  return (
    <>
      <Tostify />
      <LayoutModal
        button={true}
        form={true}
        title={props.editData ? "Edit Category" : "Add Category"}
        btnTitle={props.editData ? "Add Category" : "Category List"}
        onclick={() => {
          props.editData
            ? dispatch(userEdit(null))
            : props.history.push("/category-list")
        }}
      >
        <Form
          className="form-horizontal"
          onSubmit={e => {
            e.preventDefault()
            validation.handleSubmit()
            return false
          }}
        >
          <div className="mb-3">
            <Label className="form-label">
              Name <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="category"
              id="category"
              className="form-control"
              placeholder="Enter category name"
              type="text"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              // value={validation.values.category}
              invalid={
                validation.touched.category && validation.errors.category
                  ? true
                  : false
              }
            />
            {validation.touched.category && validation.errors.category ? (
              <FormFeedback type="invalid">
                {validation.errors.category}
              </FormFeedback>
            ) : null}
          </div>

          <Selector
            label="Brand"
            required={true}
            id="select"
            data={brands !== null ? brands : []}
            onchange={event => setSelectBrand(event.value)}
            edit={
              props.editData &&
              props.editData.brand && {
                label: props.editData.brand.name,
                value: props.editData.brand._id,
              }
            }
          />
          {/* {submitting && selectBrand == null && <span style={{color:"red"}}>Please select a Brand</span>} */}

          {/* <div className="mb-3">
                        <Label className="form-label">Brand</Label>
                        <Input
                          name="name"
                          id="name"
                          className="form-control"
                          placeholder="Enter brand name"
                          type="select"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.name || ""}
                          invalid={
                            validation.touched.name && validation.errors.name ? true : false
                          }
                        >
                          {
                            brands !== null && brands.map((d,i) =>{
                             return <option key={d._id} value={[d.name,d._id]}>{d.name}</option>
                              
                            })
                          }
                        </Input>
                        {validation.touched.name && validation.errors.name ? (
                          <FormFeedback type="invalid">{validation.errors.name}</FormFeedback>
                        ) : null}
                      </div> */}

          <div className="mb-3">
            <Label className="form-label">
              Select File (500 x 500) <span style={{ color: "red" }}>*</span>
            </Label>
            {/* <Input
                          name="file"
                          id="file"
                          className="form-control"
                          placeholder="Enter your new password"
                          type="file"
                          onChange={(e) => setFiles(e.target.files[0])}
                          onBlur={validation.handleBlur}
                          ref={fileRef}
                          // value=""
                          invalid={
                            validation.touched.file  && files !== null && files.size > Number(process.env.REACT_APP_FILE_SIZE)  ? true : false
                          }
                        />
                        
                        {validation.touched.file && files !== null && files.size > Number(process.env.REACT_APP_FILE_SIZE) ? (
                          <FormFeedback type="invalid">File must be less than 2 MB</FormFeedback>
                        ) : null} */}
            <Input
              name="file"
              id="file"
              className="form-control"
              // placeholder="Enter your new password"
              type="file"
              // onChange={(e) => setFiles(e.target.files[0])}
              onChange={e => {
                validation.handleChange(e)
                setFiles(e.target.files[0])
              }}
              onBlur={validation.handleBlur}
              ref={fileRef}
              value={validation.file || ""}
              invalid={
                (validation.touched.file &&
                  files !== null &&
                  files.size > Number(process.env.REACT_APP_FILE_SIZE)) ||
                validation.errors.file
                  ? true
                  : false
              }
            />

            {validation.touched.file &&
            files !== null &&
            files.size > Number(process.env.REACT_APP_FILE_SIZE) ? (
              <FormFeedback type="invalid">
                File must be less than 2 MB
              </FormFeedback>
            ) : null}

            {validation.touched.file && validation.errors.file ? (
              <FormFeedback type="invalid">
                {validation.errors.file}
              </FormFeedback>
            ) : null}
          </div>

          {files !== null && (
            <Card
              className="mt-1 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              style={{
                backgroundColor:
                  files.size > Number(process.env.REACT_APP_FILE_SIZE)
                    ? "#ff6a6a"
                    : "#2FDD92",
                color:
                  files.size > Number(process.env.REACT_APP_FILE_SIZE)
                    ? "#ffffff"
                    : "#131313",
                width: "97%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      style={{
                        objectFit: "cover",
                      }}
                      alt={files.name}
                      src={URL.createObjectURL(files)}
                    />
                  </Col>
                  <Col>
                    <Link
                      to="#"
                      // className="text-muted font-weight-bold"
                      style={{
                        color:
                          files.size > Number(process.env.REACT_APP_FILE_SIZE)
                            ? "#ffffff"
                            : "#131313",
                      }}
                    >
                      {files.name}
                    </Link>
                    <p className="mb-0">
                      <strong>
                        {(files.size / 1048576).toFixed(2) + " MB"}
                      </strong>
                    </p>
                    <i
                      className="bx bx-no-entry"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 10,
                        color:
                          files.size > Number(process.env.REACT_APP_FILE_SIZE)
                            ? "#ffffff"
                            : "#131313",
                        fontSize: 17,
                        fontWeight: 800,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setFiles(null)
                        document.getElementById("file").value = ""
                      }}
                    ></i>
                  </Col>
                </Row>
              </div>
            </Card>
          )}

          {props.editData &&
            props.editData.file &&
            showEditImage &&
            files == null && (
              <Card
                className="mt-1 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                style={{
                  backgroundColor:
                    props.editData.file.fileSize >
                    Number(process.env.REACT_APP_FILE_SIZE)
                      ? "#ff6a6a"
                      : "#2FDD92",
                  color:
                    props.editData.file.fileSize >
                    Number(process.env.REACT_APP_FILE_SIZE)
                      ? "#ffffff"
                      : "#131313",
                  width: "97%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        height="80"
                        className="avatar-sm rounded bg-light"
                        style={{
                          objectFit: "cover",
                        }}
                        alt={props.editData.file.name}
                        // alt={files.name}
                        src={`${ImageURL}/categories/${props.editData.file.filename}`}
                      />
                    </Col>
                    <Col>
                      <Link
                        to="#"
                        // className="text-muted font-weight-bold"
                        style={{
                          color:
                            props.editData.file.fileSize >
                            Number(process.env.REACT_APP_FILE_SIZE)
                              ? "#ffffff"
                              : "#131313",
                        }}
                      >
                        {props.editData.file.originalname}
                      </Link>
                      <p className="mb-0">
                        <strong>
                          {(props.editData.file.fileSize / 1024).toFixed(2) +
                            " MB"}
                        </strong>
                      </p>
                      <i
                        className="bx bx-no-entry"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 10,
                          color:
                            props.editData.file.fileSize >
                            Number(process.env.REACT_APP_FILE_SIZE)
                              ? "#ffffff"
                              : "#131313",
                          fontSize: 17,
                          fontWeight: 800,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowEditImage(false)
                          document.getElementById("file").value = ""
                        }}
                      ></i>
                    </Col>
                  </Row>
                </div>
              </Card>
            )}

          <Row className="mb-3">
            <Col className="text-start">
              <button className="btn btn-primary w-md " type="submit">
                Submit
              </button>
            </Col>
          </Row>
        </Form>
      </LayoutModal>
    </>
  )
}

AddCategory.propTypes = {
  userData: PropTypes.object,
  editData: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(AddCategory))
