import React from 'react'
import { Spinner } from 'reactstrap'

function Loader() {
  return (
      <div
      style={{
          position: 'relative',
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          alignContent:"center",
      }}
      >
        <Spinner
        style={{ width: 50, height: 50 }}
        color="primary"
        animation="border"
       />
     
   
  
      </div>
  )

}

export default Loader