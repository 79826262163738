import React, { useState, useEffect } from "react"
import LayoutModal from "../../components/Custom/LayoutModel"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import "./datatables.scss"

import { connect } from "react-redux"
import Tostify from "components/Custom/Tostify"
import PropTypes from "prop-types"
import { BaseURL } from "config/BaseUrl"
import { Link, withRouter, Route } from "react-router-dom"

import { Row, Col } from "../imports/import"

import axios from "axios"

import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
  Modal,
  CardBody,
  Card,
  Container,
  Label,
  Input,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"

import { userEdit } from "../../store/actions"

import { toast } from "react-toastify"
import Loader from "components/Custom/Loader"
import moment from "moment"
import Selector from "components/Custom/Selector"

function FinalReportScreen(props) {
  const dispatch = useDispatch()

  const party_id = props.location.state.partyId
  const item_id = props.location.state.data.id
  const [pendingQty, setPendingQty] = useState("")
  const [sizePerPage, setSizePerPage] = useState(10)
  const [activeTooltip, setActiveTooltip] = useState(false)
  const [rowIndexTable, setRowIndexTable] = useState()
  const [loader, setLoader] = useState(false)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [orderListStatus, setOrderListStatus] = useState([])
  const [oldOrderListStatus, setOldOrderListStatus] = useState([])
  const [modal_backdrop, setmodal_backdrop] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(null)
  const [reasonsOfStatus, setReasonsOfStatus] = useState([])
  const [updateVal, setUpdateVal] = useState("")
  const [selectedOrderStatus, setSelectedOrderStatus] = useState()
  const [remark, setRemark] = useState("")
  const [selectedReason, setSelectedReason] = useState(null)

  // console.log(props.location.state, "DIRECTORY DATA")
  // console.log(props.location.state.data.id, "partyid")
  // console.log(props.location.state.itemId, "item_id")
  // console.log(party_id, "kkkkkkijiuiu")
  // console.log(item_id, "lloo")

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    // removeBodyCss()
  }
  useEffect(() => {
    var data = JSON.stringify({
      partyId: party_id ? party_id : props.location.state.data.id,
      itemId: party_id ? item_id : props.location.state.itemId,
    })

    var config = {
      method: "post",
      url: BaseURL + "/partywiseitem/orders",
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data.orders))
        setPendingQty(response.data.orders)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])
  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseURL}/orderstatuses`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        console.log(response.data, "Order Status")
        setOrderListStatus(response.data)
        setOldOrderListStatus(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])
  const editUserData = ({ row, type }) => {
    console.log(row, type, "EDIT")
    try {
      localStorage.setItem("editData", JSON.stringify(row))
    } catch {
      console.log("user list editData localStorage fail")
    }

    try {
      dispatch(userEdit(row))

      if (type == "edit") {
        props.history.push("/add-order")
      } else {
        props.history.push("/order-delivery")
      }
    } catch (e) {
      console.log("user list redux editdata  fail")
    }
  }
  const submitReason = ({ data, state, orderStatus }) => {
    const id = data.order._id
    setLoader(true)

    var data = JSON.stringify({
      orderStatus: orderStatus,
      reason: selectedReason ? selectedReason : null,
      remarks: remark ? remark : null,
    })

    var config = {
      method: "put",
      url: `${BaseURL}/orders/${state}/${id ? id : selectedOrder._id}`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        // setOrderList([])
        console.log(JSON.stringify(response.data))
        setLoader(false)
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })

        // var data = JSON.stringify({
        //   "status": "Pending"
        // });

        var config = {
          method: "post",
          url: `${BaseURL}/orders/pending`,
          headers: {
            "x-auth-token": props.userData.token,
          },
          // data : data
        }

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data))
            setOrderList([])
            setTimeout(() => {
              setOrderList(response.data)
            }, 50)
          })
          .catch(function (error) {
            console.log(error)
          })

        // let dataOfOrderList = orderList
        // let i = dataOfOrderList.indexOf(selectedOrder)

        // dataOfOrderList[i].orderStatus = response.data.order_status

        // console.log(dataOfOrderList , "order data changed")
        // setOrderList([])

        //   setOrderList([...dataOfOrderList])

        setmodal_backdrop(false)
      })
      .catch(function (error) {
        console.log(error)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        setLoader(false)
      })
  }
  const columns = [
    {
      dataField: "order.seq",
      text: "Order Number",
      sort: true,
    },
    {
      dataField: "order.date",
      text: "Date",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        return (
          <div
            style={{
              width: 100,
              wordBreak: "break-all",
              overflowWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            <p>{moment(cellContent).format("DD/MM/YYYY")}</p>
          </div>
        )
      },
    },
    {
      dataField: "pendingQty",
      text: "Pending Qty",
      sort: true,
    },
    {
      dataField: "dummy01",
      text: "Action",
      isDummyField: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              size="sm"
              type="button"
              className="btn btn-warning"
              onClick={() => editUserData({ row: row.order, type: "edit" })}
            >
              {/* <i className="bx bxs-pencil font-size-15 "></i> */}
              Edit
            </Button>

            <Button
              size="sm"
              type="button"
              style={{ marginLeft: 10 }}
              className="btn btn-info"
              onClick={() => editUserData({ row: row.order, type: "deliver" })}
            >
              {/* <i className="bx bxs-pencil font-size-15 "></i> */}
              Deliver
            </Button>
            <Button
              size="sm"
              type="button"
              style={{ marginLeft: 10 }}
              className="btn btn-danger"
              onClick={() => {
                setSelectedOrder(row)
                setUpdateVal("delete")
                setmodal_backdrop(true)
                orderListStatus.map((d, i) => {
                  if (d.name == "Deleted") {
                    setSelectedOrderStatus(d._id)
                    setReasonsOfStatus(d.reasons)
                    console.log(d, "Deleted DATA")
                    if (d.reasons.length > 0) {
                      setmodal_backdrop(true)
                    } else {
                      setUpdateVal("delete")
                      setSelectedOrder(row)
                      // console.log(row, updateVal,selectedOrder ,"ROOOOOOOOOOOOOOOOOOOOOOOOOOOO")
                      submitReason({
                        data: row,
                        state: "delete",
                        orderStatus: d._id,
                      })
                    }
                  }
                })
              }}
            >
              {/* <i className="bx bxs-pencil font-size-15 "></i> */}
              Delete
            </Button>
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPageRenderer,
    sizePerPage: 50,
    totalSize: pendingQty.length, // replace later with size(customers),
    // custom: true,
  }

  const { SearchBar } = Search

  if (loader) {
    return <Loader />
  }

  return (
    <>
      <Tostify />
      <LayoutModal sideTitle="User" button={false}>
        <div className="col-7 row">
          <div className="col-6">
            <Label className="form-label">
              Item Name :{" "}
              {props.location.state.itemName
                ? props.location.state.itemName
                : props.location.state.data.name}
            </Label>
          </div>

          <div className="col-6">
            <Label className="form-label">
              Pending Qty:{props.location.state.data.pendingQty}
            </Label>
          </div>
        </div>
        <div className="col-7 row mb-3">
          <div className="col-6">
            <Label className="form-label">
              party:
              {props.location.state.partyName
                ? props.location.state.partyName
                : props.location.state.data.name}
            </Label>
          </div>
          <div className="col-6">
            <Label className="form-label">
              City :
              {props.location.state.data.city
                ? props.location.state.data.city
                : props.location.state.cityName}
            </Label>
          </div>
        </div>

        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="order.seq"
          columns={columns}
          data={pendingQty}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="order.seq"
              columns={columns}
              data={pendingQty}
              //   search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="7">
                      <div className="table-responsive">
                        <BootstrapTable
                          // keyField={"email"}

                          responsive
                          bordered={false}
                          striped={true}
                          // defaultSorted={defaultSorted}
                          hover
                          // selectRow={selectRow}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                   
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row> */}
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </LayoutModal>
      <Modal
        isOpen={modal_backdrop}
        toggle={() => {
          tog_backdrop()
        }}
        // backdrop={"static"}
        // id="staticBackdrop"
      >
        <div
          className="modal-header"
          style={{
            display: "flex",
            // flexDirection: "column",
            // justifyContent: "flex-start",
            // alignItems: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <h5 className="modal-title" id="staticBackdropLabel">
              Reasons for Order Status Change
            </h5>
            <p className="modal-title" id="staticBackdropLabel">
              Status Change By User
            </p>
          </div>

          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_backdrop(false)
            }}
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <Selector
            data={reasonsOfStatus !== [] ? reasonsOfStatus : []}
            label="Select reasons"
            required
            onchange={event => {
              setSelectedReason(event.value)
            }}
          />
          <div className="mb-3">
            <Label className="form-label">Remark </Label>
            <Input
              name="remark"
              id="remark"
              className="form-control"
              placeholder="Enter Remark here ..."
              type="textarea"
              onChange={e => setRemark(e.target.value)}
            />
          </div>
        </div>
        <div className="modal-footer">
          {/* <button type="button" className="btn btn-light" onClick={() => {
                          setmodal_backdrop(false);
                        }}>Close</button> */}
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              if (selectedReason == null) {
                return toast.error(
                  "Please select a reason, Reason is required",
                  {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  }
                )
              }
              submitReason({
                data: selectedOrder,
                state: updateVal,
                orderStatus: selectedOrderStatus,
              })
              // submitReason()
            }}
          >
            Submit
          </button>
        </div>
      </Modal>
    </>
  )
}
FinalReportScreen.propTypes = {
  userData: PropTypes.object,
  editUser: PropTypes.any,
  history: PropTypes.any,
  data: PropTypes.any,
  dirData: PropTypes.object,
  delivered: PropTypes.bool,
  onClick: PropTypes.any,
  location: PropTypes.any,
}

const mapStateToProps = state => {
  return { userData: state.userData }
}

export default connect(mapStateToProps)(withRouter(FinalReportScreen))
