import React, { useState, useRef, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import LayoutModal from "../../components/Custom/LayoutModel"
import { userEdit } from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Loader from "components/Custom/Loader"
import Tostify from "components/Custom/Tostify"
import { BaseURL, ImageURL } from "config/BaseUrl"
import { toast } from "react-toastify"

function AddBrand(props) {
  const [files, setFiles] = useState(null)
  const [filesErr, setFilesErr] = useState("")
  const [brandName, setBrandName] = useState("")
  const [brandNameErr, setBrandNameErr] = useState("")
  const [showEditImage, setShowEditImage] = useState(true)
  const [loader, setLoader] = useState(false)

  const dispatch = useDispatch()

  // console.log(process.env)

  const fileRef = useRef()
  const nameRef = useRef()

  useEffect(() => {
    const data = props.editData

    // console.log("dataaaa",data)

    if (props.editData) {
      document.getElementById("name").value = data.name
      setBrandName(data.name)
      // setFiles(props.editData.file)
    } else {
      document.getElementById("name").value = ""
      setBrandName("")
    }

    return () => dispatch(userEdit(null))
  }, [props.editData])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      name: brandName || "",
      file: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Your Brand Name"),
      file: props.editData
        ? Yup.mixed()
        : Yup.mixed().required("Please select a file"),
    }),
    onSubmit: values => {
      console.log(values)

      if (files == null) {
        setBrandNameErr("Brand name required")
        nameRef.current.invalid = true
      }

      if (
        files !== null &&
        files.size > Number(process.env.REACT_APP_FILE_SIZE)
      ) {
        fileRef.current = null
        console.log("Hello")
        setFilesErr("File is required")
        toast.error("File size exceeds 2MB limit", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      } else {
        setLoader(true)
        var data = new FormData()
        data.append("name", values.name)
        files !== null && data.append("doc", files)

        var config = {
          method: props.editData ? "put" : "post",
          url: `${BaseURL}/brands${
            props.editData ? "/" + props.editData._id : ""
          }`,
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": props.userData.token,
          },
          data: data,
        }

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data))
            setLoader(false)
            document.getElementById("name").value = ""
            document.getElementById("file").value = ""
            setBrandName("")
            dispatch(userEdit(null))
            setFiles(null)
            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
          })
          .catch(function (error) {
            setLoader(false)
            toast.error(error.response && error.response.data.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            })
            console.log(error)
          })
      }
    },
  })

  const handleSubmit = () => {
    if (files == null) {
      setBrandNameErr("Brand name required")
      nameRef.current.invalid = true
    }

    if (
      files !== null &&
      files.size > Number(process.env.REACT_APP_FILE_SIZE)
    ) {
      fileRef.current = null
      console.log("Hello")
      setFilesErr("File is required")
      toast.error("File size exceeds 2MB limit", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    } else {
      setLoader(true)
      var data = new FormData()
      data.append("name", brandName)
      data.append("doc", files)

      var config = {
        method: props.editData ? "put" : "post",
        url: `${BaseURL}/brands${
          props.editData ? "/" + props.editData._id : ""
        }`,
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": props.userData.token,
        },
        data: data,
      }

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data))
          setLoader(false)
          document.getElementById("name").value = ""
          document.getElementById("file").value = ""
          setBrandName("")
          dispatch(userEdit(null))
          setFiles(null)
          toast.success(response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
        })
        .catch(function (error) {
          setLoader(false)
          toast.error(error.response && error.response.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          })
          console.log(error)
        })
    }

    // console.log(brandName ,props.editData.file.filename, files);
  }

  if (loader) {
    return <Loader />
  }

  return (
    <>
      <Tostify />
      <LayoutModal
        button={true}
        form={true}
        title={props.editData ? "Edit Brand" : "Add Brand"}
        btnTitle={props.editData ? "Add Brand" : "Brand List"}
        onclick={() => {
          props.editData
            ? dispatch(userEdit(null))
            : props.history.push("/brand-list")
        }}
      >
        <Form
          onSubmit={validation.handleSubmit}
          // onSubmit={ props.editData ? editUser  : submitAddUser}
        >
          <div className="mb-3">
            <Label className="form-label">
              Name <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              ref={nameRef}
              name="name"
              id="name"
              className="form-control"
              placeholder="Enter your Brand Name"
              type="text"
              // onChange={(e) => setBrandName(e.target.value)}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.name}
              invalid={
                validation.touched.name && validation.errors.name ? true : false
              }
            />
            {validation.touched.name && validation.errors.name ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </div>

          <div className="mb-3">
            <Label className="form-label">
              Select File (350 x 175) <span style={{ color: "red" }}>*</span>
            </Label>
            <Input
              name="file"
              id="file"
              className="form-control"
              // placeholder="Enter your new password"
              type="file"
              // onChange={(e) => setFiles(e.target.files[0])}
              onChange={e => {
                validation.handleChange(e)
                setFiles(e.target.files[0])
              }}
              onBlur={validation.handleBlur}
              ref={fileRef}
              value={validation.file || ""}
              invalid={
                (validation.touched.file &&
                  files !== null &&
                  files.size > Number(process.env.REACT_APP_FILE_SIZE)) ||
                validation.errors.file
                  ? true
                  : false
              }
            />

            {validation.touched.file &&
            files !== null &&
            files.size > Number(process.env.REACT_APP_FILE_SIZE) ? (
              <FormFeedback type="invalid">
                File must be less than 2 MB
              </FormFeedback>
            ) : null}

            {validation.touched.file && validation.errors.file ? (
              <FormFeedback type="invalid">
                {validation.errors.file}
              </FormFeedback>
            ) : null}
          </div>

          {files !== null && (
            <Card
              className="mt-1 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
              style={{
                backgroundColor:
                  files.size > Number(process.env.REACT_APP_FILE_SIZE)
                    ? "#ff6a6a"
                    : "#2FDD92",
                color:
                  files.size > Number(process.env.REACT_APP_FILE_SIZE)
                    ? "#ffffff"
                    : "#131313",
                width: "97%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <div className="p-2">
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <img
                      data-dz-thumbnail=""
                      height="80"
                      className="avatar-sm rounded bg-light"
                      style={{
                        objectFit: "cover",
                      }}
                      alt={files.name}
                      src={URL.createObjectURL(files)}
                    />
                  </Col>
                  <Col>
                    <Link
                      to="#"
                      // className="text-muted font-weight-bold"
                      style={{
                        color:
                          files.size > Number(process.env.REACT_APP_FILE_SIZE)
                            ? "#ffffff"
                            : "#131313",
                      }}
                    >
                      {files.name}
                    </Link>
                    <p className="mb-0">
                      <strong>
                        {(files.size / 1048576).toFixed(2) + " MB"}
                      </strong>
                    </p>
                    <i
                      className="bx bx-no-entry"
                      style={{
                        position: "absolute",
                        top: 0,
                        right: 10,
                        color:
                          files.size > Number(process.env.REACT_APP_FILE_SIZE)
                            ? "#ffffff"
                            : "#131313",
                        fontSize: 17,
                        fontWeight: 800,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setFiles(null)
                        document.getElementById("file").value = ""
                      }}
                    ></i>
                  </Col>
                </Row>
              </div>
            </Card>
          )}

          {props.editData &&
            props.editData.file &&
            showEditImage &&
            files == null && (
              <Card
                className="mt-1 mb-10 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                style={{
                  backgroundColor:
                    props.editData.file.fileSize >
                    Number(process.env.REACT_APP_FILE_SIZE)
                      ? "#ff6a6a"
                      : "#2FDD92",
                  color:
                    props.editData.file.fileSize >
                    Number(process.env.REACT_APP_FILE_SIZE)
                      ? "#ffffff"
                      : "#131313",
                  width: "97%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <img
                        data-dz-thumbnail=""
                        height="80"
                        className="avatar-sm rounded bg-light"
                        style={{
                          objectFit: "cover",
                        }}
                        alt={props.editData.file.originalname}
                        // alt={files.name}
                        src={`${ImageURL}/brands/${props.editData.file.filename}`}
                      />
                    </Col>
                    <Col>
                      <Link
                        to="#"
                        // className="text-muted font-weight-bold"
                        style={{
                          color:
                            props.editData.file.fileSize >
                            Number(process.env.REACT_APP_FILE_SIZE)
                              ? "#ffffff"
                              : "#131313",
                        }}
                      >
                        {props.editData.file.originalname}
                      </Link>
                      <p className="mb-0">
                        <strong>
                          {(props.editData.file.fileSize / 1024).toFixed(2) +
                            " MB"}
                        </strong>
                      </p>
                      <i
                        className="bx bx-no-entry"
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 10,
                          color:
                            props.editData.file.fileSize >
                            Number(process.env.REACT_APP_FILE_SIZE)
                              ? "#ffffff"
                              : "#131313",
                          fontSize: 17,
                          fontWeight: 800,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowEditImage(false)
                        }}
                      ></i>
                    </Col>
                  </Row>
                </div>
              </Card>
            )}

          <Row className="mb-3">
            <Col className="text-start">
              <button
                className="btn btn-primary w-md "
                type="submit"
                // onClick={() => handleSubmit()}
              >
                Submit
              </button>
            </Col>
          </Row>
        </Form>
      </LayoutModal>
    </>
  )
}

AddBrand.propTypes = {
  userData: PropTypes.object,
  editData: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(AddBrand))
