import React, { useState, useEffect } from "react"
import LayoutModal from "../../components/Custom/LayoutModel"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import "../user/datatables.scss"

import { connect } from "react-redux"
import PropTypes from "prop-types"

import { Row, Col } from "../imports/import"

import axios from "axios"

import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
  Modal,
  Label,
  Input,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"

import { userEdit } from "../../store/actions"
import { withRouter } from "react-router-dom"

import Loader from "components/Custom/Loader"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import { BaseURL } from "config/BaseUrl"
import moment from "moment"
import Selector from "components/Custom/Selector"
import SecondaryTable from "pages/orders/SecondaryTable"

function OrderList(props) {
  const dispatch = useDispatch()

  const [orderList, setOrderList] = useState([])
  const [sizePerPage, setSizePerPage] = useState(10)
  const [activeTooltip, setActiveTooltip] = useState(false)
  const [rowIndexTable, setRowIndexTable] = useState()
  const [loader, setLoader] = useState(false)

  const [orderListStatus, setOrderListStatus] = useState([])
  const [oldOrderListStatus, setOldOrderListStatus] = useState([])

  const [selectedOrderStatus, setSelectedOrderStatus] = useState()

  const [reasonsOfStatus, setReasonsOfStatus] = useState([])
  const [selectedReason, setSelectedReason] = useState(null)
  const [selectedOrder, setSelectedOrder] = useState(null)

  const [modal_backdrop, setmodal_backdrop] = useState(false)

  const [remark, setRemark] = useState("")

  const [updateVal, setUpdateVal] = useState("")
  const [modal_xlarge, setmodal_xlarge] = useState(false)

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseURL}/orderstatuses`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        console.log(response.data, "Order Status")
        setOrderListStatus(response.data)
        setOldOrderListStatus(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    // var data = JSON.stringify({
    //   "status": "Pending"
    // });

    if (!modal_backdrop) {
      var config = {
        method: "post",
        url: `${BaseURL}/orders/pending`,
        headers: {
          "x-auth-token": props.userData.token,
        },
        // data : data
      }

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data))

          setOrderList(response.data)
        })
        .catch(function (error) {
          console.log(error)
        })
    }
  }, [modal_backdrop])

  const editUserData = ({ row, type }) => {
    console.log(row, type, "EDIT")
    try {
      localStorage.setItem("editData", JSON.stringify(row))
    } catch {
      console.log("user list editData localStorage fail")
    }

    try {
      dispatch(userEdit(row))

      if (type == "edit") {
        props.history.push("/add-order")
      } else {
        props.history.push("/order-delivery")
      }
    } catch (e) {
      console.log("user list redux editdata  fail")
    }
  }

  function tog_backdrop() {
    setmodal_backdrop(!modal_backdrop)
    // removeBodyCss()
  }

  const submitReason = ({ data, state, orderStatus }) => {
    const id = data._id

    setLoader(true)

    var data = JSON.stringify({
      orderStatus: orderStatus,
      reason: selectedReason ? selectedReason : null,
      remarks: remark ? remark : null,
    })

    var config = {
      method: "put",
      url: `${BaseURL}/orders/${state}/${id ? id : selectedOrder._id}`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        // setOrderList([])
        console.log(JSON.stringify(response.data))
        setLoader(false)
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })

        // var data = JSON.stringify({
        //   "status": "Pending"
        // });

        var config = {
          method: "post",
          url: `${BaseURL}/orders/pending`,
          headers: {
            "x-auth-token": props.userData.token,
          },
          // data : data
        }

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data))
            setOrderList([])
            setTimeout(() => {
              setOrderList(response.data)
            }, 50)
          })
          .catch(function (error) {
            console.log(error)
          })

        // let dataOfOrderList = orderList
        // let i = dataOfOrderList.indexOf(selectedOrder)

        // dataOfOrderList[i].orderStatus = response.data.order_status

        // console.log(dataOfOrderList , "order data changed")
        // setOrderList([])

        //   setOrderList([...dataOfOrderList])

        setmodal_backdrop(false)
      })
      .catch(function (error) {
        console.log(error)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        setLoader(false)
      })
  }

  // const changeStatus = (data) => {
  // //   console.log(data, userList[userList.indexOf(data)] ,userList.indexOf(data)  , "STATUS DATA")
  //   setRowIndexTable(rowIndexTable - 1)
  //   setLoader(true)

  // //   var dataa = JSON.stringify({
  // //     "status": !data.isActive
  // //   });

  //   var config = {
  //     method: 'put',
  //     url: `${BaseURL}/items/${data._id}/${data.isActive ? "0" : "1"}`,
  //     headers: {
  //       'x-auth-token': props.userData.token,
  //       'Content-Type': 'application/json'
  //     },
  //     // data : dataa
  //   };

  //   axios(config)
  //   .then(function (response) {
  //     console.log(JSON.stringify(response.data));
  //     setLoader(false)
  //     let values = orderList;
  //     values[orderList.indexOf(data)].isActive = !data.isActive;
  //     setOrderList([...values]);
  //     toast.success(response.data.message, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       });
  //   })
  //   .catch(function (error) {
  //     console.log(error);
  //     setLoader(false)
  //     toast.error(error.response && error.response.data.message, {
  //       position: "top-right",
  //       autoClose: 5000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,

  //       });
  //   });

  // }

  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge)
  }

  const columns = [
    {
      dataField: "seq",
      text: "Order Id",
      sort: true,
    },
    {
      dataField: "party.partyName",
      text: "Party",
      sort: true,
    },
    {
      dataField: "party.city.name",
      text: "City",
      sort: true,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        return (
          <div
            style={{
              width: 100,
              wordBreak: "break-all",
              overflowWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            <p>{moment(cellContent).format("DD/MM/YYYY")}</p>
          </div>
        )
      },
    },
    {
      dataField: "dummy",
      isDummyField: true,
      text: "Status",
      // sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        let Values = []
        if (row.orderStatus !== null && row.orderStatus.name !== "Pending") {
          // let data = [];
          orderListStatus.map((d, i) => {
            if (
              d._id !== "627c912d4b4e048680dfadde" &&
              d._id !== "627ce2404b4e048680dfae13" &&
              d._id !== "627ce24e4b4e048680dfae14"
            ) {
              Values.push(d)
              console.log(d, "DDDDDD")
            }
          })
        } else {
          orderListStatus.map((d, i) => {
            if (
              d._id !== "627ce2404b4e048680dfae13" &&
              d._id !== "627ce24e4b4e048680dfae14"
            ) {
              Values.push(d)
              console.log(d, "DDDDDD")
            }
          })
          // Values = oldOrderListStatus
        }
        return (
          <div style={{ position: "relative" }}>
            <Selector
              data={Values !== [] ? Values : []}
              onchange={event => {
                if (
                  row.orderStatus !== null &&
                  row.orderStatus._id !== event.value
                ) {
                  setSelectedOrder(row)
                  setSelectedOrderStatus(event.value)

                  Values.map((d, i) => {
                    if (d._id === event.value && d.reasons.length > 0) {
                      console.log(d, "Values Data")
                      setReasonsOfStatus(d.reasons)
                      setUpdateVal("status")
                      setmodal_backdrop(true)
                    } else if (d._id === event.value) {
                      setUpdateVal("status")
                      setSelectedOrder(row)
                      console.log(
                        row,
                        updateVal,
                        selectedOrder,
                        "ROOOOOOOOOOOOOOOOOOOOOOOOOOOO"
                      )
                      submitReason({
                        data: row,
                        state: "status",
                        orderStatus: d._id,
                      })
                    }
                  })
                }
              }}
              edit={
                row.orderStatus !== null && {
                  label: row.orderStatus.name,
                  value: row.orderStatus._id,
                }
              }
            />
          </div>
        )
      },
    },
    {
      dataField: "dummy01",
      text: "Action",
      isDummyField: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              size="sm"
              type="button"
              className="btn btn-warning"
              onClick={() => editUserData({ row, type: "edit" })}
            >
              {/* <i className="bx bxs-pencil font-size-15 "></i> */}
              Edit
            </Button>

            {/* <Button
              size="sm"
              type="button"
              style={{marginLeft:10}}
              className="btn btn-success"
              onClick={() => {

                  setSelectedOrder(row)
                  // setmodal_backdrop(true);
                  setUpdateVal("complete")
                  orderListStatus.map((d,i) => {
                    if(d.name == "Completed"){
                      setSelectedOrderStatus(d._id)
                   
                      setReasonsOfStatus(d.reasons)
                      if(d.reasons.length > 0){
                        setmodal_backdrop(true);
                      }else{
                        setUpdateVal("complete")
                        setSelectedOrder(row)
                        console.log(row, updateVal,selectedOrder ,"ROOOOOOOOOOOOOOOOOOOOOOOOOOOO")
                        submitReason({data:row, state:"complete",orderStatus:d._id})
                      }
                    }
                  })
             
                
              }}
                  >
              
              Complete
          
              </Button> */}

            <Button
              size="sm"
              type="button"
              style={{ marginLeft: 10 }}
              className="btn btn-danger"
              onClick={() => {
                setSelectedOrder(row)
                setUpdateVal("delete")
                setmodal_backdrop(true)
                orderListStatus.map((d, i) => {
                  if (d.name == "Deleted") {
                    setSelectedOrderStatus(d._id)
                    setReasonsOfStatus(d.reasons)
                    console.log(d, "Deleted DATA")
                    if (d.reasons.length > 0) {
                      setmodal_backdrop(true)
                    } else {
                      setUpdateVal("delete")
                      setSelectedOrder(row)
                      // console.log(row, updateVal,selectedOrder ,"ROOOOOOOOOOOOOOOOOOOOOOOOOOOO")
                      submitReason({
                        data: row,
                        state: "delete",
                        orderStatus: d._id,
                      })
                    }
                  }
                })
              }}
            >
              {/* <i className="bx bxs-pencil font-size-15 "></i> */}
              Delete
            </Button>

            <Button
              size="sm"
              type="button"
              style={{ marginLeft: 10 }}
              className="btn btn-info"
              onClick={() => editUserData({ row, type: "deliver" })}
            >
              {/* <i className="bx bxs-pencil font-size-15 "></i> */}
              Deliver
            </Button>

            <Button
              size="sm"
              type="button"
              style={{ marginLeft: 10 }}
              className="btn btn-link"
              color="secondary"
              onClick={() => setmodal_xlarge(true)}
            >
              {/* <i className="bx bxs-pencil font-size-15 "></i> */}
              Item List
            </Button>

            {rowIndex === rowIndexTable && modal_xlarge && (
              <Modal
                size="xl"
                isOpen={modal_xlarge}
                toggle={() => {
                  tog_xlarge()
                }}
              >
                <div className="modal-header">
                  <SecondaryTable
                    data={row}
                    dirData={row}
                    onClick={() => setmodal_xlarge(!modal_xlarge)}
                  />
                </div>
              </Modal>
            )}
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        console.log(option, currSizePerPage, "OP")
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPageRenderer,
    sizePerPage: 50,
    totalSize: orderList.length, // replace later with size(customers),
    // custom: true,
  }

  const { SearchBar } = Search

  if (loader) {
    return <Loader />
  }

  return (
    <>
      <Tostify />
      <LayoutModal title="Pending Orders" sideTitle="User" button={false}>
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="_id"
          columns={columns}
          data={orderList}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="_id"
              columns={columns}
              data={orderList}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12">
                      <div className="table-responsive-sm table-responsive-md table-responsive-lg">
                        <BootstrapTable
                          // keyField={"email"}

                          // responsive
                          bordered={false}
                          striped={true}
                          // defaultSorted={defaultSorted}
                          hover
                          // selectRow={selectRow}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                     
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row> */}
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </LayoutModal>

      <Modal
        isOpen={modal_backdrop}
        toggle={() => {
          tog_backdrop()
        }}
        // backdrop={"static"}
        // id="staticBackdrop"
      >
        <div
          className="modal-header"
          style={{
            display: "flex",
            // flexDirection: "column",
            // justifyContent: "flex-start",
            // alignItems: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <h5 className="modal-title" id="staticBackdropLabel">
              Reasons for Order Status Change
            </h5>
            <p className="modal-title" id="staticBackdropLabel">
              Status Change By User
            </p>
          </div>

          <button
            type="button"
            className="btn-close"
            onClick={() => {
              setmodal_backdrop(false)
            }}
            aria-label="Close"
          ></button>
        </div>

        <div className="modal-body">
          <Selector
            data={reasonsOfStatus !== [] ? reasonsOfStatus : []}
            label="Select reasons"
            required
            onchange={event => {
              setSelectedReason(event.value)
            }}
          />
          <div className="mb-3">
            <Label className="form-label">Remark </Label>
            <Input
              name="remark"
              id="remark"
              className="form-control"
              placeholder="Enter Remark here ..."
              type="textarea"
              onChange={e => setRemark(e.target.value)}
            />
          </div>
        </div>
        <div className="modal-footer">
          {/* <button type="button" className="btn btn-light" onClick={() => {
                          setmodal_backdrop(false);
                        }}>Close</button> */}
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              if (selectedReason == null) {
                return toast.error(
                  "Please select a reason, Reason is required",
                  {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                  }
                )
              }
              submitReason({
                data: selectedOrder,
                state: updateVal,
                orderStatus: selectedOrderStatus,
              })
              // submitReason()
            }}
          >
            Submit
          </button>
        </div>
      </Modal>
    </>
  )
}
OrderList.propTypes = {
  userData: PropTypes.object,
  editUser: PropTypes.any,
  history: PropTypes.any,
}

const mapStateToProps = state => {
  return { userData: state.userData }
}

export default connect(mapStateToProps)(withRouter(OrderList))
