import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem ,Button } from "reactstrap"

const Breadcrumb = props => {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{props.breadcrumbItem}</h4>
          <div className="page-title-right"
          style={{ 
            display: 'flex'
          }}
          >
            {props.button &&
            <ol className="breadcrumb m-0">
        
            <button
                 type="button"
                 className="btn btn-primary"
                 style={{
                   marginLeft:20,
                 }}

                 onClick={props.onClick}
             
                    >
                {props.buttonTitle === "Add User" ? <i className="bx bxs-user font-size-16 align-middle me-2"></i> : <i className="bx bxs-user-detail font-size-16 align-middle me-2"></i>} {" "}
                 {props.buttonTitle}
               </button>
            
            </ol>}
            {props.editOrder &&
            <ol className="breadcrumb m-0">
        
            <button
                 type="button"
                 className="btn btn-primary"
                 style={{
                   marginLeft:20,
                 }}

                 onClick={props.onClickOfEdit}
             
                    >
                {props.buttonTitle === "Add User" ? <i className="bx bxs-pencil font-size-16 align-middle me-2"></i> : <i className="bx bxs-pencil font-size-16 align-middle me-2"></i>} {" "}
                 {props.editOrder}
               </button>
            
            </ol>}
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  buttonTitle: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.any,
  showBtn:PropTypes.bool,
  button:PropTypes.bool,
  editOrder:PropTypes.any,
  onClickOfEdit:PropTypes.any,
}

export default Breadcrumb
