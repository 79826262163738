import React, { useState, useRef, useEffect } from "react"
import axios from "axios"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import LayoutModal from "../../components/Custom/LayoutModel"
import { userEdit } from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"
import { Link, withRouter } from "react-router-dom"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Loader from "components/Custom/Loader"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import "../user/datatables.scss"
import { BaseURL } from "config/BaseUrl"

function AddCity(props) {
  const [city, setCity] = useState("")
  const [cityList, setCityList] = useState([])
  const [editCity, setEditCity] = useState(null)
  // const [brandNameErr , setBrandNameErr] = useState("")
  const [rowIndexTable, setRowIndexTable] = useState()
  const [loader, setLoader] = useState(false)

  const dispatch = useDispatch()

  // console.log(process.env)

  const nameRef = useRef()

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseURL}/states/0`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        setCityList(response.data)
      })
      .catch(function (error) {
        console.log(error)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }, [])

  // useEffect(() => {

  //   const data = props.editData;

  //   console.log("dataaaa",data)

  //   if(props.editData){

  //     document.getElementById("name").value = data.name
  //     setCity(data.name)

  //   }else{
  //     document.getElementById("name").value =""
  //     setCity("")
  //   }

  //   // return () => dispatch(userEdit(null))

  // },[props.editData])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      city: "",
    },
    validationSchema: Yup.object({
      city: Yup.string().required("Please Enter city name"),
    }),
  })

  const handleSubmit = () => {
    setLoader(true)

    var data = JSON.stringify({
      name: city,
    })

    var config = {
      method: editCity !== null ? "put" : "post",
      url: `${BaseURL}/states${editCity !== null ? "/" + editCity._id : ""}`,
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": props.userData.token,
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        setLoader(false)
        let values = cityList

        if (editCity !== null) {
          let index = cityList.indexOf(editCity)
          values.splice(index, 1, {
            name: city,
            isActive: editCity.isActive,
            _id: response.data.id,
          })
          setCityList([])
          setTimeout(() => {
            setCityList([...values])
          }, 100)
        } else {
          values.push({ name: city, isActive: true, _id: response.data.id })
          setCityList([])
          setTimeout(() => {
            setCityList([...values])
          }, 100)
        }

        document.getElementById("city").value = ""
        setCity("")
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
      .catch(function (error) {
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        console.log(error)
      })
  }

  const changeStatus = data => {
    //   console.log(data, userList[userList.indexOf(data)] ,userList.indexOf(data)  , "STATUS DATA")
    setRowIndexTable(rowIndexTable - 1)

    setLoader(true)
    //   var dataa = JSON.stringify({
    //     "status": !data.isActive
    //   });

    var config = {
      method: "put",
      url: `${BaseURL}/states/${data._id}/${data.isActive ? "0" : "1"}`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      // data : dataa
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        setLoader(false)
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        let values = cityList
        values[cityList.indexOf(data)].isActive = !data.isActive
        setCityList([...values])
      })
      .catch(function (error) {
        console.log(error)
        setLoader(false)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }

  // const editCity = (data) => {
  //     var data = JSON.stringify({
  //         "name": city
  //       });

  //       var config = {
  //         method: 'put',
  //         url: `http://192.168.29.192:4000/api/cities/${data._id}`,
  //         headers: {
  //           'x-auth-token': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MWNlZjlmYmE2YTM5MDc0MDgyY2RjMjEiLCJuYW1lIjoiQ2hhcm1pIiwiaXNBZG1pbiI6dHJ1ZSwiaWF0IjoxNjQxNTUxNDEzfQ.9GHBgIuSaa3Cn-2HNQqSJVNRSIgrrAdRWMjMpGbHiyg',
  //           'Content-Type': 'application/json'
  //         },
  //         data : data
  //       };

  //       axios(config)
  //       .then(function (response) {
  //         console.log(JSON.stringify(response.data));
  //         setLoader(false)
  //         toast.success(response.data.message, {
  //             position: "top-right",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             });
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //         setLoader(false)
  //         toast.error(error.response && error.response.data.message, {
  //             position: "top-right",
  //             autoClose: 5000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,

  //             });
  //       });

  // }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "isActive",
      text: "Status",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        if (row.isActive) {
          return (
            <>
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable && "PopoverLegacy"}
                className="btn btn-success"
              >
                Active
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you Sure, You want to Deactivate this State ?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      style={{
                        marginRight: 5,
                      }}
                      size="sm"
                      onClick={() => changeStatus(row)}
                      color="success"
                    >
                      Yes
                    </Button>
                    <Button
                      style={{
                        marginLeft: 5,
                      }}
                      size="sm"
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        } else {
          return (
            <>
              {" "}
              <Button
                size="sm"
                type="button"
                id={rowIndex === rowIndexTable && "PopoverLegacy"}
                className="btn btn-danger"
                onClick={() =>
                  console.log(cellContent, row, "DATATATATAATATATATAT")
                }
              >
                Deactive
              </Button>
              {rowIndex === rowIndexTable && (
                <UncontrolledPopover
                  placement="top"
                  target="PopoverLegacy"
                  trigger="legacy"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <PopoverHeader style={{ textAlign: "center" }}>
                    Are you Sure, You want to Activate this State ?
                  </PopoverHeader>
                  <PopoverBody>
                    <Button
                      size="sm"
                      style={{
                        marginRight: 5,
                      }}
                      onClick={() => changeStatus(row)}
                      color="success"
                    >
                      Yes
                    </Button>
                    <Button
                      size="sm"
                      style={{
                        marginLeft: 5,
                      }}
                      onClick={() => setRowIndexTable(rowIndexTable - 1)}
                      color="danger"
                    >
                      No
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              )}
            </>
          )
        }
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          // console.log(rowIndex,"clicked")
          rowIndex !== rowIndexTable && setRowIndexTable(rowIndex)
        },
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
    {
      dataField: "df1",
      isDummyField: true,
      text: "Action",
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              size="sm"
              type="button"
              className="btn btn-warning"
              onClick={() => {
                setCity(row.name)
                setEditCity(row)
              }}
            >
              {/* <i className="bx bxs-pencil font-size-15 "></i> */}
              Edit
            </Button>
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPageRenderer,
    sizePerPage: 50,
    totalSize: cityList.length, // replace later with size(customers),
    // custom: true,
  }

  const { SearchBar } = Search

  // console.log(brandName ,props.editData.file.filename, files);

  if (loader) {
    return <Loader />
  }

  return (
    <>
      <Tostify />
      <div className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row">
        <div className="col-sm-12 col-lg-4">
          <LayoutModal
            button={false}
            //  form={true}
            title={props.editData ? "Edit State" : "Add State"}
            btnTitle={props.editData ? "Add State" : "State List"}
            //  onclick={() => {props.editData ? dispatch(userEdit(null)) : props.history.push("/brand-list")}}
          >
            <div className="mb-3">
              <Label className="form-label">State</Label>
              <Input
                ref={nameRef}
                name="city"
                id="city"
                className="form-control"
                placeholder="Enter your Brand Name"
                type="text"
                onChange={e => setCity(e.target.value)}
                onBlur={validation.handleBlur}
                value={city || ""}
                // invalid={
                //   validation.touched.name && brandName === "" ? true : false
                // }
              />
              <FormFeedback type="invalid">City is required</FormFeedback>
              {/* {validation.touched.name && brandName == "" ? (
                          <FormFeedback type="invalid">Brand Name is required</FormFeedback>
                        ) : null} */}
            </div>
            <Row className="mb-3">
              <Col className="text-start">
                <button
                  className="btn btn-primary w-md "
                  type="button"
                  onClick={() => handleSubmit()}
                >
                  Submit
                </button>
              </Col>
            </Row>
          </LayoutModal>
        </div>
        <div className="col-sm-12 col-lg-8">
          <LayoutModal title="State List" sideTitle="City" button={false}>
            <PaginationProvider
              pagination={paginationFactory(pageOptions)}
              keyField="name"
              columns={columns}
              data={cityList}
            >
              {({ paginationProps, paginationTableProps }) => (
                <ToolkitProvider
                  keyField="name"
                  columns={columns}
                  data={cityList}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="mb-2">
                        <Col md="4">
                          <div className="search-box me-2 mb-2 d-inline-block">
                            <div className="position-relative">
                              <SearchBar {...toolkitProps.searchProps} />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl="12">
                          <div className="table-responsive">
                            <BootstrapTable
                              // keyField={"email"}

                              responsive
                              bordered={false}
                              striped={true}
                              // defaultSorted={defaultSorted}
                              hover
                              // selectRow={selectRow}
                              classes={"table align-middle table-nowrap"}
                              headerWrapperClasses={"thead-light"}
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                            />
                          </div>
                        </Col>
                      </Row>

                      {/* <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                     
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row> */}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              )}
            </PaginationProvider>
          </LayoutModal>
        </div>
      </div>
    </>
  )
}

AddCity.propTypes = {
  userData: PropTypes.object,
  editData: PropTypes.object,
  history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(AddCity))
