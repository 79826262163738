import React, { useState, useEffect } from "react"
import LayoutModal from "../../../components/Custom/LayoutModel"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"

// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import "../datatables.scss"

import { connect } from "react-redux"
import Tostify from "components/Custom/Tostify"
import PropTypes from "prop-types"
import { BaseURL } from "config/BaseUrl"
import { Link, withRouter, Route } from "react-router-dom"

import { Row, Col } from "../../imports/import"

import axios from "axios"

import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
  Modal,
  CardBody,
  Card,
  Container,
  Label,
  Input,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"

import { userEdit } from "../../../store/actions"

import { toast } from "react-toastify"
import Loader from "components/Custom/Loader"
import moment from "moment"

function PartyReportScreen1(props) {
  const dispatch = useDispatch()

  const Id = props.location.state.data._id
  const CityName = props.location.state.data.city.name
  const partyName = props.location.state.data.partyName
  const [itemData, setItemsData] = useState("")
  const [sizePerPage, setSizePerPage] = useState(10)
  const [activeTooltip, setActiveTooltip] = useState(false)
  const [rowIndexTable, setRowIndexTable] = useState()
  const [loader, setLoader] = useState(false)
  const [modal_xlarge, setmodal_xlarge] = useState(false)

  // console.log(props.location, "DIRECTORY DATA")

  useEffect(() => {
    var config = {
      method: "get",
      url: BaseURL + "/partywiseitem/pendingItems/" + Id,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        setItemsData(response.data.items)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])
  const columns = [
    {
      dataField: "name",
      text: "Item Name",
      sort: true,
    },
    {
      dataField: "PendingQty",
      text: "Pending Quantity",
      sort: true,
    },
    {
      dataField: "dummy",
      isDummyField: true,
      text: "Action",
      sort: true,
      // hidden: props.delivered ? true : false,
      formatter: (cellContent, row, rowIndex, field) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Link
              to={{
                pathname: "final-report-screen",
                state: {
                  data: row,
                  partyId: Id,
                  cityName: CityName,
                  partyName: partyName,
                },
              }}
            >
              <Button
                size="sm"
                type="button"
                className="btn btn-link"
                color="secondary"
              >
                Details
              </Button>
            </Link>
          </div>
        )
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPageRenderer,
    sizePerPage: 50,
    totalSize: itemData.length, // replace later with size(customers),
    // custom: true,
  }

  const { SearchBar } = Search

  if (loader) {
    return <Loader />
  }

  return (
    <>
      <Tostify />
      <LayoutModal
        title="Party Wise Item - Pending Order"
        sideTitle="User"
        button={false}
      >
        <div className="col-7 row mb-3">
          <div className="col-6">
            <Label className="form-label">
              Party Name: {props.location.state.data.partyName}
            </Label>
          </div>

          <div className="col-6">
            <Label className="form-label">
              City:{props.location.state.data.city.name}
            </Label>
          </div>
        </div>
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="id"
          columns={columns}
          data={itemData}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={columns}
              data={itemData}
              //   search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="7">
                      <div className="table-responsive">
                        <BootstrapTable
                          // keyField={"email"}

                          responsive
                          bordered={false}
                          striped={true}
                          // defaultSorted={defaultSorted}
                          hover
                          // selectRow={selectRow}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                   
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row> */}
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </LayoutModal>
    </>
  )
}
PartyReportScreen1.propTypes = {
  userData: PropTypes.object,
  editUser: PropTypes.any,
  history: PropTypes.any,
  data: PropTypes.any,
  dirData: PropTypes.object,
  delivered: PropTypes.bool,
  onClick: PropTypes.any,
  location: PropTypes.any,
}

const mapStateToProps = state => {
  return { userData: state.userData }
}

export default connect(mapStateToProps)(withRouter(PartyReportScreen1))
