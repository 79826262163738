import React, { useState, useEffect, useRef, useCallback } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import LayoutModal from "../../components/Custom/LayoutModel"
import { connect } from "react-redux"
import { Switch, withRouter } from "react-router-dom"
import moment from "moment"
import Selector from "components/Custom/Selector"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import Loader from "components/Custom/Loader"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  CardTitle,
  Button,
  Modal,
} from "reactstrap"
// action
import { userEdit, userForgetPassword } from "../../store/actions"
import { BaseURL } from "config/BaseUrl"
import { LocalizationProvider } from "@mui/lab"
import MobileDatePicker from "@mui/lab/MobileDatePicker"
import MomentUtils from "@date-io/moment"
import { TextField } from "@mui/material"

function OrderDeliver(props) {
  const dispatch = useDispatch()

  const [items, setItems] = useState([])

  const [itemsSelector, setItemsSelector] = useState([])
  const [selectItem, setSelectItem] = useState(null)

  const [multipleItems, setMultipleItems] = useState([
    {
      item: "",
      quantity: "",
      unit: "",
      discountedPrice: "",
      total: "",
      price: "",
      discount: "",
      deliveredQty: "",
      totalQuantity: "",
    },
  ])

  const accessItems = new Map()

  const [units, setUnits] = useState("")
  const [itemData, setItemData] = useState(null)
  const [party, setPartyList] = useState([])
  const [selectedParty, setSelectedParty] = useState(null)
  const [index, setIndex] = useState("")
  const [date, setDate] = useState(new Date())
  const [partyName, setPartyName] = useState("")
  const [total, setTotal] = useState(0)
  const [remark, setRemark] = useState("")

  const [loader, setLoader] = useState(false)

  console.log(props.editData, "EDIT DATA")

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseURL}/formdata/item`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        setItems(response.data)
        // response.data.map((d,i) => {
        //     let data = itemsSelector
        //     let label = d.name;
        //     let value = d._id;
        //     data.push({label,value})
        //     setItemsSelector([...data])
        // })
      })
      .catch(function (error) {
        console.log(error)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }, [])

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseURL}/formdata/party`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        setPartyList(response.data)
      })
      .catch(function (error) {
        console.log(error)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      })
  }, [])

  useEffect(() => {
    if (items.length > 0 && selectItem == null) {
      // setItemData(null)
      items.map((d, i) => {
        accessItems.set(d._id, d)
      })
      setItemData(accessItems)
      console.log(accessItems, "MAP ITEMS")
    }
  }, [items.length, selectItem])

  useEffect(() => {
    if (selectItem !== null) {
      let getData = itemData.get(selectItem)
      multipleItems[index].discountedPrice = getData.discountedPrice
      multipleItems[index].unit = getData.unit.name
      multipleItems[index].price = getData.price
      multipleItems[index].discount = getData.discount
      multipleItems[index].deliveredQty = getData.deliveredQty
      // console.log(selectItem,multipleItems,index,itemData);
      setMultipleItems([...multipleItems])
      setSelectItem(null)
    }
  }, [selectItem, items.length])

  useEffect(() => {
    if (props.editData) {
      setRemark(props.editData.remarks)

      let dummyArr = []
      let num = 0

      setSelectedParty(props.editData.party._id)

      props.editData.items.map((d, i) => {
        let dummyObj = {}
        dummyObj.item = d.item
        dummyObj.quantity = Number(d.quantity) - Number(d.deliveredQty)
        dummyObj.unit = d.item.unit.name
        dummyObj.discountedPrice = d.discountedPrice
        dummyObj.total = 0
        dummyObj.price = d.price
        dummyObj.discount = d.discount
        dummyObj.deliveredQty = "0"
        dummyObj.totalQuantity = Number(d.quantity)
        // let getData = itemData.get(d.item._id)
        //   console.log(dummyObj,"Dummy")
        num += Number(d.total)
        dummyArr.push(dummyObj)
      })
      setMultipleItems([])
      setTimeout(() => {
        setMultipleItems([...dummyArr])
      }, 100)
      setTotal(0)
      console.log(dummyArr, "DUMMY")
    } else {
      props.history.push("/pending-orders")
    }

    //   return () =>  {dispatch(userEdit(null))}
  }, [props.editData])

  // const onAddFormRow = () => {
  //     const modifiedRows = [...multipleItems];
  //     console.log(modifiedRows , "Value")
  //     modifiedRows.push({item:"" , quantity:"" , unit:"" , discountedPrice:"" , total:"", price:"" ,discount:""});
  //     setMultipleItems(modifiedRows);
  //   };

  // const onDeleteFormRow = index => {

  //       var modifiedRows = [...multipleItems];
  //       console.log(modifiedRows , "Value")
  //       modifiedRows.splice(index, 1);
  //       let num = 0
  //       modifiedRows.map((d,i) => {
  //         num += Number(d.total)
  //       })
  //       setTotal(num)
  //       setMultipleItems(modifiedRows);

  // };

  const onSubmit = e => {
    e.preventDefault()
    // console.log(e.target.value,"SUBMIT")

    const dataArr = []

    let isCompleted = true

    if (selectedParty === null) {
      return toast.error("Please select a Party, Party name is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }

    let deliverQTY = false

    multipleItems.map((d, i) => {
      let num = Number(d.quantity) - Number(d.deliveredQty)
      // let remain = Number(d.totalQuantity) - num

      if (Number(d.deliveredQty > 0)) {
        dataArr.push(d)
      }

      if (num <= 0) {
        isCompleted = isCompleted && true
      } else {
        isCompleted = isCompleted && false
      }

      if (d.item === "") {
        return toast.error("Please select item, Item is required", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }

      if (d.deliveredQty == "0") {
        console.log(d, "qqqqqqqqqq")
        deliverQTY = deliverQTY || false
      } else {
        deliverQTY = deliverQTY || true
      }
    })

    if (!deliverQTY) {
      return toast.error(
        "Please enter deliver quantity , deliver quantity is required",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      )
    }

    console.log(isCompleted, "CCCCCCCC")
    setLoader(true)

    var data = JSON.stringify({
      party: props.editData.party._id,
      date: date,
      grandTotal: total,
      remarks: remark,
      items: dataArr,
      orderByParty: 0,
      isCompleted: isCompleted,
    })

    var config = {
      method: props.editData ? "put" : "post",
      url: `${BaseURL}/orders${
        props.editData ? "/deliver/" + props.editData._id : ""
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        setLoader(false)
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        setTimeout(() => {
          props.history.push("/pending-orders")
        }, 2000)
      })
      .catch(function (error) {
        console.log(error)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        setLoader(false)
      })
  }

  if (loader) {
    return <Loader />
  }

  return (
    <div
      // className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row"
      style={{ flex: 1, minHeight: "100vh" }}
    >
      <Tostify />
      <LayoutModal
        button={true}
        // form={true}
        title={
          props.editData ? `Deliver Order #${props.editData.seq}` : "Add Order"
        }
        btnTitle={props.editData && "Order List"}
        editOrder="Edit Order"
        onClickOfEdit={() => props.history.push("/add-order")}
        onclick={() => {
          if (props.editData) {
            dispatch(userEdit(null))
            setLoader(true)
            window.location.reload()
            setTimeout(() => setLoader(false), 300)
          } else {
            props.history.push("/pending-orders")
          }
        }}
      >
        <Row>
          <Col xs={12}>
            <CardBody>
              <Form
                className="repeater"
                encType="multipart/form-data"
                onSubmit={onSubmit}
              >
                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      <Label className="form-label">
                        Party Name <span style={{ color: "red" }}>*</span>
                      </Label>
                      <Input
                        name="qty"
                        id="qty"
                        className="form-control"
                        placeholder={
                          props.editData &&
                          props.editData.party &&
                          props.editData.party.partyName
                        }
                        disabled
                        type="text"
                      />
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mb-3" style={{ marginTop: 10 }}>
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          left: 42,
                          top: 6,
                        }}
                      >
                        *
                      </span>
                      <LocalizationProvider dateAdapter={MomentUtils}>
                        <MobileDatePicker
                          label="Date "
                          inputFormat="DD/MM/YYYY"
                          value={date}
                          onChange={e => {
                            const dateOfBirth = moment(e)
                            setDate(dateOfBirth)
                          }}
                          renderInput={params => (
                            <TextField variant="standard" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Col>
                </Row>
                <div>
                  {(multipleItems || []).map(
                    (formRow, key) =>
                      Number(multipleItems[key].quantity) > 0 && (
                        <>
                          <Row key={key}>
                            <Col md={4}>
                              {multipleItems[key].item.name ? (
                                <>
                                  {key === 0 ? (
                                    <Label className="form-label">
                                      Items{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                  ) : (
                                    <div style={{ marginTop: 20 }} />
                                  )}
                                  <Input
                                    name="total"
                                    id="total"
                                    className="form-control"
                                    disabled
                                    placeholder={multipleItems[key].item.name}
                                    // value={multipleItems[key].unit}
                                    type="text"
                                  />
                                </>
                              ) : (
                                <Selector
                                  label={key === 0 ? "Items" : ""}
                                  data={items !== null ? items : []}
                                  onchange={event => {
                                    setSelectItem(event.value)
                                    multipleItems[key].item = event.value
                                    setMultipleItems([...multipleItems])
                                    setIndex(key)
                                  }}
                                  // value={}
                                  required
                                  // edit={props.editData && {label: multipleItems[key].item.name , value: multipleItems[key].item._id}}
                                />
                              )}
                              {/* <Selector
                            label="Items"
                            data={items !== null ? items : []}
                            onchange={(event) => {
                                setSelectItem(event.value)
                                multipleItems[key].item = event.value
                                setMultipleItems([...multipleItems])
                                setIndex(key)
                            }}
                            // value={}
                            required
                            edit={props.editData && {label: multipleItems[key].item.name , value: multipleItems[key].item._id}}
                        /> */}
                            </Col>
                            <Col md={1}>
                              {key === 0 ? (
                                <Label className="form-label">
                                  QTY <span style={{ color: "red" }}>*</span>
                                </Label>
                              ) : (
                                <div style={{ marginTop: 20 }} />
                              )}
                              <Input
                                name="qty"
                                id="qty"
                                disabled
                                // style={{
                                //     width:80,
                                // }}
                                className="form-control"
                                placeholder="Quantity"
                                onKeyPress={event => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                type="text"
                                value={multipleItems[key].quantity}
                                // onEndEditing={(event) => {}}
                                onChange={e => {
                                  if (e.target.value !== "") {
                                    multipleItems[key].quantity = Number(
                                      e.target.value
                                    )
                                    multipleItems[key].total =
                                      Number(e.target.value) *
                                      Number(multipleItems[key].discountedPrice)
                                    setMultipleItems([...multipleItems])
                                    let num = 0
                                    multipleItems.map((d, i) => {
                                      num += Number(d.total)
                                    })
                                    setTotal(num)
                                    return
                                  }
                                  multipleItems[key].quantity = "01"
                                  multipleItems[key].total = Number(
                                    multipleItems[key].discountedPrice
                                  )
                                  setMultipleItems([...multipleItems])
                                }}
                              />
                              <text style={{ fontSize: 8 }}>
                                Ordered Quantity :{" "}
                                <text style={{ fontSize: 10 }}>
                                  {multipleItems[key].totalQuantity}
                                </text>
                              </text>
                            </Col>
                            <Col md={1}>
                              {key === 0 ? (
                                <Label className="form-label">
                                  Unit <span style={{ color: "red" }}>*</span>
                                </Label>
                              ) : (
                                <div style={{ marginTop: 20 }} />
                              )}
                              <Input
                                name="total"
                                id="total"
                                className="form-control"
                                disabled
                                placeholder={multipleItems[key].unit}
                                value={multipleItems[key].unit}
                                type="text"
                              />
                            </Col>
                            <Col md={1}>
                              {key === 0 ? (
                                <Label className="form-label">
                                  Dlv Qty{" "}
                                  <span style={{ color: "red" }}>*</span>
                                </Label>
                              ) : (
                                <div style={{ marginTop: 20 }} />
                              )}
                              <Input
                                name="dlvqty"
                                id="dlvqty"
                                className="form-control"
                                placeholder="QTY"
                                onKeyPress={event => {
                                  if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault()
                                  }
                                }}
                                // placeholder={multipleItems[key].deliveredQty}
                                type="text"
                                //   onChange={(e) => {
                                //     multipleItems[key].deliveredQty = e.target.value
                                //     setMultipleItems([...multipleItems])
                                //   }}

                                // value={multipleItems[key].deliveredQty}

                                onChange={e => {
                                  if (e.target.value !== "") {
                                    multipleItems[key].deliveredQty =
                                      e.target.value
                                    multipleItems[key].total =
                                      Number(e.target.value) *
                                      Number(multipleItems[key].discountedPrice)
                                    //   setMultipleItems([...multipleItems])
                                    let num = 0
                                    multipleItems.map((d, i) => {
                                      num += Number(d.total)
                                    })
                                    setTotal(num)
                                    return
                                  }
                                  multipleItems[key].deliveredQty = "0"
                                  multipleItems[key].total = "0"
                                  let num = 0
                                  multipleItems.map((d, i) => {
                                    num += Number(d.total)
                                  })
                                  setTotal(num)
                                  setMultipleItems([...multipleItems])
                                }}
                              />
                            </Col>

                            <Col md={2}>
                              {key === 0 ? (
                                <Label className="form-label">
                                  Price <span style={{ color: "red" }}>*</span>
                                </Label>
                              ) : (
                                <div style={{ marginTop: 20 }} />
                              )}
                              <Input
                                name="price"
                                id="price"
                                className="form-control"
                                placeholder={multipleItems[key].discountedPrice}
                                value={multipleItems[key].discountedPrice}
                                type="text"
                                onChange={e => {
                                  multipleItems[key].discountedPrice =
                                    e.target.value
                                  multipleItems[key].total =
                                    Number(e.target.value) *
                                    Number(multipleItems[key].deliveredQty)

                                  let num = 0
                                  multipleItems.map((d, i) => {
                                    num += Number(d.total)
                                  })
                                  setTotal(num)
                                  setMultipleItems([...multipleItems])
                                }}
                              />
                            </Col>

                            <Col md={2}>
                              {key === 0 ? (
                                <Label className="form-label">
                                  Total <span style={{ color: "red" }}>*</span>
                                </Label>
                              ) : (
                                <div style={{ marginTop: 20 }} />
                              )}
                              <Input
                                name="total"
                                id="total"
                                className="form-control"
                                disabled
                                placeholder={multipleItems[key].total}
                                value={multipleItems[key].total}
                                type="text"
                              />
                            </Col>
                            {/* <Col lg={1} className="align-self-center">
                          {
                            key === multipleItems.length -1 ? 
                            <div className="d-grid" style={{marginTop:10}}>
                            <input
                            type="button"
                            className="btn btn-success mt-3 mt-lg-0"
                            value="Add"
                            onClick={() => onAddFormRow()}
                            /> 
                                 </div>
                            :
                         
                             props.editData ? Number(multipleItems[key].deliveredQty) == 0 &&
                            
                            <div className="d-grid" style={{marginTop:10}}>
                            <input
                              type="button"
                              className="btn btn-primary"
                              value="Delete"
                              onClick={() => onDeleteFormRow(key)}
                            />
                          </div>
                          :
                          <div className="d-grid" style={{marginTop:10}}>
                          <input
                            type="button"
                            className="btn btn-primary"
                            value="Delete"
                            onClick={() => onDeleteFormRow(key)}
                          />
                        </div>

                          }
                           
                          </Col> */}
                          </Row>
                          <div
                            style={{
                              border: "1px solid #131313",
                              marginTop: 10,
                              marginBottom: 10,
                              opacity: 0.02,
                            }}
                          />
                        </>
                      )
                  )}
                </div>

                <Row>
                  <Col md={3}></Col>

                  <Col md={6}></Col>

                  <Col md={2}>
                    <div className="mb-3">
                      <Label className="form-label">Grand Total</Label>
                      <Input
                        name="total"
                        id="total"
                        className="form-control"
                        placeholder={total}
                        type="text"
                        disabled
                        // onChange={validation.handleChange}
                        // onBlur={validation.handleBlur}
                        // value={validation.values.partyName || ""}
                        // invalid={
                        //   validation.touched.partyName && validation.errors.partyName ? true : false
                        // }
                      />
                      {/* {validation.touched.partyName && validation.errors.partyName ? (
                          <FormFeedback type="invalid">{validation.errors.partyName}</FormFeedback>
                        ) : null} */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label className="form-label">Remark </Label>
                      <Input
                        name="remark"
                        id="remark"
                        className="form-control"
                        placeholder="Enter Remark here ..."
                        type="textarea"
                        onChange={e => setRemark(e.target.value)}
                        value={remark}
                      />
                    </div>
                  </Col>
                </Row>
                <Button
                  color="primary"
                  type="submit"
                  //  onClick={showCroppedImage}
                >
                  Submit
                </Button>
              </Form>
            </CardBody>
          </Col>
        </Row>
      </LayoutModal>
    </div>
  )
}

OrderDeliver.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  editData: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(OrderDeliver))
