import React, { useState, useRef, useEffect } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { Link, withRouter, Route } from "react-router-dom"
import axios from "axios"
import { BaseURL } from "config/BaseUrl"
import Loader from "components/Custom/Loader"
import BootstrapTable from "react-bootstrap-table-next"
import { Row, Col } from "../../imports/import"
import LayoutModal from "../../../components/Custom/LayoutModel"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import {
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
  Modal,
  CardBody,
  Card,
  Container,
  Label,
  Input,
} from "reactstrap"

function PartyRepostList(props) {
  const [partyReportList, setPartyRepostList] = useState([])
  const [loader, setLoader] = useState(false)
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  const [rowIndexTable, setRowIndexTable] = useState()
  useEffect(() => {
    var config = {
      method: "get",
      url: BaseURL + "/itemwiseparty/items",
      headers: {
        "x-auth-token": props.userData.token,
        // "Content-Type": "application/json",
      },
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        const data = response.data.items.filter((d, i) => {
          return Number(d.pendingQty) > 0
        })

        setPartyRepostList(data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])
  function tog_xlarge() {
    setmodal_xlarge(!modal_xlarge)
  }
  const columns = [
    {
      dataField: "name",
      text: "Item Name",
      sort: true,
    },
    {
      dataField: "pendingQty",
      text: "Pending Qty",
      sort: true,
    },
    {
      dataField: "dummy",
      isDummyField: true,
      text: "Action",
      sort: true,
      // hidden: props.delivered ? true : false,
      formatter: (cellContent, row, rowIndex, field) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Link
              to={{ pathname: "item-report-screen-1", state: { data: row } }}
            >
              <Button
                size="sm"
                type="button"
                className="btn btn-link"
                color="secondary"
              >
                Details
              </Button>
            </Link>
          </div>
        )
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPageRenderer,
    sizePerPage: 50,
    totalSize: partyReportList.length, // replace later with size(customers),
    // custom: true,
  }

  const { SearchBar } = Search

  if (loader) {
    return <Loader />
  }
  return (
    <>
      <LayoutModal
        title="Item Wise Party - Pending Order"
        sideTitle="User"
        button={false}
      >
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="id"
          columns={columns}
          data={partyReportList}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="id"
              columns={columns}
              data={partyReportList}
              //   search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="7">
                      <div className="table-responsive">
                        <BootstrapTable
                          // keyField={"email"}

                          responsive
                          bordered={false}
                          striped={true}
                          // defaultSorted={defaultSorted}
                          hover
                          // selectRow={selectRow}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>

                  {/* <Row className="align-items-md-center mt-30">
                        <Col className="inner-custom-pagination d-flex">
                          <div className="d-inline">
                            <SizePerPageDropdownStandalone
                               
                              {...paginationProps}
                            />
                          </div>
                          <div className="text-md-right ms-auto">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </div>
                        </Col>
                      </Row> */}
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </LayoutModal>
    </>
  )
}

PartyRepostList.propTypes = {
  userData: PropTypes.object,
  // editData: PropTypes.object,
  // history: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(PartyRepostList))
