import React, { useState, useEffect } from "react"
import LayoutModal from "../../components/Custom/LayoutModel"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import "../user/datatables.scss"

import { connect } from "react-redux"
import PropTypes from "prop-types"

import { Row, Col } from "../imports/import"

import axios from "axios"

import { Button, Modal } from "reactstrap"
import { withRouter } from "react-router-dom"

import Loader from "components/Custom/Loader"
import Tostify from "components/Custom/Tostify"
import { BaseURL } from "config/BaseUrl"
import moment from "moment"
import SecondaryTable from "./SecondaryTable"

function DeliveredOrdersList(props) {
  const [loader, setLoader] = useState(false)
  const [deliverdOrderList, setDeliverdOrderList] = useState([])
  const [rowIndexTable, setRowIndexTable] = useState()
  const [modal_xlarge, setmodal_xlarge] = useState(false)
  useEffect(() => {
    var config = {
      method: "post",
      url: `${BaseURL}/deliveries`,
      headers: {
        "x-auth-token": props.userData.token,
      },
      //   data: data,
    }

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data))
        setDeliverdOrderList(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])
  const columns = [
    {
      dataField: "seq",
      text: "# Id",
      sort: true,
    },
    {
      dataField: "party.partyName",
      text: "Party",
      sort: true,
    },
    {
      dataField: "party.city.name",
      text: "City",
      sort: true,
    },
    {
      dataField: "date",
      text: "Date",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        return (
          <div
            style={{
              width: 100,
              wordBreak: "break-all",
              overflowWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            <p>{moment(cellContent).format("DD/MM/YYYY")}</p>
          </div>
        )
      },
    },
    {
      dataField: "dummy",
      text: "Action",
      isDummyField: true,
      formatter: (cellContent, row, rowIndex) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <Button
              size="sm"
              type="button"
              className="btn btn-link"
              color="secondary"
              onClick={() => setmodal_xlarge(true)}
            >
              Item List
            </Button>

            {rowIndex === rowIndexTable && modal_xlarge && (
              <Modal
                size="xl"
                isOpen={modal_xlarge}
                toggle={() => {
                  tog_xlarge()
                }}
              >
                <div className="modal-header">
                  <SecondaryTable
                    data={row}
                    delivery={true}
                    dirData={row}
                    onClick={() => setmodal_xlarge(!modal_xlarge)}
                  />
                </div>
              </Modal>
            )}
          </div>
        )
      },
      events: {
        onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
          setRowIndexTable(rowIndex)
        },
      },
    },
  ]

  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPageRenderer,
    sizePerPage: 50,
    totalSize: deliverdOrderList.length, // replace later with size(customers),
    // custom: true,
  }

  const { SearchBar } = Search

  if (loader) {
    return <Loader />
  }
  return (
    <>
      <Tostify />
      <LayoutModal title="Delivered Order List" sideTitle="User" button={false}>
        <PaginationProvider
          pagination={paginationFactory(pageOptions)}
          keyField="_id"
          columns={columns}
          data={deliverdOrderList}
        >
          {({ paginationProps, paginationTableProps }) => (
            <ToolkitProvider
              keyField="_id"
              columns={columns}
              data={deliverdOrderList}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <SearchBar {...toolkitProps.searchProps} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xl="12">
                      <div className="table-responsive">
                        <BootstrapTable
                          // keyField={"email"}
                          responsive
                          bordered={false}
                          striped={true}
                          // defaultSorted={defaultSorted}
                          hover
                          // selectRow={selectRow}
                          classes={"table align-middle table-nowrap"}
                          headerWrapperClasses={"thead-light"}
                          {...toolkitProps.baseProps}
                          {...paginationTableProps}
                        />
                      </div>
                    </Col>
                  </Row>
                </React.Fragment>
              )}
            </ToolkitProvider>
          )}
        </PaginationProvider>
      </LayoutModal>
    </>
  )
}

DeliveredOrdersList.propTypes = {
  userData: PropTypes.object,
  editUser: PropTypes.any,
  history: PropTypes.any,
}

const mapStateToProps = state => {
  return { userData: state.userData }
}

export default connect(mapStateToProps)(withRouter(DeliveredOrdersList))
