import React, { useState, useEffect } from "react"
// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"

import "./datatables.scss"

import { connect } from "react-redux"
import PropTypes from "prop-types"

import { Row, Col } from "../imports/import"
import { Container, Label } from "reactstrap"
import { withRouter } from "react-router-dom"
import Loader from "components/Custom/Loader"

function SecondaryTable(props) {
  const [directoryList, setDirectoryList] = useState(props.data.items)
  const [loader, setLoader] = useState(false)
  console.log(props.data, "DIRECTORY DATA")

  const columns = [
    {
      dataField: "item.name",
      text: "Item Name",
      sort: true,
    },
    {
      dataField: "quantity",
      text: "Ordered Quantity",
      sort: true,
    },
    {
      dataField: "dummy",
      isDummyField: true,
      text: "Pending Quantity",
      sort: true,
      hidden: props.delivered ? true : false,
      formatter: (cellContent, row, rowIndex, field) => {
        return (
          <div
            style={{
              height: 20,
            }}
          >
            <p>{row.remaningQty}</p>{" "}
          </div>
        )
      },
    },
    {
      dataField: "discountedPrice",
      text: "Price",
      sort: true,
    },
    {
      dataField: "total",
      isDummyField: true,
      text: "Total Price",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        // Number(row.deliveredQty) * Number(row.discountedPrice)
        return (
          <div
            style={{
              height: 20,
            }}
          >
            <p>{row.remainingPrice}</p>{" "}
          </div>
        )
      },
    },
  ]

  const delColumns = [
    {
      dataField: "item.name",
      text: "Item Name",
      sort: true,
    },
    {
      dataField: "quantity",
      text: "Ordered Quantity",
      sort: true,
    },
    {
      dataField: "dummy",
      isDummyField: true,
      text: "Pending Quantity",
      sort: true,
      hidden: props.delivered ? true : false,
      formatter: (cellContent, row, rowIndex, field) => {
        return (
          <div
            style={{
              height: 20,
            }}
          >
            <p>{Number(row.quantity) - Number(row.deliveredQty)}</p>{" "}
          </div>
        )
      },
    },
    {
      dataField: "discountedPrice",
      text: "Price",
      sort: true,
    },
    {
      dataField: "total",
      isDummyField: true,
      text: "Total Price",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        // Number(row.deliveredQty) * Number(row.discountedPrice)
        return (
          <div
            style={{
              height: 20,
            }}
          >
            <p>{Number(row.quantity) * Number(row.discountedPrice)}</p>{" "}
          </div>
        )
      },
    },
  ]
  const deliveryColumns = [
    {
      dataField: "item.name",
      text: "Item Name",
      sort: true,
    },
    {
      dataField: "deliveredQty",
      text: "Delivered Quantity",
      sort: true,
    },
    {
      dataField: "discountedPrice",
      text: "Price",
      sort: true,
    },

    {
      dataField: "total",
      isDummyField: true,
      text: "Total Price",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        // console.log(row, "ROWWWWWW")
        return (
          <div
            style={{
              height: 20,
            }}
          >
            <p>{Number(row.deliveredQty) * Number(row.discountedPrice)}</p>{" "}
          </div>
        )
      },
    },
  ]

  const completedColumns = [
    {
      dataField: "item.name",
      text: "Item Name",
      sort: true,
    },
    {
      dataField: "quantity",
      text: "Ordered Quantity",
      sort: true,
    },
    {
      dataField: "deliveredQty",
      text: "Delivered Quantity",
      sort: true,
    },
    {
      dataField: "discountedPrice",
      text: "Price",
      sort: true,
    },

    {
      dataField: "total",
      isDummyField: true,
      text: "Total Price",
      sort: true,
      formatter: (cellContent, row, rowIndex, field) => {
        // console.log(row, "ROWWWWWW")
        return (
          <div
            style={{
              height: 20,
            }}
          >
            <p>{Number(row.deliveredQty) * Number(row.discountedPrice)}</p>
          </div>
        )
      },
    },
  ]
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map(option => {
        const isSelect = currSizePerPage === `${option.page}`
        return (
          <button
            key={option.text}
            type="button"
            onClick={() => onSizePerPageChange(option.page)}
            className={`btn ${isSelect ? "btn-secondary" : "btn-primary"}`}
          >
            {option.text}
          </button>
        )
      })}
    </div>
  )

  const defaultSorted = [
    {
      dataField: "name",
      order: "asc",
    },
  ]

  const pageOptions = {
    sizePerPageRenderer,
    sizePerPage: 50,
    totalSize: directoryList.length, // replace later with size(customers),
    // custom: true,
  }

  const { SearchBar } = Search

  if (loader) {
    return <Loader />
  }

  return (
    <Container fluid>
      <Row>
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            alignContent: "center",
            // cursor: "pointer",
          }}
        >
          <Label style={{ fontSize: 18, marginTop: 5 }}>
            Items (#{props.data.seq})
          </Label>

          <i
            className="bx bx-x-circle"
            onClick={props.onClick}
            style={{
              fontSize: 25,
              color: "#FF5858",
              cursor: "pointer",
            }}
          ></i>
        </Col>
      </Row>

      <div
        style={{
          display: "flex",
          flexDirection: "row",

          // justifyContent: "space-between",
        }}
      >
        <Label
          className="form-label"
          style={{
            height: 50,
            display: "flex",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <h5 style={{ fontWeight: "600", marginTop: 5, marginRight: 5 }}>
            Party Name
          </h5>{" "}
          : {props.data.party.partyName}
        </Label>

        <Label
          className="form-label"
          style={{
            height: 50,
            marginLeft: 20,
            display: "flex",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <h5 style={{ fontWeight: "600", marginTop: 5, marginRight: 5 }}>
            Total Value
          </h5>{" "}
          : {props.data.grandTotal}
        </Label>

        {/* <Col md={2}>
          <div className="mb-1">
            <Label className="form-label">Order : #{props.data.seq}</Label>
          </div>
        </Col> */}
      </div>
      {/* <Row>
        <Col md={4}>
          <div className="mb-1">
            <Label className="form-label">
              Total Value : {props.data.grandTotal}
            </Label>
          </div>
        </Col>
      </Row> */}
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="_id"
        columns={props.delivery ? deliveryColumns : columns}
        data={directoryList}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="_id"
            columns={
              props.completed
                ? completedColumns
                : props.delivery
                ? deliveryColumns
                : props.deleted
                ? delColumns
                : columns
            }
            data={directoryList}
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row className="mb-2">
                  <h6 style={{ marginTop: 10, position: "absolute" }}>
                    {props.dirData.name}
                  </h6>
                  <Col
                    md="4"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    <div className="search-box me-2 mb-2 d-inline-block">
                      <div className="position-relative">
                        <SearchBar {...toolkitProps.searchProps} />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        // keyField={"email"}

                        responsive
                        bordered={true}
                        striped={false}
                        // defaultSorted={defaultSorted}
                        hover
                        // selectRow={selectRow}
                        classes={"table align-middle table-nowrap"}
                        headerWrapperClasses={"thead-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                      />
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>
    </Container>
  )
}
SecondaryTable.propTypes = {
  userData: PropTypes.object,
  editUser: PropTypes.any,
  history: PropTypes.any,
  data: PropTypes.any,
  dirData: PropTypes.object,
  delivered: PropTypes.bool,
  onClick: PropTypes.any,
  delivery: PropTypes.any,
  completed: PropTypes.bool,
  deleted: PropTypes.bool,
}

const mapStateToProps = state => {
  return { userData: state.userData }
}

export default connect(mapStateToProps)(withRouter(SecondaryTable))
