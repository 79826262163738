import React, { useState, useEffect, useRef, useCallback } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import LayoutModal from "../../components/Custom/LayoutModel"
import { connect } from "react-redux"
import { Switch, withRouter } from "react-router-dom"
import moment from "moment"
import Selector from "components/Custom/Selector"
import { toast } from "react-toastify"
import Tostify from "components/Custom/Tostify"
import Loader from "components/Custom/Loader"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useSelector, useDispatch } from "react-redux"
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  CardTitle,
  Button,
  Modal,
} from "reactstrap"
// action
import { userEdit, userForgetPassword } from "../../store/actions"
import { BaseURL } from "config/BaseUrl"
import { LocalizationProvider } from "@mui/lab"
import MobileDatePicker from "@mui/lab/MobileDatePicker"
import MomentUtils from "@date-io/moment"
import { TextField } from "@mui/material"

function AddOrder(props) {
  const dispatch = useDispatch()

  const [items, setItems] = useState([])
  const [itemsBackup, setItemsBackup] = useState([])
  const [removedItems, setRemovedItems] = useState([])

  const [itemsSelector, setItemsSelector] = useState([])
  const [selectItem, setSelectItem] = useState(null)

  const [multipleItems, setMultipleItems] = useState([
    {
      item: "",
      quantity: "",
      unit: "",
      discountedPrice: "",
      total: "",
      price: "",
      discount: "",
      itemName: "",
    },
  ])

  const accessItems = new Map()

  const [units, setUnits] = useState("")
  const [itemData, setItemData] = useState(null)
  const [party, setPartyList] = useState([])
  const [selectedParty, setSelectedParty] = useState(null)
  const [index, setIndex] = useState("")
  const [date, setDate] = useState(
    props.editData ? moment(props.editData.date) : new Date()
  )
  const [partyName, setPartyName] = useState("")
  const [total, setTotal] = useState("")
  const [remark, setRemark] = useState("")

  const [loader, setLoader] = useState(false)

  // console.log(props.editData,"EDIT DATA")

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseURL}/formdata/item`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        setItems(response.data)
        setItemsBackup(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    var config = {
      method: "get",
      url: `${BaseURL}/formdata/party`,
      headers: {
        "x-auth-token": props.userData.token,
      },
    }

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        setPartyList(response.data)
      })
      .catch(function (error) {
        console.log(error)
      })
  }, [])

  // useEffect(() => {

  //   let demoDataOfItems = itemsBackup;
  //   let removed = []
  //   itemsBackup.map((d,index) => {
  //       // accessItems.set(d._id,d)

  //       multipleItems.map((data,i) => {

  //         if(data.item === d._id){
  //           console.log(d,i,"found")
  //          let item = demoDataOfItems.splice(index,1)
  //          removed.push(...item)
  //         }

  //       })

  //     })

  //     // setItemData(accessItems)
  //     setRemovedItems(removed)
  //     // console.log(demoDataOfItems, multipleItems.length, itemsBackup.length,removed , "--------------------------------------------------------------------------------------------LOG----------------------------")
  //      setItems([...demoDataOfItems])

  // },[multipleItems.length])

  useEffect(() => {
    if (items.length > 0 && selectItem == null) {
      // console.log(selectItem ,"ITEMM")
      // setItems(itemsBackup)
      // let demoDataOfItems = items;
      items.map((d, index) => {
        accessItems.set(d._id, d)

        // // console.log(d._id,"idd")
        // multipleItems.map((data,i) => {
        //   // console.log(data.item )
        //   // console.log(data.item === d._id && d._id)
        //   if(data.item === d._id){
        //     // console.log(d,i,"found")
        //     demoDataOfItems.splice(index,1)
        //   }
        // })
      })
      setItemData(accessItems)
      // console.log(demoDataOfItems,"MAP ITEMS")

      // setItems([...demoDataOfItems])
    }
  }, [items.length, selectItem])

  useEffect(() => {
    if (selectItem !== null) {
      let getData = itemData.get(selectItem)
      multipleItems[index].discountedPrice = getData.discountedPrice
      multipleItems[index].unit = getData.unit.name
      multipleItems[index].price = getData.price
      multipleItems[index].discount = getData.discount

      multipleItems[index].total =
        Number(multipleItems[index].quantity) *
        Number(multipleItems[index].discountedPrice)
      // console.log(selectItem,multipleItems,index,itemData);
      let num = 0
      multipleItems.map((d, i) => {
        num += Number(d.total)
      })
      setTotal(num)
      setMultipleItems([...multipleItems])
      setSelectItem(null)
    }
  }, [selectItem, items.length])

  useEffect(() => {
    if (props.editData) {
      setRemark(props.editData.remarks)

      let dummyArr = []
      let num = 0

      setSelectedParty(props.editData.party._id)

      props.editData.items.map((d, i) => {
        let dummyObj = {}
        dummyObj.item = d.item
        dummyObj.quantity = d.quantity
        dummyObj.unit = d.item.unit.name
        dummyObj.discountedPrice = d.discountedPrice
        dummyObj.total = d.total
        dummyObj.price = d.price
        dummyObj.discount = d.discount
        dummyObj.deliveredQty = d.deliveredQty
        // let getData = itemData.get(d.item._id)
        num += Number(d.total)
        dummyArr.push(dummyObj)
      })
      setMultipleItems([])
      setTimeout(() => {
        setMultipleItems([...dummyArr])
      }, 100)
      setTotal(num)
      // console.log(dummyArr , "DUMMY")
    }

    return () => dispatch(userEdit(null))
  }, [props.editData])

  const onAddFormRow = () => {
    const modifiedRows = [...multipleItems]
    let notEmpty = true
    modifiedRows.map((d, i) => {
      if (d.item !== "" && d.quantity !== "") {
        notEmpty = notEmpty && true
      } else {
        notEmpty = notEmpty && false
      }
    })
    notEmpty
      ? modifiedRows.push({
          item: "",
          quantity: "",
          unit: "",
          discountedPrice: "",
          total: "",
          price: "",
          discount: "",
        })
      : toast.error("Please fill required fields before adding new data", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
    setMultipleItems(modifiedRows)
    calculate(modifiedRows)
  }

  const calculate = data => {
    //   multipleItems[key].total =
    //   Number(e.target.value) *
    //   Number(multipleItems[key].discountedPrice)
    // setMultipleItems([...multipleItems])
    let num = 0
    data.map((d, i) => {
      num += Number(d.total)
    })
    setTotal(num)
  }

  const onDeleteFormRow = val => {
    const modifiedRowsDelete = [...multipleItems]

    modifiedRowsDelete.splice(val, 1)
    calculate(modifiedRowsDelete)
    setMultipleItems([])
    setTimeout(() => {
      setMultipleItems([...modifiedRowsDelete])
    }, 100)

    console.log(modifiedRowsDelete, "ITEMS")
  }

  const onSubmit = e => {
    e.preventDefault()
    // console.log(e.target.value,"SUBMIT")

    if (selectedParty === null) {
      return toast.error("Please select a Party, Party name is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
    }

    multipleItems.map((d, i) => {
      if (d.item === "") {
        return toast.error("Please select item, Item is required", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }

      if (d.quantity === "") {
        return toast.error("Please enter quantity , quantity is required", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
    })

    setLoader(true)

    var data = JSON.stringify({
      party: selectedParty,
      date: date,
      grandTotal: total,
      remarks: remark,
      items: multipleItems,
      orderByParty: 0,
      isMobile: 0,
    })

    var config = {
      method: props.editData ? "put" : "post",
      url: `${BaseURL}/orders${
        props.editData ? "/update/" + props.editData._id : ""
      }`,
      headers: {
        "x-auth-token": props.userData.token,
        "Content-Type": "application/json",
      },
      data: data,
    }

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        setLoader(false)
        props.history.push("/pending-orders")
      })
      .catch(function (error) {
        console.log(error)
        toast.error(error.response && error.response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
        setLoader(false)
      })
  }

  if (loader) {
    return <Loader />
  }

  return (
    <div
      // className="d-flex flex-column flex-sm-column flex-md-column flex-lg-row"
      style={{ flex: 1, minHeight: "100vh" }}
    >
      <Tostify />
      <LayoutModal
        button={true}
        // form={true}
        title={
          props.editData ? `Edit Order #${props.editData.seq}` : "Add Order"
        }
        btnTitle={props.editData ? "Add Order" : "Order List"}
        onclick={() => {
          if (props.editData) {
            dispatch(userEdit(null))
            setLoader(true)
            window.location.reload()
            setTimeout(() => setLoader(false), 300)
          } else {
            props.history.push("/pending-orders")
          }
        }}
      >
        <Row>
          <Col xs={12}>
            <CardBody>
              <Form
                className="repeater"
                encType="multipart/form-data"
                onSubmit={onSubmit}
              >
                <Row>
                  <Col md={4}>
                    <div className="mb-3">
                      {props.editData ? (
                        <>
                          <Label
                            className="form-label"
                            style={{ fontWeight: "800" }}
                          >
                            Party Name <span style={{ color: "red" }}>*</span>
                          </Label>
                          <Input
                            name="partyName"
                            id="partyName"
                            className="form-control"
                            placeholder={
                              props.editData.party &&
                              props.editData.party.partyName
                            }
                            disabled={true}
                            type="text"
                          />
                        </>
                      ) : (
                        <Selector
                          label="Party Name"
                          data={party !== null ? party : []}
                          onchange={event => setSelectedParty(event.value)}
                          // value={}
                          required
                          edit={
                            props.editData &&
                            props.editData.party && {
                              label: props.editData.party.partyName,
                              value: props.editData.party._id,
                            }
                          }
                        />
                      )}
                    </div>
                  </Col>
                  <Col md={2}>
                    <div className="mb-3" style={{ marginTop: 10 }}>
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          left: 42,
                          top: 6,
                        }}
                      >
                        *
                      </span>
                      <LocalizationProvider dateAdapter={MomentUtils}>
                        <MobileDatePicker
                          label="Date "
                          inputFormat="DD/MM/YYYY"
                          value={date}
                          onChange={e => {
                            const dateOfBirth = moment(e)
                            setDate(dateOfBirth)
                          }}
                          renderInput={params => (
                            <TextField variant="standard" {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </div>
                  </Col>
                </Row>
                <div>
                  {(multipleItems || []).map((formRow, key) => (
                    <>
                      <Row key={key}>
                        <Col md={4}>
                          {/* {
                      
                            <Label className="form-label">Items <span style={{color:"red"}}>*</span></Label>
                            <Input
                              name="total"
                              id="total"
                              className="form-control"
                              // disabled
                              placeholder={multipleItems[key].item.name || multipleItems[key].itemName}
                              // value={multipleItems[key].unit}
                              type="text"
                            />
                            </> 
                        : */}
                          <Selector
                            label={key === 0 ? "Items" : ""}
                            data={items !== null ? items : []}
                            onchange={event => {
                              setSelectItem(event.value)
                              multipleItems[key].item = event.value
                              multipleItems[key].itemName = event.label

                              let num = 0
                              multipleItems.map((d, i) => {
                                num += Number(d.total)
                              })
                              setTotal(num)
                              setIndex(key)
                              setMultipleItems([...multipleItems])
                            }}
                            // value={}
                            required={key === 0 ? true : false}
                            key={key}
                            edit={
                              props.editData && multipleItems[key].item.name
                                ? {
                                    label: multipleItems[key].item.name,
                                    value: multipleItems[key].item._id,
                                  }
                                : multipleItems[key].item !== "" && {
                                    label: multipleItems[key].itemName,
                                    value: multipleItems[key].item,
                                  }
                            }
                          />

                          {/* <Selector
                            label="Items"
                            data={items !== null ? items : []}
                            onchange={(event) => {
                                setSelectItem(event.value)
                                multipleItems[key].item = event.value
                                setMultipleItems([...multipleItems])
                                setIndex(key)
                            }}
                            // value={}
                            required
                            edit={props.editData && {label: multipleItems[key].item.name , value: multipleItems[key].item._id}}
                        /> */}
                        </Col>
                        <Col md={1}>
                          {key === 0 ? (
                            <Label className="form-label">
                              QTY <span style={{ color: "red" }}>*</span>
                            </Label>
                          ) : (
                            <div style={{ marginTop: 20 }} />
                          )}

                          <Input
                            name="qty"
                            id="qty"
                            // style={{
                            //     width:80,
                            // }}
                            className="form-control"
                            placeholder="Quantity"
                            onKeyPress={event => {
                              if (
                                !/[0-9]/.test(event.key) ||
                                multipleItems[key].item == ""
                              ) {
                                event.preventDefault()
                              }
                            }}
                            type="text"
                            value={multipleItems[key].quantity}
                            // onEndEditing={(event) => {}}
                            onChange={e => {
                              if (e.target.value !== "") {
                                multipleItems[key].quantity = Number(
                                  e.target.value
                                )
                                multipleItems[key].total =
                                  Number(e.target.value) *
                                  Number(multipleItems[key].discountedPrice)
                                setMultipleItems([...multipleItems])
                                let num = 0
                                multipleItems.map((d, i) => {
                                  num += Number(d.total)
                                })
                                setTotal(num)
                                return
                              }
                              multipleItems[key].quantity = "0"
                              multipleItems[key].total = "0"
                              let num = 0
                              multipleItems.map((d, i) => {
                                num += Number(d.total)
                              })
                              setTotal(num)
                              setMultipleItems([...multipleItems])
                              // multipleItems[key].quantity = "01"
                              // multipleItems[key].total = Number(multipleItems[key].discountedPrice)
                              // setMultipleItems([...multipleItems])
                            }}
                          />
                        </Col>
                        <Col md={1}>
                          {key === 0 ? (
                            <Label className="form-label">
                              Unit <span style={{ color: "red" }}>*</span>
                            </Label>
                          ) : (
                            <div style={{ marginTop: 20 }} />
                          )}

                          <Input
                            name="total"
                            id="total"
                            className="form-control"
                            disabled
                            placeholder={multipleItems[key].unit}
                            value={multipleItems[key].unit}
                            type="text"
                          />
                        </Col>
                        <Col md={2}>
                          {key === 0 ? (
                            <Label className="form-label">
                              Price <span style={{ color: "red" }}>*</span>
                            </Label>
                          ) : (
                            <div style={{ marginTop: 20 }} />
                          )}

                          <Input
                            name="price"
                            id="price"
                            // disabled
                            // style={{
                            //     width:80,
                            // }}
                            className="form-control"
                            placeholder={multipleItems[key].discountedPrice}
                            value={multipleItems[key].discountedPrice}
                            onKeyPress={event => {
                              if (
                                !/[0-9]/.test(event.key) ||
                                multipleItems[key].item == ""
                              ) {
                                event.preventDefault()
                              }
                            }}
                            type="text"
                            onChange={e => {
                              multipleItems[key].discountedPrice =
                                e.target.value
                              multipleItems[key].total =
                                Number(multipleItems[key].discountedPrice) *
                                Number(multipleItems[key].quantity)

                              setMultipleItems([...multipleItems])
                              let num = 0
                              multipleItems.map((d, i) => {
                                num += Number(d.total)
                              })
                              setTotal(num)
                              // setMultipleItems([...multipleItems])
                            }}
                            //   onBlur={validation.handleBlur}
                            //   value={validation.values.qty || ""}
                            //   invalid={
                            //     validation.touched.qty && validation.errors.qty ? true : false
                            //   }
                          />
                          {/* {validation.touched.qty && validation.errors.qty ? (
                          <FormFeedback type="invalid">{validation.errors.qty}</FormFeedback>
                        ) : null} */}
                        </Col>
                        <Col md={2}>
                          {key === 0 ? (
                            <Label className="form-label">
                              Total <span style={{ color: "red" }}>*</span>
                            </Label>
                          ) : (
                            <div style={{ marginTop: 20 }} />
                          )}

                          <Input
                            name="total"
                            id="total"
                            className="form-control"
                            disabled
                            placeholder={multipleItems[key].total}
                            value={multipleItems[key].total}
                            type="text"
                          />
                        </Col>
                        <Col lg={1} className="align-self-center">
                          {props.editData
                            ? key !== 0 && (
                                <div
                                  className="d-grid"
                                  style={{ marginTop: 10 }}
                                >
                                  <input
                                    type="button"
                                    className="btn btn-primary"
                                    value="Delete"
                                    onClick={() => onDeleteFormRow(key)}
                                  />
                                </div>
                              )
                            : key !== 0 && (
                                <div
                                  className="d-grid"
                                  style={{ marginTop: 10 }}
                                >
                                  <input
                                    type="button"
                                    className="btn btn-primary"
                                    value="Delete"
                                    onClick={() => onDeleteFormRow(key)}
                                  />
                                </div>
                              )}
                        </Col>
                      </Row>
                      {key === multipleItems.length - 1 && (
                        <div
                          className="d-grid"
                          style={{
                            marginTop: 10,
                            width: 80,
                          }}
                        >
                          <input
                            type="button"
                            className="btn btn-success mt-3"
                            value="Add"
                            onClick={() => onAddFormRow()}
                          />
                        </div>
                      )}
                      <div
                        style={{
                          border: "1px solid #131313",
                          marginTop: 10,
                          marginBottom: 10,
                          opacity: 0.02,
                        }}
                      />
                    </>
                  ))}
                </div>

                <Row>
                  <Col md={3}></Col>

                  <Col md={5}></Col>

                  <Col md={2}>
                    <div className="mb-3">
                      <Label className="form-label">Grand Total</Label>
                      <Input
                        name="total"
                        id="total"
                        className="form-control"
                        placeholder={total}
                        type="text"
                        disabled
                        // onChange={validation.handleChange}
                        // onBlur={validation.handleBlur}
                        // value={validation.values.partyName || ""}
                        // invalid={
                        //   validation.touched.partyName && validation.errors.partyName ? true : false
                        // }
                      />
                      {/* {validation.touched.partyName && validation.errors.partyName ? (
                          <FormFeedback type="invalid">{validation.errors.partyName}</FormFeedback>
                        ) : null} */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <div className="mb-3">
                      <Label className="form-label">Remark </Label>
                      <Input
                        name="remark"
                        id="remark"
                        className="form-control"
                        placeholder="Enter Remark here ..."
                        type="textarea"
                        onChange={e => setRemark(e.target.value)}
                        value={remark}
                      />
                    </div>
                  </Col>
                </Row>
                <Button
                  color="primary"
                  type="submit"
                  //  onClick={showCroppedImage}
                >
                  Submit
                </Button>
              </Form>
            </CardBody>
          </Col>
        </Row>
      </LayoutModal>
    </div>
  )
}

AddOrder.propTypes = {
  history: PropTypes.object,
  userData: PropTypes.object,
  editData: PropTypes.object,
}

const mapStateToProps = state => {
  return {
    userData: state.userData,
    editData: state.userEdit,
  }
}

export default connect(mapStateToProps)(withRouter(AddOrder))
